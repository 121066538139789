import React from "react";
import Book from "../../classes/DataBaseValue/Book";
import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";

/** Books Component
 * 
 * Database value overview for books
 */
export default function Books(props: any) {
    return <DataBaseValueOverview Class={Book} />
}