import React, {Component, useEffect, useState} from 'react';
import { BottomNavigation, BottomNavigationAction, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import './Navigator.css';
import { Link, withRouter } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';

// TODO: Bottom navigation text seems to be white when not selected

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '5%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#f2f2f2'
    }
}));

function Navigator(props: any) {
    const [value, setValue] = useState(-1);
    const values = ["sales", "rents", "transactions"]

    const classes = useStyles();

    useEffect(() => {
        const route = window.location.hash.substring(2);
        setValue(values.findIndex(v => {
            return route.startsWith(v);
        }))
    });

    return (
        <BottomNavigation className={classes.root} value={value} onChange={(event, value) => setValue(value)}>
            <BottomNavigationAction label="Sales"        icon={<FastfoodIcon />}   component={Link} to='/sales' />
            <BottomNavigationAction label="Rents"        icon={<MenuBookIcon />}   component={Link} to='/rents/books' />
            <BottomNavigationAction label="Transactions" icon={<SwapHorizIcon />}  component={Link} to='/transactions/transfer' />
        </BottomNavigation>
    )
}

export default withRouter(Navigator)