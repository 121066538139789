import React, { useEffect, useState, useContext } from 'react';
import {
    Paper,
    Avatar,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Input,
    Fab,
    Theme,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import './Vending.css';
import CheckIcon from '@mui/icons-material/Check';
import DataBaseValueAutoComplete from "../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Consumable, {RawConsumableAttributes} from '../../classes/DataBaseValue/Consumable';
import Product from "../../classes/DataBaseValue/Product";
import config from "../../config";
import Inventory from "../../classes/DataBaseValue/Inventory";
import InventoryItem from "../../classes/DataBaseValue/InventoryItem";
import InventoryTransfer from "../../classes/DataBaseValue/InventoryTransfer";
import InventoryTransferItem from "../../classes/DataBaseValue/InventoryTransferItem";
import Right from "../../classes/DataBaseValue/Right";
import ProductRightsAssociation from "../../classes/DataBaseValue/ProductRightsAssociation";
import NumericalField from "../DataBaseValueOverview/FormBody/NumericalField";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import Category from "../../classes/DataBaseValue/Category";
import GlobalContext from '../../GlobalContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1%'
    },

    align: {
        display: 'flex',
        flexDirection: 'row'
    },
    input: {
        /*height: '3rem',
        borderRadius: 40,
        border: '1px solid',
        borderColor: 'grey',
        overflow: 'hidden',
        backgroundColor: '#fff',
        '&:hover:not($disabled)': {
            borderColor: 'darkgrey'
        },
        margin: theme.spacing(1)*/
    },
    sameSize: {
      height: theme.spacing(7)
    },
    autoComplete: {
        /*marginLeft: '75%'*/
    },
    itemInfo: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },

    extendedIcon: {
        marginRight: theme.spacing(1)
    },

    fab: {
        position: 'absolute',
        bottom:  theme.spacing(10),
        right: theme.spacing(5),
        margin: theme.spacing(1),
        zIndex: theme.zIndex.appBar + 10
    }
}));

//TODO add item that will be added to vending

export default function Vending(props: {}) {
    const classes = useStyles();

    const { load, raiseSuccess } = useContext(GlobalContext);

    const [inventoryTransfer, setInventoryTransfer] = useState<InventoryTransfer>(InventoryTransfer.makeStub());

    const [product, setProduct] = useState<Product | null>(null)
    const [products, setProducts] = useState<Array<Consumable>>([])
    const [inventory, setInventory] = useState<Inventory>(Inventory.makeStub());

    useEffect(() => {
        (async function() {
            await load(async (loadHook) => {
                setInventory(await Inventory.Kassa(loadHook))
            }, 'Getting kassa')
        })();
    }, [])

    useEffect(() => {
        (async function() {
           await load(async (loadHook) => {
               setProducts(await Consumable.getVendingProducts(loadHook))
           }, 'Getting products')
        })()
    }, [])

    const addProductToVending = async () => {
        if(product !== null) {
            await load(async () => {
                await product.merge({
                    product_rights_associations: [ProductRightsAssociation.makeStub().merge({
                        right: (await Right.Vendable()).toRaw(),
                        product_id: product.attributes.id,
                        rentable: false,
                        sellable: true
                    }).toRaw()]
                }).put()

                setProducts(await Consumable.getVendingProducts())

                raiseSuccess(`Product added to vending machine`)
            })
        }
    }

    const addProduct = (product: Product, amount: number) =>
        setInventoryTransfer(inventoryTransfer.addInventoryTransferItem(InventoryTransferItem.makeStub().merge({
            product: product.toRaw(),
            amount: amount
        })))

    const removeProductFromVending = async (product: Consumable) => {
        await load(async () => {
            const vendable = await Right.Vendable()
            await product.merge({
                product_rights_associations: [ProductRightsAssociation.makeStub().merge({
                    id: product.attributes.product_rights_associations.product_rights_associations.find(pra =>
                        pra.attributes.right.attributes.id === vendable.attributes.id)?.attributes.id,
                    discarded_at: new Date()
                }).toRaw()]
            }).put()

            setProducts(await Consumable.getVendingProducts())

            raiseSuccess(`Product removed from vending machine`)
        })
    }

    const commit = async () => {
        await load(async () => {
            const newInventoryTransfer = inventoryTransfer.merge({
                from_id: inventory.attributes.id,
                to_id: (await Inventory.VendingMachines()).attributes.id
            })

            await newInventoryTransfer.post()
            raiseSuccess(`Vending machines filled`)
            setInventoryTransfer(InventoryTransfer.makeStub())
            setInventory(await Inventory.Kassa())
        })
    }

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'scroll'}}>
            <Paper variant="outlined" elevation={0} className={classes.root}>
                <Table>
                    <TableBody>
                    <TableRow>
                            <TableCell align='left'>
                                <Typography>Add item</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <DataBaseValueAutoComplete
                                    preloadOptions={true}
                                    key={inventoryTransfer.attributes.inventory_transfer_items.length}
                                    fullWidth={false}
                                    label='Search Product'
                                    Class={Consumable}
                                    onSelect={(val: Consumable | null) => val !== null && setProduct(val)}
                                    customSearch={async (value: string) =>
                                        (await Consumable.search<RawConsumableAttributes, Consumable>(
                                            value,
                                            0,
                                            Consumable.route.addPredicate(
                                                new QueryPredicate('from_category', (await Category.NonAlcoholic()).attributes.id))
                                        )).records.filter(c => !products.find(p => p.attributes.id === c.attributes.id))
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => addProductToVending()}
                                    disabled={product === null}
                                    size="large">
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        {products.map((product: Consumable, index: number) => (
                            <Entry
                                product={product}
                                inventory={inventory}
                                inventoryTransfer={inventoryTransfer}
                                remove={() => removeProductFromVending(product)}
                                add={(amount: number) => addProduct(product, amount)}
                                key={product.attributes.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <Fab color="secondary" className={classes.fab} size='large' onClick={(event) => commit()} sx={{position: "absolute"}}>
                <CheckIcon />
            </Fab>
        </div>
    );
}

function Entry(props: {remove: () => void, add: (amount: number) => void, product: Product, inventory: Inventory, inventoryTransfer: InventoryTransfer}) {

    const classes = useStyles();

    const inventoryItem: InventoryItem | undefined = props.inventory.inventoryItemOf(props.product)
    const inventoryTransferItem: InventoryTransferItem | undefined = props.inventoryTransfer.inventoryTransferItemOf(props.product)

    const inventoryItemAmount: number = inventoryItem ? inventoryItem.attributes.amount : 0
    const inventoryTransferItemAmount: number = inventoryTransferItem ? inventoryTransferItem.attributes.amount : 0


    return (
        <TableRow>
            <TableCell>
                <ListItemAvatar>
                    <Avatar src={props.product.attributes.photo ? `${config.api}${props.product.attributes.photo}` : '/images/Food.jpg'}/>
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography>{props.product.attributes.name}</Typography>}
                    secondary={`${inventoryItemAmount} in stock`}
                />
            </TableCell>
            <TableCell>
                <TextField
                    InputProps={{
                        className: classes.sameSize
                    }}
                    error={inventoryTransferItemAmount > inventoryItemAmount}
                    inputProps={{style: {textAlign: 'center'}}}
                    placeholder="Amount"
                    className={classes.input}
                    type='number'
                    variant='outlined'
                    onChange={(e) => props.add(parseInt(e.target.value))}
                    value={inventoryTransferItemAmount}
                />
            </TableCell>
            <TableCell align='left'>
                <Button className={classes.sameSize} variant='contained' color='primary' onClick={e => props.add(inventoryTransferItemAmount + 24)}>+24</Button>
                <IconButton onClick={props.remove} size="large">
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}