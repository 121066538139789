import React, { ComponentType } from 'react';
import {
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
    Theme,
    Divider,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Link } from 'react-router-dom';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import RedeemIcon from '@mui/icons-material/Redeem';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SaveIcon from '@mui/icons-material/Save';
import BookIcon from '@mui/icons-material/Book';
import GroupIcon from '@mui/icons-material/Group';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CategoryIcon from '@mui/icons-material/Category';
import BusinessIcon from '@mui/icons-material/Business';
import makeStyles from '@mui/styles/makeStyles';
import PrintIcon from '@mui/icons-material/Print';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

type ItemArray = Array<{text: string, Icon: ComponentType, linkTo?: string, onClick?: any}>

interface ListItems {
    [key: string]: ItemArray | JSX.Element
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawer: {
        overflowY: 'hidden'
    },
    content: {
        width: '250px'
    },
    height: {
        height: '100%'
    },
    maxHeight: {
        maxHeight: '100%'
    },
    toolbar: theme.mixins.toolbar,
    botNav: {
        minHeight: '5%'
    },
    list: {
        overflowY: 'hidden'
    },
    contained: {
        marginTop: '75px',
        height: `calc(100% - 64px - 5%)`,
        marginBottom: '5%',
    },
    relative: {
        position: 'relative'
    }
}));

export function MainDrawer(props: {open: boolean, onClose: any, onOpen: any}) {
    return <Drawer
        {...props}
        anchor='left'
        items={{
            "Main Routes": [
                {text: 'Sales', Icon: FastfoodIcon, linkTo: '/sales'},
                {text: 'Rents', Icon: MenuBookIcon, linkTo: '/rents/books'},
                {text: 'Transactions', Icon: SyncAltIcon, linkTo: '/transactions/transfer'},
                {text: 'Vending Machines', Icon: LocalDrinkIcon, linkTo: '/vending'},
                {text: 'Inventory', Icon: RedeemIcon, linkTo: '/inventory'}
            ],
            "Database Management": [
                {text: 'Persons', Icon: PersonIcon, linkTo: '/db/persons'},
                {text: 'Companies', Icon: BusinessIcon, linkTo: '/db/companies'},
                {text: 'Consumables', Icon: FastfoodIcon, linkTo: '/db/consumables'},
                {text: 'Books', Icon: MenuBookIcon, linkTo: '/db/books'},
                {text: 'Hardware', Icon: SaveIcon, linkTo: '/db/hardware'},
                {text: 'Groups', Icon: GroupIcon, linkTo: '/db/groups'},
                {text: 'Rights', Icon: BeenhereIcon, linkTo: '/db/rights'},
                {text: 'Categories', Icon: CategoryIcon, linkTo: '/db/categories'}
            ],
            "Extras": [
                {text: "Printer", Icon: PrintIcon, linkTo: '/printer'},
                {text: "Quaestor", Icon: AccountBalanceIcon, linkTo: '/quaestor'},
            ]
        }}
        variant='temporary'
        over
    />
}

export default function Drawer(props: {
    open: boolean,
    onClose: any,
    onOpen: any,
    items: ListItems,
    anchor: 'left' | 'right',
    variant: 'permanent' | 'persistent' | 'temporary',
    over: boolean
}) {

    const classes = useStyles();

    /*
            Not a good fix: this component is used as the navigation drawer and shopping drawer

            The navigation drawer needs to appear before the app bar, while the shopping drawer
            needs to appear behind the app bar
            */

    return (
        <SwipeableDrawer
            variant={props.variant}
            anchor={props.anchor}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            sx={props.anchor === "left" ? {zIndex: 1201} : {zIndex: 5}}
        >
            <div
                className={`${classes.content} ${classes.height} ${classes.drawer}`}
                onClick={props.onClose}
                onKeyDown={props.onOpen}
            >
                <DrawerList items={props.items} over={props.over} />
            </div>
        </SwipeableDrawer>
    );
}

function DrawerList(props: {items: ListItems, over: boolean}) {
    const classes = useStyles();

    const renderSection = (header: string, index: number) => {
        const section = props.items[header];
        if(Array.isArray(section)) {
            return (
                <div key={index}>
                    <ListSubheader>{header}</ListSubheader>
                    {section.map((item, index) => (
                        item.linkTo
                            ?(
                                <ListItem key={index} button component={Link} to={item.linkTo}>
                                    <ListItemIcon><item.Icon /></ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            )
                            :(
                                <ListItem key={index} button onClick={item.onClick}>
                                    <ListItemIcon><item.Icon /></ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            )
                    ))}
                    <Divider />
                </div>
            )
        } else {
            return section
        }
    };

    return (
        <div className={`${classes.list} ${props.over ? classes.height : classes.contained}`}>
            <List className={`${classes.height} ${classes.maxHeight} ${classes.relative}`} subheader={<li />} >
                {Object.keys(props.items).map(renderSection)}
            </List>
        </div>
    );
}