import axios from "axios";
import config from "../../config";
import Route from "../Route/Route";
import {RawGETReturns} from "../types";
import AbstractValue from "./AbstractValue";
import {Jsonable} from "./Form";
import {Simulate} from "react-dom/test-utils";

export type LoadHook = (percentage: number) => void

abstract class DataBaseValue extends AbstractValue{

    public attributes: DataBaseValueAttributes;

    public static route: Route;
    public static asString: string;
    public static paginated: boolean;

    public static attributes: Array<string> = []

    public static readableAttribute(attribute: string) {
        const capitalizedStart = attribute.charAt(0).toUpperCase() + attribute.slice(1);
        return capitalizedStart.replace("_", " ");
    }

    abstract merge(toMerge: PartialRawDataBaseValueAttributes): AbstractValue;

    public containsFile(): boolean { return false; }

    public serialize(): Jsonable | FormData {
        if(this.containsFile())
            return this.serializeToFormDate()
        else
            return this.serializeToJson()
    }

    abstract serializeToJson(): Jsonable

    public serializeToFormDate(): FormData {
        const formData = new FormData();

        function visit(name: string, obj: Jsonable) {
            if(Array.isArray(obj)) {
                obj.forEach(newObj => visit(`${name}[]`, newObj))
            } else if(obj !== undefined && obj !== null && obj.constructor === File) {
                formData.append(name, obj as File)
            } else if(obj !== undefined && obj !== null && typeof obj === "object") {
                Object.entries(obj).forEach(([newName, newObj]) => visit(`${name}[${newName}]`, newObj))
            } else if(obj !== undefined) {
                formData.append(name, String(obj))
            }
        }

        const serialized = this.serializeToJson();

        if(serialized !== null && typeof serialized === "object" && serialized.constructor !== File)
            Object.entries(serialized).forEach(([name, structure]) => visit(name, structure));

        return formData
    }

    constructor(params: RawDataBaseValueAttributes) {
        super()
        this.attributes = params;
    }

    public isStub(): boolean {
        return this.attributes.id === ""
    }

    public toRaw(): RawDataBaseValueAttributes {
        return {
            id: this.attributes.id,
            created_at: this.attributes.created_at,
            updated_at: this.attributes.updated_at,
            discarded_at: this.attributes.discarded_at
        }
    }

    public static async HTTPRequest(params: HTTPRequestParams) {
        try {
            const response = await axios({
                method: params.method,
                url: `${config.api}${params.route.toString()}`,
                data: params.data ? params.data : {},
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ...params.headers
                }
            });
            return response.data
        } catch(error) {
            throw error
        }
    }

    public static async GETRequest<C extends RawDataBaseValueAttributes = RawDataBaseValueAttributes>(route: Route, loadHook?: LoadHook): Promise<RawGETReturns<C>> {
        try {
            const response = await axios({
                method: 'GET',
                url: `${config.api}${route.toString()}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                onDownloadProgress: progressEvent => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                    loadHook && loadHook(percentage)
                }
            });
            return response.data;
        } catch(error) {
            throw error
        }
    }

    public async POSTRequest(params: POSTRequestParams): Promise<any> {
        try {
            return DataBaseValue.HTTPRequest({...params, method: 'post'})
        } catch(error) {
            throw error
        }
    }

    public async PUTRequest(params: PUTRequestParams): Promise<any> {
        try {
            return DataBaseValue.HTTPRequest({...params, route: params.keepRoute ? params.route : params.route.addRoute(this.attributes.id), method: 'put'})
        } catch(error) {
            throw error
        }
    }

    public async DELETERequest(params: DELETERequestParams): Promise<any> {
        try {
            return DataBaseValue.HTTPRequest({...params, route: params.route.addRoute(this.attributes.id), method: 'delete'})
        } catch(error) {
            throw error
        }
    }
}

export interface PartialRawDataBaseValueAttributes {
    id?: string,
    created_at?: Date | null,
    updated_at?: Date | null,
    discarded_at?: Date | null
}

export interface RawDataBaseValueAttributes {
    id: string,
    created_at: Date | null,
    updated_at: Date | null,
    discarded_at: Date | null
}

export interface DataBaseValueAttributes {
    id: string,
    created_at: Date | null,
    updated_at: Date | null,
    discarded_at: Date | null
}

export interface HTTPRequestParams {
    method: 'post' | 'put' | 'delete',
    route: Route,
    data?: Jsonable | FormData,
    headers?: {[index: string]: string}
}

export interface POSTRequestParams{
    route: Route,
    data?: Jsonable | FormData,
    headers?: {[index: string]: string}
}

export interface PUTRequestParams {
    route: Route,
    keepRoute?: boolean
    data?: Jsonable | FormData,
    headers?: {[index: string]: string}
}

export interface DELETERequestParams {
    route: Route,
    data?: Jsonable | FormData,
    headers?: {[index: string]: string}
}

export default DataBaseValue;