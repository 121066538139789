import DataBaseValue, {RawDataBaseValueAttributes} from "../../classes/DataBaseValue/DataBaseValue";
import React, {useEffect, useState, useContext} from "react";
import AddEditDialog, {Purpose} from "./AddEditDialog";
import {CrudValue} from "../../classes/DataBaseValue/CrudValue";
import GlobalContext from "../../GlobalContext";

export default function EditDialog<T extends CrudValue>(props: {instance: T, Class: new (params: RawDataBaseValueAttributes) => T, open: boolean, onClose: () => void, onSubmit: () => void}) {

    const [state, setState] = useState<T>(props.instance)

    const { raiseSuccess } = useContext(GlobalContext);

    const submit = async () => {

        await state.put();
        raiseSuccess(`${(props.Class as any).asString.capitalizeFirst()} updated`);
        props.onSubmit();


        /*await state.put()
        gActions.raiseSuccess(`${props.Class.asString.capitalizeFirst()} updated`);
        props.onSubmit();*/
    }

    useEffect(() => {
        setState(props.instance)
    }, [props.open])

    return (
        <AddEditDialog
            Class={props.Class}
            instance={state}
            hook={(update) => setState(update as T)}
            open={props.open}
            onClose={props.onClose}
            onSubmit={submit}
            purpose={Purpose.Edit}
        />
    )
}