import DataBaseValueCollection from "./DataBaseValueCollection";
import Group, {PartialRawGroupAttributes, RawGroupAttributes} from "./Group";
import {RawScanTagAttributes} from "./ScanTag";
import PartyGroupsAssociation, {RawPartyGroupsAssociationAttributes} from "./PartyGroupsAssociation";

//We need the PartyGroupsAssociation ID
class PartyGroupsAssociations extends DataBaseValueCollection {
    public party_groups_associations: Array<PartyGroupsAssociation>

    constructor(partyGroupsAssociations: Array<RawPartyGroupsAssociationAttributes>) {
        super();
        this.party_groups_associations = partyGroupsAssociations.map(g => new PartyGroupsAssociation(g))
    }

    public static makeStub(): PartyGroupsAssociations {
        return new PartyGroupsAssociations([])
    }

    public find(findFn: (pga: PartyGroupsAssociation) => boolean): PartyGroupsAssociation | undefined {
        return this.party_groups_associations.find(findFn)
    }

    public toRaw(): Array<RawPartyGroupsAssociationAttributes> {
        return this.party_groups_associations.map(pga => pga.toRaw());
    }

    public merge(toMerge: Array<RawPartyGroupsAssociationAttributes>): PartyGroupsAssociations {
        const old = this.toRaw();

        const newEntries = toMerge
            .filter(m => !old.find(o => !o.discarded_at && o.id === m.id))

        const updatedEntries = old
            .filter(o => !o.discarded_at && toMerge.find(m => o.id === m.id))

        const discardedEntries = old
            .filter(o => o.discarded_at || !toMerge.find(m => m.id === o.id))

        const entries = [
            ...newEntries,
            ...updatedEntries.map(o => {
                const updated = toMerge.find(m => m.group.id === o.group.id) as RawPartyGroupsAssociationAttributes;
                return {...o, group: updated.group}
            }),
            ...discardedEntries.map(o => ({...o, discarded_at: new Date()}))
        ]

        return new PartyGroupsAssociations(entries);
    }
}

export default PartyGroupsAssociations