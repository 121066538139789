import React, {useEffect, useState} from "react";
import { Autocomplete } from '@mui/material';
import { CircularProgress, TextField, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DataBaseValue from "../../classes/DataBaseValue/DataBaseValue";
import makeStyles from '@mui/styles/makeStyles';
import { CrudValue } from "../../classes/DataBaseValue/CrudValue";


function DataBaseValueAutoCompleteBase<T extends CrudValue>(props: {
    Class: any,
    classes?: object,
    label: string,
    helperText?: string,
    className?: string,
    multiple?: boolean,
    fullWidth: boolean,
    onSelect: (val: T | Array<T> | null) => void,
    customSearch?: (value: string) => Promise<Array<T>>,
    preloadOptions?: boolean,
    value?: T | Array<T>
    key?: any
}) {

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<Array<T>>([]);

    const search = async (value: string) => {
        await setLoading(true);
        setOptions(props.customSearch ? await props.customSearch(value) : (await props.Class.search(value)).records);
        await setLoading(false);
    };

    useEffect(() => {
        if(props.preloadOptions)
            (async () => {
                const response = props.customSearch ? await props.customSearch("") : (await props.Class.search("")).records
                setOptions(response)
            })()
    }, [])

    useEffect(() => {
        setOptions(o => Array.isArray(props.value) ? props.value : props.value !== undefined ? [props.value] : o)
    }, [props.value])

    return (
        <Autocomplete
            key={props.key}
            classes={props.classes}
            className={props.className ? props.className : ''}
            multiple={props.multiple !== null && props.multiple !== undefined ? props.multiple : false}
            fullWidth={props.fullWidth}
            loading={loading}
            onInputChange={(data:any, value, reason) => search(value.toString().trim())}
            onChange={(event, newValue) => {
                //Sometimes value is null when fully deleting everything from the form field
                props.onSelect(newValue as T | Array<T> | null)
            }}
            options={options}
            getOptionLabel={(option) => option.toString()}
            isOptionEqualToValue={(option, value) => {
                if(Array.isArray(option) && Array.isArray(value)) {
                    return option.every((o, i) => o.attributes.id === value[i].attributes.id)
                // For some reason, sometimes value is not of type T at runtime
                } else if(typeof value !== "string") {
                    return (option as T).attributes.id === (value as T).attributes.id
                } else {
                    return false
                }
            }}
            renderInput={(params) => <TextField
                {...params}
                label={props.label}
                helperText={props.helperText ? props.helperText : false}
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress size={20} color="inherit" /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }}/>}
            filterOptions={(x) => x}
            value={Array.isArray(props.value) ? props.value.filter(v => v.attributes.discarded_at === null) : props.value}
        />
    );
}

export default function DataBaseValueAutoComplete<T extends CrudValue>(props: {
    Class: any,
    classes?: object,
    label: string,
    helperText?: string,
    className?: string,
    multiple?: boolean,
    fullWidth: boolean,
    onSelect: (val: T | null ) => void,
    customSearch?: (value: string) => Promise<Array<T>>,
    preloadOptions?: boolean,
    value?: T
    key?: any
}) {
    return DataBaseValueAutoCompleteBase<T>({...props, onSelect: (val) => props.onSelect(val as T | null), multiple: false})
}

export function DataBaseValueAutoCompleteMultiple<T extends CrudValue>(props: {
    Class: any,
    label: string,
    fullWidth: boolean,
    className?: string,
    onSelect: (val: Array<T>) => void,
    customSearch?: (value: string) => Promise<Array<T>>,
    preloadOptions?: boolean,
    value?: Array<T>,
    key?: any
}) {
    return DataBaseValueAutoCompleteBase<T>({...props, onSelect: (val) => props.onSelect(val === null ? [] : val as Array<T>), multiple: true})
}