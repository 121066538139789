import FormBody, {FormProps} from "./FormBody";
import ProductGroupsAssociation from "../../../classes/DataBaseValue/ProductGroupsAssociation";
import React from "react";
import PriceField from "./PriceField";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import GroupSelection from "./GroupSelection";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    sidebyside: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

export interface ProductGroupsAssociationFormProps extends FormProps<ProductGroupsAssociation> {
    children: React.ReactNode
}

function ProductGroupsAssociationForm(props: ProductGroupsAssociationFormProps) {

    const classes = useStyles();

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    return (
        <div className={classes.sidebyside}>
            <GroupSelection instance={props.instance.attributes.group} hook={(update) => propagate("group", update)} name="Group" />
            <PriceField instance={new NumberValue(props.instance.attributes.price)} hook={(update) => propagate("price", update)} name="Price" />
            {props.children}
        </div>
    )
}

export default ProductGroupsAssociationForm