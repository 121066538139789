import {CrudValue} from "./CrudValue";
import {DataBaseValueAttributes, PartialRawDataBaseValueAttributes, RawDataBaseValueAttributes} from "./DataBaseValue";
import Route from "../Route/Route";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import InventoryTransferItem, {RawInventoryTransferItemAttributes} from "./InventoryTransferItem";
import Product from "./Product";

class InventoryTransfer extends CrudValue {
    public attributes: InventoryTransferAttributes;

    public static route = new Route(['inventory_transfers']);
    public static paginated = true;
    public static asString = 'inventory_transfer'

    public static attributeNames: Array<string> = ["From", "To", "Items"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.from_id},
            {Component: BasicContent, content: this.attributes.to_id},
            {Component: ArrayContent, content: this.attributes.inventory_transfer_items.map(iti => `${iti.attributes.amount}x ${iti.attributes.product.attributes.name}`)}
        ]
    }

    public toString() {
        return `From ${this.attributes.from_id} to ${this.attributes.to_id}`
    }

    constructor(params: RawInventoryTransferAttributes) {
        super(params);

        this.attributes = {
            ...params,
            from_id: params.from_id,
            to_id: params.to_id,
            inventory_transfer_items: params.inventory_transfer_items.map(iti => new InventoryTransferItem(iti))
        }
    }

    public removeInventoryTransferItem(inventoryTransferItem: InventoryTransferItem): InventoryTransfer {
        const inventoryTransferItemExists = this.inventoryTransferItemOf(inventoryTransferItem.attributes.product)
        if(inventoryTransferItemExists) {
            return this.merge({
                inventory_transfer_items: this.attributes.inventory_transfer_items.filter(iti =>
                    iti !== inventoryTransferItemExists
                ).map(iti => iti.toRaw())
            })
        } else {
            return this
        }
    }

    public addInventoryTransferItem(inventoryTransferItem: InventoryTransferItem): InventoryTransfer {
        const inventoryTransferItemExists = this.inventoryTransferItemOf(inventoryTransferItem.attributes.product)
        if(inventoryTransferItemExists) {
            return this.merge({
                inventory_transfer_items: this.attributes.inventory_transfer_items.map(iti =>
                    iti === inventoryTransferItemExists
                        ? iti.merge({amount: inventoryTransferItem.attributes.amount}).toRaw()
                        : iti.toRaw()
                )
            })
        } else {
            return this.merge({
                inventory_transfer_items: [...this.attributes.inventory_transfer_items, inventoryTransferItem].map(iti => iti.toRaw())
            })
        }
    }

    public inventoryTransferItemOf(product: Product): InventoryTransferItem | undefined {
        return this.attributes.inventory_transfer_items.find(iti => iti.attributes.product.attributes.id === product.attributes.id)
    }

    public static makeStub(): InventoryTransfer {
        return new InventoryTransfer({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            from_id: "",
            to_id: "",
            inventory_transfer_items: []
        })
    }

    public toRaw(): RawInventoryTransferAttributes {
        return {
            from_id: this.attributes.from_id,
            to_id: this.attributes.to_id,
            inventory_transfer_items: this.attributes.inventory_transfer_items.map(iti => iti.toRaw()),
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawInventoryTransferAttributes): InventoryTransfer {
        return new InventoryTransfer({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            inventory_transfer: {
                from_id: this.attributes.from_id,
                to_id: this.attributes.to_id,
                inventory_transfer_items_attributes: this.attributes.inventory_transfer_items.map(iti => iti.serializeToJson())
            }
        }
    }
}

export interface InventoryTransferAttributes extends DataBaseValueAttributes {
    from_id: string,
    to_id: string,
    inventory_transfer_items: Array<InventoryTransferItem>
}

export interface RawInventoryTransferAttributes extends RawDataBaseValueAttributes {
    from_id: string,
    to_id: string,
    inventory_transfer_items: Array<RawInventoryTransferItemAttributes>
}

export interface PartialRawInventoryTransferAttributes extends PartialRawDataBaseValueAttributes {
    from_id?: string,
    to_id?: string,
    inventory_transfer_items?: Array<RawInventoryTransferItemAttributes>
}

export default InventoryTransfer