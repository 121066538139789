import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {DateTime} from "luxon";

declare global {
    interface String {
        capitalizeFirst(): string,
        replaceAll(searchValue: string, replaceValue : string): any;
        isEmail(): boolean;
        humanize(): string;
    }

    interface ObjectConstructor {
        deepClone(obj: {[index: string]: any}): {[index: string]: any}
    }
}

String.prototype.capitalizeFirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.isEmail = function() {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(this.toLowerCase())
}

String.prototype.humanize = function() {
    return this.replaceAll('_', ' ')
}

Object.deepClone = function(obj) {
    return Object.create(Object.getPrototypeOf(obj), Object.getOwnPropertyDescriptors(obj))
}

export const dateTimeRange = (from: DateTime, to: DateTime): Array<DateTime> => {

    if(from.startOf('day').equals(to.startOf('day')))
        return [from.startOf('day')]
    else
        return [from.startOf('day'), ...dateTimeRange(from.plus({days: 1}), to)]
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
