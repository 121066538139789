import DataBaseValueCollection from "./DataBaseValueCollection";
import ProductRightsAssociation, {RawProductRightsAssociationAttributes} from "./ProductRightsAssociation";

class ProductRightsAssociations extends DataBaseValueCollection {
    public product_rights_associations: Array<ProductRightsAssociation>

    constructor(product_rights_associations: Array<RawProductRightsAssociationAttributes>) {
        super();
        this.product_rights_associations = product_rights_associations.map(pra => new ProductRightsAssociation(pra));
    }

    public static makeStub(): ProductRightsAssociations {
        return new ProductRightsAssociations([])
    }

    public toRaw(): Array<RawProductRightsAssociationAttributes> {
        return this.product_rights_associations.map(pra => pra.toRaw());
    }

    public merge(toMerge: Array<RawProductRightsAssociationAttributes>): ProductRightsAssociations {

        const old = this.toRaw();

        const newEntries = toMerge
            .filter(m => !old.find(o => o.discarded_at === null && o.right.id === m.right.id));

        const updatedEntries = old
            .filter(o => o.discarded_at === null && toMerge.find(m => m.right.id === o.right.id));

        const discardedEntries = old
            .filter(o => o.discarded_at !== null || !toMerge.find(m => m.right.id === o.right.id));

        const entries = [
            ...newEntries,
            ...updatedEntries.map(o => {
                const updated = toMerge.find(m => m.right.id === o.right.id) as RawProductRightsAssociationAttributes
                return {...o, sellable: updated.sellable, rentable: updated.rentable, right: updated.right}
            }),
            ...discardedEntries.map(o => ({...o, discarded_at: new Date()}))
        ]
        return new ProductRightsAssociations(entries);
    }
}

export default ProductRightsAssociations