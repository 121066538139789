import React from 'react';
import Message from "./Message";
import {SnackbarProvider} from "notistack";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => createStyles({
    main: {
        '& > *': {
            '@media (max-width: 1024px)': {
                marginBottom: '10%'
            }
        }
    }
}));

export default function MessageProvider(props: {}) {
    const classes = styles();

    return (
        <div className={classes.main}>
            <SnackbarProvider maxSnack={3} >
                <Message />
            </SnackbarProvider>
        </div>
    );
}