import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";

class ScanTag extends DataBaseValue {
    public attributes: ScanTagAttributes

    constructor(params: RawScanTagAttributes) {
        super(params);
        this.attributes = params;
    }

    public static makeStub(): ScanTag {
        return new ScanTag({
            tag: "",
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawScanTagAttributes {
        return {
            tag: this.attributes.tag,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawScanTagAttributes): ScanTag {
        return new ScanTag({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            tag: this.attributes.tag
        }
    }
}

export interface ScanTagAttributes extends DataBaseValueAttributes {
    tag: string
}

export interface RawScanTagAttributes extends RawDataBaseValueAttributes {
    tag: string
}

export interface PartialRawScanTagAttributes extends PartialRawDataBaseValueAttributes {
    tag?: string
}

export default ScanTag