import FormBody, {FormProps} from "./FormBody";
import PartyGroupsAssociations from "../../../classes/DataBaseValue/PartyGroupsAssociations";
import React from "react";
import Group from "../../../classes/DataBaseValue/Group";
import {DataBaseValueAutoCompleteMultiple} from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import PartyGroupsAssociation from "../../../classes/DataBaseValue/PartyGroupsAssociation";

function GroupsSelection(props: FormProps<PartyGroupsAssociations>) {

    const {party_groups_associations} = props.instance

    return (
        <DataBaseValueAutoCompleteMultiple
            Class={Group}
            label="Select groups"
            fullWidth={false}
            value={party_groups_associations.filter(pga => pga.attributes.discarded_at === null).map(pga => pga.attributes.group)}
            onSelect={(update: Array<Group>) =>
                props.hook(props.instance.merge(update.map(g => PartyGroupsAssociation.makeStub().merge({group: g.toRaw()}).toRaw())))}
            preloadOptions={true}
        />
    )
}

export default GroupsSelection