import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid, Paper, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React, {useEffect, useState} from "react";
import Person from "../../classes/DataBaseValue/Person";
import Consumable from "../../classes/DataBaseValue/Consumable";
import ColaZeroesBram from "./ColaZeroesBram";
import SaleStats from "./SaleStats";
import NegativeBalances from "./NegativeBalances";
import OutOfStockProducts from "./OutOfStockProducts";
import MostPopularProduct from "./MostPopularProduct";
import '../../index.css'
import CoolestPerson from "./CoolestPerson";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'scroll',
        backgroundColor: `#7894b3`
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    logo: {
        fontFamily: 'LogoFont'
    },
    yellowText: {
        color: theme.palette.secondary.main,
        fontFamily: 'LogoFont'
    },
    blueText: {
        color: theme.palette.primary.main,
        fontFamily: 'LogoFont'
    }
}));

/**Home Component
 * 
 * This component shows the home screen.
 */
function Home(props: any) {
    const classes = useStyles();

    const theme = useTheme();

    return (
        <div className={`${classes.root} css-selector`}>
            <Typography component='h1' variant='h1' align='center' className={classes.logo}>
                <span className={classes.blueText}>IG</span><span className={classes.yellowText}>5</span><span className={classes.logo}>tool</span>
            </Typography>
            <Typography variant="h6" align="center">
                Bugs or complaints? Make an issue <a href="https://git.infogroep.be/infogroep/ig5tool_frontend">here</a>
            </Typography>
            <Container className={classes.margin}>
                <Grid className={classes.grid} container spacing={3}>
                    <Grid item>
                        <SaleStats sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }} />
                    </Grid>
                    <Grid item>
                        <ColaZeroesBram sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                            minHeight: 240,
                        }} />
                    </Grid>
                    <Grid item>
                        <MostPopularProduct category='non_alcoholic' />
                    </Grid>
                    <Grid item>
                        <MostPopularProduct category='snack' />
                    </Grid>
                    <Grid item>
                        <MostPopularProduct category='food' />
                    </Grid>
                    <Grid item>
                        <MostPopularProduct category='alcoholic' />
                    </Grid>
                    <Grid item>
                        <CoolestPerson sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                            minHeight: 240,
                        }}  />
                    </Grid>
                    <Grid item>
                        <NegativeBalances />
                    </Grid>
                    <Grid item>
                        <OutOfStockProducts />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Home