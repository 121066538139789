import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Route from "../Route/Route";
import AddressForm from "../../components/DataBaseValueOverview/FormBody/AddressForm";

class Address extends DataBaseValue {

    public attributes: AddressAttributes;

    public static route = new Route(['addresses']);
    public static asString = 'address';
    public static paginated = true;

    public toString() {
        return `${this.attributes.street} ${this.attributes.number}, ${this.attributes.postal_code} ${this.attributes.city}`
    }

    constructor(params: RawAddressAttributes) {
        super(params);
        this.attributes = params;
    }

    public static makeStub() {
        return new Address({
            city: "",
            postal_code: "",
            street: "",
            number: "",
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawAddressAttributes {
        return {
            city: this.attributes.city,
            postal_code: this.attributes.postal_code,
            street: this.attributes.street,
            number: this.attributes.number,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawAddressAttributes): Address {
        return new Address({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            id: this.attributes.id ? this.attributes.id : undefined,
            city: this.attributes.city,
            postal_code: this.attributes.postal_code,
            street: this.attributes.street,
            number: this.attributes.number,
        }
    }

    public static form = AddressForm
}

export interface AddressAttributes extends DataBaseValueAttributes {
    city: string,
    postal_code: string,
    street: string,
    number: string
}

export interface RawAddressAttributes extends RawDataBaseValueAttributes{
    city: string,
    postal_code: string,
    street: string,
    number: string,
}

export interface PartialRawAddressAttributes extends PartialRawDataBaseValueAttributes{
    city?: string,
    postal_code?: string,
    street?: string,
    number?: string,
}

export default Address;