import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Route from "../Route/Route";
import PartyGroupsAssociations from "./PartyGroupsAssociations";
import {RawPartyGroupsAssociationAttributes} from "./PartyGroupsAssociation";
import {CrudValue} from "./CrudValue";

class Party extends CrudValue {

    public attributes: PartyAttributes;

    public static route = new Route(['parties']);
    public static paginated = true;
    public static asString = 'party';

    public attributesToTable() {
        return []
    }

    constructor(params: RawPartyAttributes) {
        super(params);

        this.attributes = {
            ...params,
            party_groups_associations: new PartyGroupsAssociations(params.party_groups_associations)
        }
    }

    public static makeStub(): Party {
        return new Party({
            name: "",
            kind: "",
            balance: 0,
            party_groups_associations: [],
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawPartyAttributes {
        return {
            name: this.attributes.name,
            kind: this.attributes.kind,
            balance: this.attributes.balance,
            party_groups_associations: this.attributes.party_groups_associations.toRaw(),
            ...super.toRaw()
        }
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            id: this.attributes.id ? this.attributes.id : undefined,
            party_groups_associations_attributes: this.attributes.party_groups_associations.party_groups_associations.map(g => ({
                group_id: g.attributes.group.attributes.id,
                id: g.attributes.id ? g.attributes.id : undefined,
                _destroy: g.attributes.discarded_at !== null
            }))
        }
    }

    public merge(toMerge: PartialRawPartyAttributes): Party {
        return new Party({...this.toRaw(), ...toMerge});
    }

    public isPerson(): boolean {
        return this.attributes.kind === 'person'
    }

    public balanceToEuro(): number {
        return (this.attributes.balance / 100)
    }

    public balanceToEuroFixed(): string {
        return this.balanceToEuro().toFixed(2)
    }

    public balanceToEuroWithSign(): string {
        return `€${this.balanceToEuroFixed()}`
    }
}

export interface PartyAttributes extends DataBaseValueAttributes {
    name: string,
    kind: string,
    balance: number,
    party_groups_associations: PartyGroupsAssociations
}

export interface RawPartyAttributes extends RawDataBaseValueAttributes{
    name: string,
    kind: string,
    balance: number,
    party_groups_associations: Array<RawPartyGroupsAssociationAttributes>
}

export interface PartialRawPartyAttributes extends PartialRawDataBaseValueAttributes{
    name?: string,
    kind?: string,
    balance?: number,
    party_groups_associations?: Array<RawPartyGroupsAssociationAttributes>
}

export default Party;