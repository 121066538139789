import Route from "../Route/Route";
import Book from "./Book";
import { CrudValue } from "./CrudValue";
import { DataBaseValueAttributes, PartialRawDataBaseValueAttributes, RawDataBaseValueAttributes } from "./DataBaseValue";
import InventoryTransfer, { RawInventoryTransferAttributes } from "./InventoryTransfer";
import Party, { RawPartyAttributes } from "./Party";
import Product, { RawProductAttributes } from "./Product";
import ProductFactory from "./ProductFactory";
import Transaction, { RawTransactionAttributes } from "./Transaction";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";

class Rent extends CrudValue {
    public attributes: RentAttributes;

    public static route = new Route(['rents']);
    public static paginated = true;
    public static asString = 'rent';

    public static attributeNames: Array<string> = ["Party", "Product", "Start Time", "End Time", "Extensions", "Overtime", "Warranty returned"];

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.party.attributes.name},
            {Component: BasicContent, content: this.attributes.product.attributes.name},
            {Component: BasicContent, content: this.attributes.start_time.toString()},
            {Component: BasicContent, content: this.attributes.end_time? this.attributes.end_time.toString() : "None"},
            {Component: BasicContent, content: this.attributes.extension_count},
            {Component: BasicContent, content: this.isOvertime() ? 'Yes' : 'No'},
            {Component: BasicContent, content: this.attributes.warranty_return ? 'Yes' : 'No'}
        ];
    }

    public isOvertime(): boolean {
        if(this.attributes.end_time === null) {
            return (+(new Date()) - +(this.attributes.start_time)) > 14 * (this.attributes.extension_count + 1) * 86400
        } else {
            return (+(this.attributes.end_time) - +(this.attributes.start_time)) > 14 * (this.attributes.extension_count + 1) * 86400
        }
    }

    public async return(returnWarranty: boolean) {
        return await this.put(Rent.route.addRoute(this.attributes.id).addRoute('return'), true, {return_warranty: returnWarranty})
    }

    public async extend() {
        return await this.put(Rent.route.addRoute(this.attributes.id).addRoute('extend'), true)
    }

    public toString() {
        return ""
    }

    constructor(params: RawRentAttributes) {
        super(params);

        this.attributes = {
            ...params,
            product_provision: new InventoryTransfer(params.product_provision),
            product_return: params.product_return ? new InventoryTransfer(params.product_return) : undefined,
            warranty_provision: new Transaction(params.warranty_provision),
            warranty_return: params.warranty_return ? new Transaction(params.warranty_return) : undefined,
            product: ProductFactory.create(params.product),
            party: new Party(params.party),
            start_time: params.start_time,
            end_time: params.end_time,
            extension_count: params.extension_count
        }
    }

    public toRaw(): RawRentAttributes {
        return {
            product_provision: this.attributes.product_provision.toRaw(),
            product_return: this.attributes.product_return ? this.attributes.product_return.toRaw() : undefined,
            warranty_provision: this.attributes.warranty_provision.toRaw(),
            warranty_return: this.attributes.warranty_return ? this.attributes.warranty_return.toRaw() : undefined,
            product: this.attributes.product.toRaw(),
            party: this.attributes.party.toRaw(),
            start_time: this.attributes.start_time,
            end_time: this.attributes.end_time,
            extension_count: this.attributes.extension_count,
            ...super.toRaw()
        }
    }

    public static makeStub(): Rent {
        return new Rent({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            product_provision: InventoryTransfer.makeStub().toRaw(),
            product_return: undefined,
            warranty_provision: Transaction.makeStub().toRaw(),
            warranty_return: undefined,
            product: Book.makeStub().toRaw(),
            party: Party.makeStub().toRaw(),
            start_time: new Date(),
            end_time: null,
            extension_count: 0
        })
    }

    public merge(toMerge: PartialRawRentAttributes): Rent {
        return new Rent({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            rent: {
                product_id: this.attributes.product.attributes.id,
                party_id: this.attributes.party.attributes.id
            }
        }
    }
}

export default Rent;

export interface RentAttributes extends DataBaseValueAttributes {
    product_provision: InventoryTransfer,
    product_return: InventoryTransfer | undefined,
    warranty_provision: Transaction,
    warranty_return: Transaction | undefined,
    product: Product,
    party: Party,
    start_time: Date,
    end_time: Date | null,
    extension_count: number
}

export interface RawRentAttributes extends RawDataBaseValueAttributes {
    product_provision: RawInventoryTransferAttributes,
    product_return: RawInventoryTransferAttributes | undefined,
    warranty_provision: RawTransactionAttributes,
    warranty_return: RawTransactionAttributes | undefined,
    product: RawProductAttributes,
    party: RawPartyAttributes,
    start_time: Date,
    end_time: Date | null,
    extension_count: number
}

export interface PartialRawRentAttributes extends PartialRawDataBaseValueAttributes {
    product_provision?: RawInventoryTransferAttributes,
    product_return?: RawInventoryTransferAttributes | undefined,
    warranty_provision?: RawTransactionAttributes,
    warranty_return?: RawTransactionAttributes | undefined,
    product?: RawProductAttributes,
    party?: RawPartyAttributes,
    start_time?: Date,
    end_time?: Date | null,
    extension_count?: number
}