import React, {useState} from 'react';
import TransactionDrawer from "./TransactionDrawer";
import {HashRouter, Route} from "react-router-dom";
import Transfer from "./Transfer";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import History from "./History";
import { Breakpoint, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import AccountTransactions, {Variant} from "./AccountTransactions";
import Transaction, {RawTransactionAttributes} from "../../classes/DataBaseValue/Transaction";
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => createStyles({
    fullHeight: {
        height: '100%'
    },
    halfHeight: {
        height: '50%',
        maxHeight: '50%'
    }
}));

export interface IHistory {
    transactions: Array<Transaction>,
    totalPages: number,
    page: number
}

function Transactions(props: any) {


    const [history, setHistory] = useState<IHistory>({
        transactions: [],
        totalPages: 0,
        page: 0
    });

    const updateHistory = async () => {
        if (history.page === 1) {

            const response = await Transaction.get<RawTransactionAttributes, Transaction>(1)

            setHistory({
                totalPages: response.totals.pages,
                transactions: response.records,
                page: 1
            })
        }
    }

    const classes = useStyles();

    const theme = useTheme();
    const showHistoryByWidth = useMediaQuery(theme.breakpoints.up("lg"));
    const isXs = useMediaQuery(theme.breakpoints.only("xs"))

    const showRoute = (): boolean => {
        const route = window.location.hash.substring(2);
        return route !== 'transactions'
    };

    return (
        <HashRouter>
            <TransactionDrawer />

            <div className={classes.fullHeight} style={{width: isXs ? '100%' : `calc(100% - 250px)`}} >
                {
                    showRoute() && (
                        <div className={classes.halfHeight}  >
                            <Route path='/transactions/transfer' render={props => <Transfer            updateHistory={updateHistory}                           />} />
                            <Route path='/transactions/send'     render={props => <AccountTransactions updateHistory={updateHistory} variant={Variant.Send}    />} />
                            <Route path='/transactions/receive'  render={props => <AccountTransactions updateHistory={updateHistory} variant={Variant.Receive} />} />
                        </div>
                    )
                }

                {
                    showHistoryByWidth && (
                        <div className={showRoute() ? classes.halfHeight : classes.fullHeight} >
                            <History history={history} setHistory={setHistory} />
                        </div>
                    )
                }
            </div>
        </HashRouter>
    );
}

export default Transactions;