import React, { useState, useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';

export interface IGlobalMessage {
    text: string,
    type: 'success' | 'error' | 'warning' | 'info'
}

export type ToTry = (loadHook?: (percentage: number) => void) => Promise<void>

interface GlobalContext {
    theme: string,
    message: false | IGlobalMessage,
    loadingInstances: Array<{id: string, displayText: string, percentage: number, deterministic: boolean}>,

    load: (toTry: ToTry, displayText?: string, loadDeterministically?: boolean) => void,
    raiseSuccess(text: string): void,
    raiseError(error: any): void,
    raiseWarning(text: string): void,
    raiseInfo(text: string): void
    toggleTheme(): void,
    isLightTheme(): boolean
}

const GlobalContext = React.createContext<GlobalContext>({
    theme: "light",
    message: false,
    loadingInstances: [],

    load: () => { },
    raiseSuccess: () => { },
    raiseError: () => { },
    raiseWarning: () => { },
    raiseInfo: () => { },
    toggleTheme: () => { },
    isLightTheme: () => { return false; },
})

export const GlobalContextProvider: React.FC<{}> = (props) => {

    // Reducer to increment and decrement loadingInstances
    function loadingInstancesReducer(state, action) {
        switch (action.type) {
            case 'increment': {
                return [...state, action]
                //return state + 1
            }
            case 'decrement': {
                return state.filter(s => s.id !== action.id)
            }
            case 'changePercentage': {
                return state.map(s => s.id === action.id ? {...s, percentage: action.percentage} : s)
            }
        }
    }

    const [loadingInstances, dispatchLoadingInstances] = useReducer(loadingInstancesReducer, []);
    const [message, setMessage] = useState(false as false | IGlobalMessage);
    const [theme, setTheme] = useState("light");

    // Actions
    function raise(text: string, type: 'success' | 'error' | 'warning' | 'info') {
        setMessage({ text, type });
    }

    function raiseSuccess(text: string) {
        raise(text, 'success')
    }

    function raiseError(error: any) {
        if (error.response && error.response.statusText && error.response.data)
            if (Array.isArray(error.response.data))
                raise(`${error.response.statusText}: ${error.response.data.map((o: any) => Object.entries(o).map(([k, v]: [any, any]) => `${k}: ${v.join(', ')}`).join(', ')).join(', ')}`, 'error')
            else if (typeof error.response.data === "object" && Object.values(error.response.data).every(d => Array.isArray(d))) {

                const errorString = Object.entries(error.response.data).map(([key, value]: [any, any]) => `${key}: ${value.join(', ')}`).join(';')

                raise(`${error.response.statusText}: ${errorString}`, 'error')
            } else
                raise(`${error.response.statusText}: ${error.response.data}`, 'error');
        else
            raise(error.toString(), 'error');
    }

    function raiseWarning(text: string) {
        raise(text, 'warning')
    }

    function raiseInfo(text: string) {
        raise(text, 'info')
    }

    function toggleTheme() {
        setTheme(theme == 'light' ? 'dark' : 'light')
    }

    function isLightTheme(): boolean {
        return theme === 'light'
    }

    async function load(toTry: ToTry, displayText?: string, loadDeterministically?: boolean) {
        const id = uuidv4()
        dispatchLoadingInstances({ type: "increment", id, displayText: displayText ?? "Loading something", percentage: 0, deterministic: loadDeterministically === true });

        try {
            await toTry(percentage => dispatchLoadingInstances({ type: "changePercentage", percentage, id }));
        } catch (e) {
            console.error(e);
            raiseError(e)
        } finally {
            dispatchLoadingInstances({ type: "decrement", id });
        }
    }


    return (
        <GlobalContext.Provider value={{
            loadingInstances, message, theme,
            raiseSuccess, raiseError, raiseWarning, raiseInfo, toggleTheme, isLightTheme, load
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalContext;
