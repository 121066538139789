import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import Category, {CategoryAttributes, PartialRawCategoryAttributes, RawCategoryAttributes} from "./Category";
import {CrudValue} from "./CrudValue";
import ProductRightsAssociation, {RawProductRightsAssociationAttributes} from "./ProductRightsAssociation";
import ProductGroupsAssociation, {RawProductGroupsAssociationAttributes} from "./ProductGroupsAssociation";
import ScanTag, {RawScanTagAttributes} from "./ScanTag";
import ScanTags from "./ScanTags";
import ProductRightsAssociations from "./ProductRightsAssociations";
import ProductGroupsAssociations from "./ProductGroupsAssociations";
import React from "react";
import Content from "../../components/DataBaseValueOverview/TableCellContent/Content";
import Party from "./Party";
import Group from "./Group";
import Right from "./Right";

abstract class Product extends CrudValue{

    public attributes: ProductAttributes;

    public static kind = "product";
    public static paginated = true;
    public static route = new Route(['products']);

    constructor(params: RawProductAttributes) {
        super(params);

        this.attributes = {
            ...params,
            category: new Category(params.category),
            product_rights_associations: new ProductRightsAssociations(params.product_rights_associations),
            product_groups_associations: new ProductGroupsAssociations(params.product_groups_associations),
            scan_tags: new ScanTags(params.scan_tags)
        }
    }

    public toString(): string {
        return this.attributes.name
    }

    public toRaw(): RawProductAttributes {
        return {
            ...this.attributes,
            rental_period: this.attributes.rental_period,
            extension_period: this.attributes.extension_period,
            category: this.attributes.category.toRaw(),
            kind: this.attributes.kind,
            name: this.attributes.name,
            photo: this.attributes.photo,
            product_groups_associations: this.attributes.product_groups_associations.toRaw(),
            product_rights_associations: this.attributes.product_rights_associations.toRaw(),
            scan_tags: this.attributes.scan_tags.toRaw(),
            ...super.toRaw()
        }
    }

    public calculatePrice(party: Party): string | null {
        const groups = this.attributes.product_groups_associations
            .sort((f, s) => f.attributes.price < s.attributes.price ? -1 : 1)
            .filter((pga1) =>
                !!party.attributes.party_groups_associations.find(pga2 =>
                    pga1.attributes.group.attributes.id === pga2.attributes.group.attributes.id) || pga1.attributes.group.attributes.name === 'non-member')

        return groups.length > 0 ? `${(groups[0].attributes.price / 100).toFixed(2)}` : null;
    }

    public overlappingGroups(party: Party): boolean {
        const groups = this.attributes.product_groups_associations.product_groups_associations.filter(pga1 =>
            !!party.attributes.party_groups_associations.find(pga2 =>
                pga2.attributes.group.attributes.id === pga2.attributes.group.attributes.id) || pga1.attributes.group.attributes.name === 'non-member'
            )
        return groups.length > 0
    }

    public hasRightToBuy(party: Party): boolean {
        const productRights = this.attributes.product_rights_associations.product_rights_associations.map(pra => pra.attributes.right)

        const permissibleRight = party.attributes.party_groups_associations.party_groups_associations
            .map(pga => pga.attributes.group)
            .reduce((rights: Array<Right>, group: Group) => [...rights, ...group.attributes.rights.rights], [])
            .find(groupRight => productRights.find(productRight => productRight.attributes.id === groupRight.attributes.id) !== undefined)

        return permissibleRight !== undefined
    }

    abstract merge(toMerge: PartialRawProductAttributes): Product;

    abstract attributesToTable(): Array<{ Component: React.FunctionComponent<{content: Content}>; content: Content }>;

    public containsFile(): boolean {
        return true;
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            id: this.attributes.id ? this.attributes.id : undefined,
            name: this.attributes.name,
            photo: this.attributes.photo && this.attributes.photo.constructor === File ? this.attributes.photo : undefined,
            rental_period: this.attributes.rental_period,
            extension_period: this.attributes.extension_period,
            category_id: this.attributes.category.attributes.id,
            product_rights_associations_attributes: this.attributes.product_rights_associations.product_rights_associations.map(pra => pra.serializeToJson()),
            product_groups_associations_attributes: this.attributes.product_groups_associations.product_groups_associations.map(pga => pga.serializeToJson()),
            scan_tags_attributes: this.attributes.scan_tags.scan_tags.map(st => ({
                tag: st.attributes.tag,
                id: st.attributes.id ? st.attributes.id : undefined,
                _destroy: !!st.attributes.discarded_at
            }))
        }
    }

    public static async getByScan(value: string): Promise<Product> {
        const matching: Array<Product> = (await this.get<RawProductAttributes, Product>(0, Product.route.addPredicate(new QueryPredicate('scan', value)))).records
        if(matching.length < 1)
            throw new Error(`No product identified with this scan`)
        else
            return matching[0];
    }

    public static async getByCategory<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(page: number, category: Category) {
        return await this.get(page, this.route.addPredicate(new QueryPredicate("from_category", category.attributes.id)))
    }

    public static async getTotalsByCategory<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(category: Category) {
        return await this.getTotals(this.route.addPredicate(new QueryPredicate("from_category", category.attributes.id)))
    }

    public async addRight(rightId: string, sellable: boolean, rentable: boolean) {
        return await this.POSTRequest({
            route: Product.route.addRoute(this.attributes.id).addRoute("rights").addRoute(rightId),
            data: {product_rights_association: {sellable, rentable}}
        })
    }

    public async editRight(rightId: string, sellable: boolean, rentable: boolean) {
        return await this.PUTRequest({
            route: Product.route.addRoute(this.attributes.id).addRoute("rights").addRoute(rightId),
            data: {sellable, rentable}
        })
    }

    public async removeRight(rightId: string) {
        return await this.DELETERequest({
            route: Product.route.addRoute(this.attributes.id).addRoute("rights").addRoute(rightId)
        })
    }
}

export interface ProductAttributes extends DataBaseValueAttributes {
    name: string,
    photo: string,
    kind: string,
    rental_period: number,
    extension_period: number,
    category: Category,
    product_rights_associations: ProductRightsAssociations,
    product_groups_associations: ProductGroupsAssociations,
    scan_tags: ScanTags
}

export interface RawProductAttributes extends RawDataBaseValueAttributes {
    name: string,
    photo: string,
    kind: string,
    rental_period: number,
    extension_period: number,
    category: RawCategoryAttributes,
    product_rights_associations: Array<RawProductRightsAssociationAttributes>,
    product_groups_associations: Array<RawProductGroupsAssociationAttributes>,
    scan_tags: Array<RawScanTagAttributes>
}

export interface PartialRawProductAttributes extends PartialRawDataBaseValueAttributes {
    name?: string,
    photo?: string,
    kind?: string,
    rental_period?: number,
    extension_period?: number,
    category?: PartialRawCategoryAttributes,
    product_rights_associations?: Array<RawProductRightsAssociationAttributes>,
    product_groups_associations?: Array<RawProductGroupsAssociationAttributes>,
    scan_tags?: Array<RawScanTagAttributes>
}

export default Product