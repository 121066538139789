import React, { useContext } from 'react';
import {Backdrop, CircularProgress, Theme, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import GlobalContext from '../../GlobalContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        zIndex: theme.zIndex.modal + 10000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default function Loading(props: any) {
    const { loadingInstances } = useContext(GlobalContext);

    const classes = useStyles();

    const determinate = loadingInstances.filter(i => i.deterministic).length > 0
    const accumulatedPercentages = loadingInstances.reduce((acc, val) => val.percentage + acc, 0)
    const length = loadingInstances.length > 0 ? loadingInstances.length : 1
    const average = accumulatedPercentages / length

    return (
        <Backdrop open={loadingInstances.length > 0} classes={{root: classes.root}} >
            <CircularProgress color='secondary' variant={determinate ? "determinate" : "indeterminate"} value={average} />
            <div>
                {loadingInstances.map(({displayText}) => (
                    <Typography component='p' variant='h5' color={theme => 'beige'}>
                        {displayText}
                    </Typography>
                ))}
            </div>
        </Backdrop>
    );
}