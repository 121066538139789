import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";
import usePrint, { Log } from "../../Printer/usePrint";
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%'
    },
    table: {
        width: '100%',
        overflowY: 'scroll',
        height: '100%'
    },
    breakText: {
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    topText: {
        margin: '2rem'
    },
    error: {
        color: 'red'
    }
}))

function Printer(props: any) {

    const [logs, setLogs] = useState<Array<Log>>([])

    const classes = useStyles();

    usePrint((data: Log) => setLogs(oldLogs => [...oldLogs, data]))

    return (
        <div className={classes.root}>
            <Typography variant="body1" className={classes.topText}>
                Printer logs will arrive here. Newest logs are at the bottom.
            </Typography>
            <TableContainer className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Text</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow key={index}>
                                <TableCell>{log.date}</TableCell>
                                <TableCell className={`${classes.breakText} ${log.type === 'error' ? classes.error : ''}`}>{log.text}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Printer;