import FormBody, {FormProps} from "./FormBody";
import Rights from "../../../classes/DataBaseValue/Rights";
import {DataBaseValueAutoCompleteMultiple} from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import React from "react";
import Right from "../../../classes/DataBaseValue/Right";

function RightsSelection(props: FormProps<Rights>) {

    const {rights} = props.instance;

    return (
        <DataBaseValueAutoCompleteMultiple
            Class={Right}
            label="Select rights"
            fullWidth={false}
            value={rights}
            onSelect={(update: Array<Right>) => props.hook(props.instance.merge(update.map(r => r.toRaw())))}
            preloadOptions={true}
        />
    )
}

export default RightsSelection