import FormBody, {FormBodyProps, FormProps} from "./FormBody";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import MaterialTextField from "@mui/material/TextField";
import React from "react";

function NumericalField(props: FormProps<NumberValue>) {
    return (
        <MaterialTextField
            error={false}
            required={false}
            label={props.name}
            variant="outlined"
            value={isNaN(props.instance.value) ? "" : props.instance.value.toString()}
            onChange={(e) =>
                props.hook(new NumberValue(isNaN(parseInt(e.target.value)) ? NaN : parseInt(e.target.value)))
            }
        />
    )
}

export default NumericalField