import React, {useContext, useState} from 'react';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Breakpoint, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { Button, FormControlLabel, FormGroup, IconButton, TextField, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import PriceInput from "../../components/PriceInput/PriceInput";
import DataBaseValueAutoComplete, {DataBaseValueAutoCompleteMultiple} from "../../components/DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import Person from '../../classes/DataBaseValue/Person';
import {BooleanValue} from "../../classes/DataBaseValue/BasicValues";
import Checkbox from "@mui/material/Checkbox";
import Transaction from "../../classes/DataBaseValue/Transaction";
import axios from "axios";
import config from "../../config";
import InfoIcon from '@mui/icons-material/Info';
import GlobalContext from "../../GlobalContext"
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => createStyles({
    main: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
        height: '100%'
    },
    autoComplete: {
        flex: 1
    },
    margin: {
        marginBottom: '1rem'
    },
    marginBotLeft: {
        marginBottom: '1rem',
        marginLeft: '1rem'
    },
    vertAlign: {
        display: 'flex',
        flexDirection: 'row',
    },
    alignRow: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '1rem'
    },
    alignLeft: {
        marginLeft: '0.5rem',
        marginTop: '0',
        marginBottom: '0'
    }
}));

function Transfer(props: {updateHistory: () => Promise<void>}) {

    const [state, setState] = useState<{sender: null | Array<Person>, receiver: null | Person, amount: number, split: boolean, comment: string}>({
        sender: null,
        receiver: null,
        amount: NaN,
        split: false,
        comment: ""
    });

    const [resetKey1, setResetKey1] = useState<number>(0)
    const [resetKey2, setResetKey2] = useState<number>(2)
    const [resetKey3, setResetKey3] = useState<number>(4)

    const classes = useStyles();

    const { load, raiseSuccess } = useContext(GlobalContext);

    const theme = useTheme();
    const showAsRow = useMediaQuery(theme.breakpoints.up("md"));

    const stateComplete = (): boolean =>
        state.sender !== null && state.receiver !== null && !isNaN(state.amount)

    const transfer = async () => {
        if(stateComplete()) {
            load(async () => {
                if(state.sender !== null && state.receiver !== null) {
                    await axios({
                        method: 'post',
                        url: `${config.api}${Transaction.route.toString()}`,
                        data: {
                            transaction: {
                                sender_id: state.sender.map(p => p.attributes.party.attributes.id),
                                receiver_id: state.receiver.attributes.party.attributes.id,
                                amount: state.amount,
                                split: state.split,
                                comment: state.comment,
                                payment_type: 'Account'
                            }
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    })

                    raiseSuccess(`Transaction completed`)

                    setResetKey1(resetKey1 + 1)
                    setResetKey2(resetKey2 + 1)

                    setState({
                        sender: null,
                        receiver: null,
                        amount: NaN,
                        split: false,
                        comment: ""
                    })

                    await props.updateHistory();
                }
            }, 'Committing transaction')
        }
    }

    return (
        <form className={classes.main}>
            <FormGroup row={showAsRow} classes={{root: classes.margin}}>
                <DataBaseValueAutoCompleteMultiple key={resetKey1} preloadOptions={true} fullWidth={true} className={classes.autoComplete} label={state.sender === null ? "Select sender" : state.sender.map(s => s.attributes.party.balanceToEuroWithSign()).join(" • ")} Class={Person} onSelect={(val) => setState({...state, sender: val as Array<Person>})}/>
                <IconButton color="secondary" size="medium" >
                    <SwapHorizontalCircleIcon fontSize="large" />
                </IconButton>
                <DataBaseValueAutoComplete key={resetKey2} preloadOptions={true} fullWidth={true} className={classes.autoComplete} label={state.receiver === null ? "Select receiver" : state.receiver.attributes.party.balanceToEuroWithSign()} Class={Person} onSelect={(val) => val !== null && setState({...state, receiver: val as Person})}/>
            </FormGroup>
            <FormGroup>
                <FormGroup row={true} className={classes.vertAlign}>
                    <PriceInput key={resetKey3} value={state.amount} className={classes.margin} label='Amount' onChange={(val) => setState({...state, amount: val})}/>
                    {
                        state.sender !== null && state.sender.length > 1
                            ? <FormControlLabel className={classes.marginBotLeft} control={<Checkbox color="primary" onChange={(event) => setState({...state, split: Boolean(event.target.checked)})}/>} label='Split money between senders' />
                            : null
                    }
                    {
                        !isNaN(state.amount) && state.receiver !== null && state.sender !== null && state.sender.length > 1
                            ? (
                                <div className={classes.alignRow}>
                                    <InfoIcon color='action'/>
                                    <p className={classes.alignLeft}>
                                        Each sender will transfer €{(state.split ? (state.amount / state.sender.length) / 100 : (state.amount / 100)).toFixed(2)} to {state.receiver.attributes.party.attributes.name}
                                    </p>
                                </div>
                            )
                            : null
                    }
                </FormGroup>
                <TextField
                    className={classes.margin}
                    label="Comment"
                    placeholder="Aan mijn liefste debugger..."
                    value={state.comment}
                    multiline
                    variant="outlined"
                    rows={4}
                    onChange={(event) => setState({...state, comment: event.target.value})}
                />
                <div>
                    <Button color="secondary" variant="contained" onClick={transfer} disabled={!stateComplete()}>
                        Transfer
                    </Button>
                </div>
            </FormGroup>
        </form>
    );
}

export default Transfer