import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";
import Person from "../../classes/DataBaseValue/Person";
import React from "react";

/** Persons Component
 * 
 * Database value overview for persons
 */
export default function Persons(props: any) {
    return <DataBaseValueOverview Class={Person} />
}