import Product, {PartialRawProductAttributes, ProductAttributes, RawProductAttributes} from "./Product";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import {ProductKind} from "../types";
import Category from "./Category";
import ProductRightsAssociations from "./ProductRightsAssociations";
import ProductGroupsAssociations from "./ProductGroupsAssociations";
import ScanTags from "./ScanTags";
import ConsumableForm from "../../components/DataBaseValueOverview/FormBody/ConsumableForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import DataBaseValue, {LoadHook, RawDataBaseValueAttributes} from "./DataBaseValue";

class Consumable extends Product {

    public attributes: ConsumableAttributes

    public static kind: ProductKind = "consumable";
    public static asString = "consumable";
    public static paginated = true;
    public static route = new Route(['products']).addPredicate(new QueryPredicate("of_kind", Consumable.kind));

    public static attributeNames: Array<string> = ["Name", "Groups", "Rights", "Scan Tags"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: ArrayContent, content: this.attributes.product_groups_associations.product_groups_associations.map(pga => pga.attributes.group.attributes.name)},
            {Component: ArrayContent, content: this.attributes.product_rights_associations.product_rights_associations.map(pra => pra.attributes.right.attributes.name)},
            {Component: ArrayContent, content: this.attributes.scan_tags.scan_tags.map(st => st.attributes.tag)},
        ]
    }

    public static async getVendingProducts(loadHook?: LoadHook): Promise<Array<Consumable>> {
        return (await this.get<RawConsumableAttributes, Consumable>(false, this.route.addRoute('vending'), loadHook)).records
    }

    public static async ColaZero(): Promise<Consumable> {
        return (await this.get<RawConsumableAttributes, Consumable>(0, this.route.addPredicate(new QueryPredicate('with_name', 'Coca Cola Zero Blik')))).records[0]
    }

    /*public static POSTBody = new HTTPPostBody(Consumable,{
        product: new HTTPFormStructure({
            name: new TextField(),
            photo: new FileUpload("", {required: false}),
            category: new CategorySelection(Consumable.kind),
            product_rights_associations_attributes: new ProductRightsAssociationsForm(true, false),
            product_groups_associations_attributes: new ProductGroupsAssociationsForm()
        })
    })
    public PUTBody: HTTPPutBody;*/

    public toString() {
        return this.attributes.name
    }

    public static makeStub(): Consumable {
        return new Consumable({
            consumable: {id: ""},
            category: Category.makeStub().toRaw(),
            created_at: null,
            updated_at: null,
            discarded_at: null,
            id: "",
            kind: "consumable",
            name: "",
            photo: "",
            rental_period: 21,
            extension_period: 14,
            product_groups_associations: [],
            product_rights_associations: [],
            scan_tags: []

        })
    }

    public toRaw(): RawConsumableAttributes {
        return {
            consumable: this.attributes.consumable,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawConsumableAttributes): Consumable {
        return new Consumable({...this.toRaw(), ...toMerge} as RawConsumableAttributes);
    }

    public static form = ConsumableForm

    public serializeToJson(): { [p: string]: any } {
        return {
            product: {
                ...super.serializeToJson(),
                /*consumable_attributes: {
                    id: this.attributes.id ? this.attributes.id : undefined
                }*/
            }
        }
    }

    constructor(params: RawConsumableAttributes) {
        super(params)
        this.attributes = {
            ...params,
            category: new Category(params.category),
            product_rights_associations: new ProductRightsAssociations(params.product_rights_associations),
            product_groups_associations: new ProductGroupsAssociations(params.product_groups_associations),
            scan_tags: new ScanTags(params.scan_tags)
        }
        /*this.PUTBody = new HTTPPutBody(this,{
            product: new HTTPFormStructure({
                name: new TextField(this.name),
                photo: new FileUpload("", {required: false}),
                category: new CategorySelection(Consumable.kind, this.category.id),
                product_rights_associations_attributes: new ProductRightsAssociationsForm(true, false, Object.fromEntries(this.product_rights_associations.map(pra => [pra.id, new ProductRightsAssociationsNameDisplay(pra)]))),
                product_groups_associations_attributes: new ProductGroupsAssociationsForm(Object.fromEntries(this.product_groups_associations.map(pga => [pga.id, new ProductGroupsAssociationForm(pga.group, pga.price)])))
            })
        })*/
    }
}

export interface ConsumableAttributes extends ProductAttributes {
    consumable: {
        id: string
    }
}

export interface RawConsumableAttributes extends RawProductAttributes {
    consumable: {
        id: string
    }
}

export interface PartialRawConsumableAttributes extends PartialRawProductAttributes {
    consumable?: {
        id?: string
    }
}

export default Consumable;