import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";
import Category from "../../classes/DataBaseValue/Category";
import React from "react";

/** Categories Component
 * 
 * Database value overview for categories
 */
export default function Categories(props: any) {
    return <DataBaseValueOverview Class={Category} />
}