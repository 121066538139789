import React, {useContext, useEffect, useState} from "react";
import Rent from "../../classes/DataBaseValue/Rent";
import Inventory from "../../classes/DataBaseValue/Inventory";
import Product from "../../classes/DataBaseValue/Product";
import Book from "../../classes/DataBaseValue/Book";
import InventoryItem from "../../classes/DataBaseValue/InventoryItem";
import {PaymentType} from "../../classes/DataBaseValue/Transaction";
import Person from "../../classes/DataBaseValue/Person";
import {HashRouter, Route} from "react-router-dom";
import {Tab, Tabs} from "@mui/material";
import Shopping from "../../components/Shopping/Shopping";
import Hardware from "../../classes/DataBaseValue/Hardware";
import Party from "../../classes/DataBaseValue/Party";
import GlobalContext from "../../GlobalContext";

/**RentProduct Component
 * 
 * Similar in spirit to ../sales/Sales.tsx, except this time for rents
 * 
 * @param props.className - CSS class names
 * @param props.Class - The class, either Book or Hardware, that is being rented
  */
export default function RentProducts(props: {className: any, Class: new (params: any) => Book | Hardware}) {

    /* The rent object that will be filled in */
    const [rent, setRent] = useState<Rent>(Rent.makeStub())
    const [inventory, setInventory] = useState<Inventory>(Inventory.makeStub())

    const { raiseSuccess, load } = useContext(GlobalContext)

    /* This useEffect is triggered on component startup */
    useEffect(() => {
        if(inventory.isStub()) {
            (async function() {
                load(async (loadHook) => {
                    setInventory(await Inventory.Kassa(loadHook))
                }, 'Getting kassa')
            })();
        }
    }, [])

    /* Only a single item can be added to the rent at once; either the product is amount > 0 or a stub object */
    const changeItemInRent = (item: Product, amount: number) => {
        const newRent = rent.merge({
            product: amount > 0 ? item.toRaw() : (props.Class as any).makeStub().toRaw()
        })
        setRent(newRent)
    }

    /* Similar to how it worked in Sales */
    const changeItemInInventory = (item: Product, amount: number) => {
        // All the inventory items (so, what is in stock)
        const inventoryItems = inventory.attributes.inventory_items
        // A check if the inventory item exists (so, at least one of it is in stock)
        const inventoryItemExists = inventoryItems.find(ii => ii.attributes.product_id === item.attributes.id)
        // Depending on its existence...
        const inventoryItem = inventoryItemExists
            // Add the provided amount to it
            ? inventoryItemExists.merge({amount: inventoryItemExists.attributes.amount + amount})
            // Create a new inventory item with the provided product as product and provided amount as amount
            : InventoryItem.makeStub().merge({product_id: item.attributes.id, amount: amount})
        // Get all inventory items except the item that was provided as argument
        const inventoryItemsWithoutItem = inventoryItems.filter(ii => ii.attributes.product_id !== item.attributes.id)
        // Add to inventoryItemsWithoutItem the inventoryItem of the item that was provided as argument
        const newInventoryItems = [...inventoryItemsWithoutItem, inventoryItem]
            // Filter out all inventory items with an amount <= 0
            .filter(ii => ii.attributes.amount > 0)
            .map(ii => ii.toRaw())
        // These inventory items will be used for the updated inventory
        const newInventory = inventory.merge({
            inventory_items: newInventoryItems
        })
        // Update the inventory
        setInventory(newInventory)
    }

    const changeItemInBasket = (item: Product, amount: number) => {

        const inventoryItem: InventoryItem | undefined = inventory.inventoryItemOf(item)

        if(amount === 1) {
            // Only add if the rent object has not product set yet, also inventoryItem must exist and have at least one instance available for rent
            if(rent.attributes.product.isStub() && inventoryItem !== undefined && inventoryItem.attributes.amount > 0) {
                changeItemInRent(item, amount)
                changeItemInInventory(item, amount * -1)
            }
        } else if(amount === -1) {
            // Only remove the item if an item was already present in the rent
            if(!rent.attributes.product.isStub()) {
                changeItemInRent(item, amount)
                changeItemInInventory(item, amount * -1)
            }
        }
    }

    /* When pay button is pressed */
    const onPay = async (paymentType: PaymentType) => {
        await rent.post()
        // TODO show a nice printout? Like, a modal that pops up describing what exactly happened (showing the warranty and such)
        raiseSuccess('Rent succeeded')
        setRent(Rent.makeStub())
    }

    /* When a customer is selected, add it to the rent */
    const onCustomerSelect = (val: Party | null) => {
        const newRent = rent.merge({
            party: val !== null ? val.toRaw() : Party.makeStub().toRaw()
        })
        setRent(newRent)
    }

    /* When the cancel button is pressed */
    const onCancel = async () => {
        await load(async () => {
            setRent(Rent.makeStub())
            setInventory(await Inventory.Kassa())
        })
    }

    return (
        <div className={props.className}>
            <Shopping
                ProductClass={props.Class}
                basket={rent}
                inventory={inventory}
                changeItemInBasket={changeItemInBasket}
                onPay={onPay}
                onCustomerSelect={onCustomerSelect}
                onCancel={onCancel}
            />
        </div>
    )
}