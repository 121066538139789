import FormBody, {FormProps} from "./FormBody";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";
import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {ProductKind} from "../../../classes/types";

function ProductKindSelection(props: FormProps<StringValue>) {
    return (
        <FormControl variant="outlined">
            <InputLabel id="product-kind-selection-label">{props.name}</InputLabel>
            <Select labelId="product-kind-selection-label" value={props.instance.value} onChange={(e) => props.hook(new StringValue(e.target.value as ProductKind))}>
                {["consumable", "book", "hardware"].map((kind, index) => <MenuItem key={index} value={kind}>{kind.capitalizeFirst()}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default ProductKindSelection