import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { Button, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {FileValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";

const useStyles = makeStyles((theme: Theme) => createStyles({
    fileUpload: {
        margin: '8px',
    }
}))

function FileUpload(props: { instance: StringValue, hook: (newInstance: FileValue) => void, name: string }) {

    const classes = useStyles();

    return (
        <Button component="label" variant="contained" color="primary" className={classes.fileUpload} startIcon={<PublishIcon/>}>
            {props.name}
            <input type="file" hidden onChange={(e) => {
                if(e.target.files && e.target.files.length > 0)
                    props.hook(new FileValue(e.target.files[0]))
            }} />
        </Button>
    )
}

export default FileUpload