class QueryPredicate {
    public key: string
    public val: string | null

    constructor(key: string, val: string | null) {
        this.key = key;
        this.val = val ? val : "";
    }


}

export default QueryPredicate