import React from "react";
import Right from "../../classes/DataBaseValue/Right";
import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";

/** Rights Component
 * 
 * Database value overview for rights
 */
export default function Rights(props: any) {
    return <DataBaseValueOverview Class={Right} />
}