import FormBody, {FormBodyProps, FormProps} from "./FormBody";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import MaterialTextField from "@mui/material/TextField";
import React, {useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";

function PriceField(props: FormProps<NumberValue>) {

    const {instance} = props

    const [error, setError] = useState(false);
    const [value, setValue] = useState<string>(instance.value !== undefined && !isNaN(instance.value) ? (instance.value / 100).toString() : '')
    const [focus, setFocus] = useState(false);

    const onChange = (value: string) => {
        const float = parseFloat(value);
        const isNumber = !isNaN(float);
        setValue(value)
        if(isNumber) {
            const cents = Math.round(float * 100)
            props.hook(new NumberValue(cents))
        } else {
            props.hook(new NumberValue(NaN))
            setError(value !== '' && !isNumber)
        }
    };

    return (
        <TextField
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            variant="outlined"
            error={error}
            value={value}
            label={props.name}
            onChange={(event) => onChange(event.target.value)}
            InputProps={focus ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <EuroIcon fontSize="small" color="disabled" />
                    </InputAdornment>
                ),
            } : {}}
        />
    );
}

export default PriceField