import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";
import Consumable from "../../classes/DataBaseValue/Consumable";
import React from "react";

/** Consumables Component
 * 
 * Database value overview for consumables
 */
export default function Consumables(props: any) {
    return <DataBaseValueOverview Class={Consumable} />
}