import React, {useState} from 'react';
import {InputAdornment, TextField} from "@mui/material";
import EuroIcon from '@mui/icons-material/Euro';

export default function PriceInput(props: {value?: number, label: string, helperText?: string, onChange: (price: number) => void, className?: string}) {

    const [error, setError] = useState(false);
    const [value, setValue] = useState<string>(props.value !== undefined && !isNaN(props.value) ? props.value.toString() : '')
    const [focus, setFocus] = useState(false);

    const onChange = (value: string) => {
        const float = parseFloat(value);
        const isNumber = !isNaN(float);
        setValue(value)
        if(isNumber) {
            const cents = Math.round(float * 100)
            props.onChange(cents)
        } else {
            props.onChange(NaN)
            setError(value !== '' && !isNumber)
        }
    };

    return (
        <TextField
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className={props.className ? props.className : ''}
            variant="outlined"
            error={error}
            value={value}
            label={props.label}
            helperText={props.helperText ? props.helperText : false}
            onChange={(event) => onChange(event.target.value)}
            InputProps={focus ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <EuroIcon fontSize="small" color="disabled" />
                    </InputAdornment>
                ),
            } : {}}
        />
    );
}