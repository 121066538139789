import FormBody, {FormBodyProps, FormProps} from "./FormBody";
import React from "react";
import MaterialTextField from "@mui/material/TextField";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";

/**Strict Textfield Component
 * 
 * Strictly allow only text, no numbers are allowed
 */
function StrictTextField(props: FormProps<StringValue>) {
    return (
        <MaterialTextField
            error={!isNaN(parseInt(props.instance.value))}
            required={false}
            label={props.name}
            variant="outlined"
            value={isNaN(parseInt(props.instance.value)) ? props.instance.value : ''}
            onChange={(e) => isNaN(parseInt(e.target.value)) && props.hook(new StringValue(e.target.value))}
        />
    )
}

export default StrictTextField