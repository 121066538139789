import React, { useContext } from 'react';
import Toolbar from "./components/Toolbar/Toolbar";
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navigator from "./components/Navigator/Navigator";
import { HashRouter, Route } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import MessageProvider from "./components/Message/MessageProvider";
import Sales from "./routes/sales/Sales";
import { createStyles, Theme, PaletteMode } from "@mui/material";
import Transactions from "./routes/transactions/Transactions";
import VendingMachines from './routes/vending/VendingMachines';
import Persons from "./routes/db/Persons";
import Consumables from "./routes/db/Consumables";
import Books from './routes/db/Books';
import Hardware from './routes/db/Hardware';
import Groups from './routes/db/Groups';
import Rights from './routes/db/Rights';
import Categories from "./routes/db/Categories";
import Companies from "./routes/db/Companies";
import Rents from "./routes/rents/Rents";
import Inventory from "./routes/inventory/Inventory";
import Home from "./routes/Home/Home";
import Ribbon from "./components/Ribbon/Ribbon";
import Printer from './routes/printer/Printer';
import GlobalContext, { GlobalContextProvider } from './GlobalContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    main: {
        height: `calc(100% - 80px - 5%)`,
        overflow: 'hidden',
    },
    alpha: {
        width: '200px',
        background: '#ab0c26',
        border: '5px solid #DAA520',
        fontSize: '1rem',
        position: 'absolute',
        top: `20px`,
        right: '-70px',
        left: 'auto',
        textAlign: 'center',
        lineHeight: '30px',
        letterSpacing: '1px',
        color: '#f0f0f0',
        transform: 'rotate(45deg)',
        '-webkit-transform': 'rotate(45deg)',
        overflow: 'hidden',
        zIndex: theme.zIndex.snackbar,
        boxShadow: '0 0 3px rgba(0, 0, 0, .3)'
    },
    '@global': {
        '@font-face': {
            fontFamily: 'LogoFont',
            src: 'url(/fonts/Gotham-Bold.otf)'
        }
    }
}));

/** App component
 * 
 * This is the root component of our application. The theme is defined here as well as the routing.
*/
export default function App() {
    return (
        <GlobalContextProvider>
                <ThemeComponent />
        </GlobalContextProvider>
    )
}

// useContext can't be called before <GlobalContextProvider>
// useStyles can't be called before <ThemeProvider>
function ThemeComponent() {
    const { theme, toggleTheme } = useContext(GlobalContext);

    /* We use Material UI */
    const muiTheme = React.useMemo(() => createTheme({
        palette: {
            mode: theme as PaletteMode,
            primary: {
                main: '#084886'
            },
            secondary: {
                main: '#FFC500',
                contrastText: "#FFFFFF"
            }
        }
    }), [theme])

    return (
        <ThemeProvider theme={muiTheme}>
            <Content onToggleTheme={toggleTheme} />
        </ThemeProvider>
    );
}

function Content({ onToggleTheme }) {
    const classes = useStyles();

    return (
            <HashRouter>
                <div className={classes["root"]}>
                    <Toolbar />


                    <div className={classes["main"]} >

                        <Route exact path='/' render={props => <Home            {...props} />} />

                        <Route path='/sales' render={props => <Sales           {...props} />} />
                        <Route path='/rents' render={props => <Rents           {...props} />} />
                        <Route path='/transactions' render={props => <Transactions    {...props} />} />
                        <Route path='/vending' render={props => <VendingMachines {...props} />} />
                        <Route path='/inventory' render={props => <Inventory       {...props} />} />

                        <Route path='/db/persons' render={props => <Persons     {...props} />} />
                        <Route path='/db/companies' render={props => <Companies   {...props} />} />
                        <Route path='/db/consumables' render={props => <Consumables {...props} />} />
                        <Route path='/db/books' render={props => <Books       {...props} />} />
                        <Route path='/db/hardware' render={props => <Hardware    {...props} />} />
                        <Route path='/db/groups' render={props => <Groups      {...props} />} />
                        <Route path='/db/rights' render={props => <Rights      {...props} />} />
                        <Route path='/db/categories' render={props => <Categories  {...props} />} />

                        <Route path='/printer' render={props => <Printer     {...props} />} />
                    </div>

                    <div>
                        <Loading />
                        <MessageProvider />
                    </div>

                    <Navigator />
                </div>
            </HashRouter>
    );
}
