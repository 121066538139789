import FormBody, {FormProps} from "./FormBody";
import Group from "../../../classes/DataBaseValue/Group";
import React from "react";
import DataBaseValueAutoComplete from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";

function GroupSelection(props: FormProps<Group>) {
    return (
        <DataBaseValueAutoComplete
            Class={Group}
            label={props.name}
            fullWidth={false}
            value={props.instance}
            onSelect={(update: Group | null) => update !== null &&  props.hook(update)}
            preloadOptions={true}
        />
    )
}

export default GroupSelection