import React from "react";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import makeStyles from '@mui/styles/makeStyles';
import { DialogContentText } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            margin: theme["spacing"](1),
            width: '25ch',
        },
    },
    product_rights_associations: {
        '& .MuiFormControl-root': {
            margin: theme["spacing"](1),
            width: '25ch'
        },

    },
    product_rights_association: {
        display: 'flex',
        flexDirection: 'row'
    }
}));

export interface FormBodyProps {
    children: React.ReactNode
}

export interface FormProps<T> {
    instance: T,
    hook: (newInstance: T) => void,
    name: string
}

function FormBody(props: FormBodyProps) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/*<DialogContentText>Text</DialogContentText>*/}
            {props.children}
        </div>
    )
}

export default FormBody