import { CrudValue } from "./CrudValue";
import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Route from "../Route/Route";
import RightForm from "../../components/DataBaseValueOverview/FormBody/RightForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";

class Right extends CrudValue {

    public attributes: RightAttributes;

    public static route = new Route(['rights']);
    public static paginated = false;
    public static asString = 'right';

    public static attributeNames: Array<string> = ["name"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name}
        ]
    }

    public toString() {
        return this.attributes.name
    }

    constructor(params: RawRightAttributes) {
        super(params);
        this.attributes = params;
    }

    public static makeStub(): Right {
        return new Right({
            name: "",
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawRightAttributes {
        return {
            name: this.attributes.name,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawRightAttributes): Right {
        return new Right({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            right: {
                name: this.attributes.name
            }
        }
    }

    public static async Vendable(): Promise<Right> {
        return await this.getOne(Right.route.addRoute('vendable'))
    }

    public static async NonAlcoholic(): Promise<Right> {
        return await this.getOne(Right.route.addRoute('non_alcoholic'))
    }

    public static form = RightForm;
}

export interface RightAttributes extends DataBaseValueAttributes {
    name: string
}

export interface RawRightAttributes extends RawDataBaseValueAttributes {
    name: string
}

export interface PartialRawRightAttributes extends PartialRawDataBaseValueAttributes {
    name?: string
}

export default Right