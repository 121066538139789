import React, {useContext, useEffect, useState} from 'react';
import ContentGrid from "./ContentGrid";
import ShoppingDrawer from "./ShoppingDrawer";
import Product, {RawProductAttributes} from "../../classes/DataBaseValue/Product";
import Category from "../../classes/DataBaseValue/Category";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import Transaction, {PaymentType} from "../../classes/DataBaseValue/Transaction";
import Sale from '../../classes/DataBaseValue/Sale';
import Rent from '../../classes/DataBaseValue/Rent';
import InventoryTransferItem from '../../classes/DataBaseValue/InventoryTransferItem';
import Person from "../../classes/DataBaseValue/Person";
import Company from "../../classes/DataBaseValue/Company";
import Inventory from "../../classes/DataBaseValue/Inventory";
import InventoryItem from "../../classes/DataBaseValue/InventoryItem";
import Book from "../../classes/DataBaseValue/Book";
import useScan from '../../Scanner/useScan';
import {DateTime} from "luxon";
import Party from '../../classes/DataBaseValue/Party';
import GlobalContext from '../../GlobalContext';


export enum Purpose {Sales, Rents}

export type BasketType = Sale | Rent;

export default function Shopping<C extends RawProductAttributes, V extends Product>(props: {
    basket: BasketType,
    inventory: Inventory,
    changeItemInBasket: (item: Product, amount: number) => void,
    onPay: (paymentType: PaymentType) => Promise<void>,
    onCustomerSelect: (val: Party | null) => void,
    onCancel: () => void,
    ProductClass: any,
    className?: any
}) {

    const [customer, setCustomer] = useState<Party>(Party.makeStub());
    const [customerPerson, setCustomerPerson] = useState<Person>(Person.makeStub())
    const [resetKey, setResetKey] = useState<number>(0);

    const [categories, setCategories] = useState<Array<Category>>([]);
    const [category, setCategory] = useState<Category | null>(null);

    const [products, setProducts] = useState<Array<Product>>([]);

    const [page, setPage] = useState<{current: number, total: number}>({current: 0, total: 0});

    const [searchText, setSearchText] = useState<string>("")

    const { raiseError, load } = useContext(GlobalContext)

    const [loadingContentGrid, setLoadingContentGrid] = useState<number>(0)

    const route = props.ProductClass.route
        .addPredicate(new QueryPredicate("with_popularity_between[lower]", DateTime.now().minus({months: 1}).toISODate()))
        .addPredicate(new QueryPredicate("with_popularity_between[upper]", DateTime.now().plus({days: 1}).toISODate()))

    useEffect(() => {
        (async function() {

            const changeCustomer = async () => {
                const externalMoney = (await Company.External()).attributes.party
                setCustomer(externalMoney)
            }

            switch(props.basket.constructor) {
                case Sale: {
                    if((props.basket as Sale).attributes.transaction.attributes.sender.isStub()) {
                        await changeCustomer()
                    }
                    break;
                }
                case Rent: {
                    if((props.basket as Rent).attributes.party.isStub()) {
                        await changeCustomer()
                    }
                    break;
                }
            }
        })()
    }, [props.basket])

    useEffect(() => {
        props.onCustomerSelect(customer)
    }, [customer])

    useScan(async (type, value) => {
        try {
            switch(type) {
                case 'nfc':
                    onCustomerSelect((await Person.getByScan(value)));
                    break;
                case 'barcode':
                    const product = await Product.getByScan(value);
                    switch(props.basket.constructor) {
                        case Sale: product.attributes.kind === 'consumable' && props.changeItemInBasket(product, 1); break;
                        case Rent: product.attributes.kind !== 'consumable' && props.changeItemInBasket(product, 1); break;
                    }
                    break;
            }
        } catch(e) {
            raiseError(e)
        }
    }, [props.inventory, props.basket])

    /*Fetch the kind of category for which we will be fetching the products for.*/
    useEffect(() => {
        if(categories.length === 0)
            (async function() {
                setLoadingContentGrid(loadingContentGrid => loadingContentGrid + 1)
                const categories = (await Category.getByKind(props.ProductClass.kind)).records.sort((a, b) => {
                        //Hardcoding a more natural ordering. Is ignored for Rents since indexOf will result in -1
                        const order = ["nonalcoholic", "snack", "alcoholic", "food"];
                        const aIndex = order.indexOf(a.attributes.name.toLowerCase())
                        const bIndex = order.indexOf(b.attributes.name.toLowerCase())
                        return aIndex - bIndex
                    })
                setCategories(categories)
                setLoadingContentGrid(loadingContentGrid => loadingContentGrid - 1)
            })();
    }, []);

    /*Once category has been fetched, set the category correctly on the UI*/
    useEffect(() => {
        if(categories.length > 0 && category === null)
            (async function() {
                await changeCategory(categories[0])
            })();
        return () => {}
    }, [categories])

    useEffect(() => {
        (async function () {
            setLoadingContentGrid(loadingContentGrid => loadingContentGrid + 1)
            setPage({current: 1, total: (await props.ProductClass.getTotalsFromSearch(searchText)).pages})
            setLoadingContentGrid(loadingContentGrid => loadingContentGrid - 1)
        })()
    }, [searchText])

    /*Get the products for a category whenever page changes, but also during initial request*/
    useEffect(() => {
        (async function() {
            setLoadingContentGrid(loadingContentGrid => loadingContentGrid + 1)
            if(searchText === "" && category !== null) {
                setProducts((await props.ProductClass.get(page.current, route.addPredicate(new QueryPredicate("from_category", category.attributes.id)))).records)
            }
            else
                setProducts((await props.ProductClass.search(searchText, page.current)).records)
            setLoadingContentGrid(loadingContentGrid => loadingContentGrid - 1)
        })();
    }, [page])

    const changeCategory = async (category: Category) => {
        setLoadingContentGrid(loadingContentGrid => loadingContentGrid + 1)
        setSearchText("")
        setCategory(category);
        setPage({current: 1, total: (await props.ProductClass.getTotalsByCategory(category)).pages});
        setProducts((await props.ProductClass.get(1, route.addPredicate(new QueryPredicate("from_category", category.attributes.id)))).records);
        setLoadingContentGrid(loadingContentGrid => loadingContentGrid - 1)
    };

    const changePage = async (newPage: number) => {
        setLoadingContentGrid(loadingContentGrid => loadingContentGrid + 1)
        if(category) {
            setPage({...page, current: newPage});

            if(searchText === "")
                setProducts((await props.ProductClass.get(newPage, route.addPredicate(new QueryPredicate("from_category", category.attributes.id)))).records)
            else
                setProducts((await props.ProductClass.search(searchText, newPage)).records)
        }
        setLoadingContentGrid(loadingContentGrid => loadingContentGrid - 1)
    };

    const onPay = async (paymentType: PaymentType) => {
        load(async () => {
            await props.onPay(paymentType);
            setCustomer(Party.makeStub());
            setCustomerPerson(Person.makeStub())
            setResetKey(resetKey+1);
        }, 'Committing transaction')
    }

    const onCustomerSelect = (val: Person | null) => {
        setCustomerPerson(val ?? Person.makeStub())
        setCustomer(val?.attributes.party ?? Party.makeStub());
    }

    const onCancel = () => {
        setCustomer(Party.makeStub())
        setCustomerPerson(Person.makeStub())
        props.onCancel()
        setResetKey(resetKey+1);
    }

    const search = (searchText: string) => setSearchText(searchText)

    return (
        <div style={{height: '100%'}} className={props.className}>
            <ShoppingDrawer
                categories={categories}
                changeCategory={changeCategory}
                add={(item) => props.changeItemInBasket(item, 1)}
                remove={(item) => props.changeItemInBasket(item, -1)}
                basket={props.basket}
                onPay={onPay}
                onCustomerSelect={onCustomerSelect}
                onCancel={onCancel}
                customer={customer}
                customerPerson={customerPerson}
                resetKey={resetKey}
            />
            {
                category !== null
                    ? <ContentGrid
                        search={search}
                        categories={categories}
                        category={category}
                        products={products}
                        inventory={props.inventory}
                        page={page.current}
                        totalPages={page.total}
                        changePage={changePage}
                        changeCategory={changeCategory}
                        add={(item) => props.changeItemInBasket(item, 1)}
                        customer={customer}
                        loading={loadingContentGrid !== 0}
                    />
                    : null
            }
        </div>
    );
}