import FormBody, {FormProps} from "./FormBody";
import Book from "../../../classes/DataBaseValue/Book";
import React from "react";
import TextField from "./TextField";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import FileUpload from "./FileUpload";
import NumericalField from "./NumericalField";
import ProductRightsAssociationsForm from "./ProductRightsAssociationsForm";
import ScanTagsForm from "./ScanTagsForm";
import CategorySelection from "./CategorySelection";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import ProductGroupsAssociationsForm from "./ProductGroupsAssociationsForm";

function BookForm(props: FormProps<Book>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    const propagateBookAttributes = (key: string, value: AbstractValue) => props.hook(props.instance.merge({
        book: {
            ...props.instance.attributes.book,
            [key]: value.toRaw()
        }
    }))

    return (
        <FormBody>
            <TextField instance={new StringValue(attributes.name)} hook={(update) => propagate("name", update)} name="Name" />
            <FileUpload instance={new StringValue(attributes.photo)} hook={(update) => propagate("photo", update)} name="Photo" />
            <TextField instance={new StringValue(attributes.book.author)} hook={(update) => propagateBookAttributes("author", update)} name="Author" />
            <NumericalField instance={new NumberValue(attributes.book.year)} hook={(update) => propagateBookAttributes("year", update)} name="Year" />
            <NumericalField instance={new NumberValue(attributes.rental_period)} hook={(update) => propagate("rental_period", update)} name="Rental period (days)" />
            <NumericalField instance={new NumberValue(attributes.extension_period)} hook={(update) => propagate("extension_period", update)} name="Extension period (days)" />
            <CategorySelection instance={attributes.category} kind='book' hook={(update) => propagate("category", update)} name="Category" />
            <ProductRightsAssociationsForm product={props.instance} instance={attributes.product_rights_associations} hook={(update) => propagate("product_rights_associations", update)} name="Rights" />
            <ScanTagsForm instance={attributes.scan_tags} hook={(update) => propagate("scan_tags", update)} name="Scan Tags" />
        </FormBody>
    )
}

export default BookForm