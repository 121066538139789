import {CrudValue} from "./CrudValue";
import {
    DataBaseValueAttributes,
    LoadHook,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Party, {RawPartyAttributes} from "./Party";
import Route from "../Route/Route";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import InventoryItem, {RawInventoryItemAttributes} from "./InventoryItem";
import InventoryTransfer, {RawInventoryTransferAttributes} from "./InventoryTransfer";
import Product from "./Product";

class Inventory extends CrudValue {
    public attributes: InventoryAttributes;

    public static route = new Route(['inventories']);
    public static paginated = true;
    public static asString = 'inventory';

    public static attributeNames: Array<string> = ["Name", "Party", "Items", "Sent", "Received"]

    public inventoryItemOf(product: Product): InventoryItem | undefined {
        return this.attributes.inventory_items.find(ii => ii.attributes.product_id === product.attributes.id)
    }

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: BasicContent, content: this.attributes.party.attributes.name},
            {Component: ArrayContent, content: this.attributes.inventory_items.map(ii => `${ii.attributes.amount}x ${ii.attributes.product_id}`)},
            //{Component: ArrayContent, content: this.attributes.sent_transfers.map(st => `To ${st.attributes.to_id}`)},
            //{Component: ArrayContent, content: this.attributes.received_transfers.map(rt => `From ${rt.attributes.from_id}`)}
        ]
    }

    public toString() {
        return `Inventory of ${this.attributes.party.attributes.name}`
    }

    constructor(params: RawInventoryAttributes) {
        super(params);

        this.attributes = {
            ...params,
            name: params.name,
            party: new Party(params.party),
            inventory_items: params.inventory_items.map(ie => new InventoryItem(ie)),
            //sent_transfers: params.sent_transfers.map(st => new InventoryTransfer(st)),
            //received_transfers: params.received_transfers.map(rt => new InventoryTransfer(rt))
        }
    }

    public static makeStub(): Inventory {
        return new Inventory({
            name: "",
            party: Party.makeStub().toRaw(),
            inventory_items: [],
            //sent_transfers: [],
            //received_transfers: [],
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawInventoryAttributes {
        return {
            name: this.attributes.name,
            party: this.attributes.party.toRaw(),
            inventory_items: this.attributes.inventory_items.map(ie => ie.toRaw()),
            //sent_transfers: this.attributes.sent_transfers.map(st => st.toRaw()),
            //received_transfers: this.attributes.received_transfers.map(rt => rt.toRaw()),
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawInventoryAttributes): Inventory {
        return new Inventory({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            inventory: {
                name: this.attributes.name,
                party_id: this.attributes.party.attributes.id
            }
        }
    }

    public static async Kassa(loadHook?: LoadHook): Promise<Inventory> {
        return await Inventory.getOne(Inventory.route.addRoute('kassa'), loadHook)
    }

    public static async External(): Promise<Inventory> {
        return await Inventory.getOne(Inventory.route.addRoute('external'))
    }

    public static async VendingMachines(): Promise<Inventory> {
        return await Inventory.getOne(Inventory.route.addRoute('vending_machines'))
    }
}

export interface InventoryAttributes extends DataBaseValueAttributes {
    name: string,
    party: Party,
    inventory_items: Array<InventoryItem>,
    //sent_transfers: Array<InventoryTransfer>,
    //received_transfers: Array<InventoryTransfer>,
}

export interface RawInventoryAttributes extends RawDataBaseValueAttributes {
    name: string,
    party: RawPartyAttributes,
    inventory_items: Array<RawInventoryItemAttributes>,
    //sent_transfers: Array<RawInventoryTransferAttributes>,
    //received_transfers: Array<RawInventoryTransferAttributes>
}

export interface PartialRawInventoryAttributes extends PartialRawDataBaseValueAttributes {
    name?: string,
    party?: RawPartyAttributes,
    inventory_items?: Array<RawInventoryItemAttributes>,
    //sent_transfers?: Array<RawInventoryTransferAttributes>,
    //received_transfers?: Array<RawInventoryTransferAttributes>
}

export default Inventory