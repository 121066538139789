import { Paper, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React, {useEffect, useState} from "react";
import Person from "../../classes/DataBaseValue/Person";
import Consumable from "../../classes/DataBaseValue/Consumable";
import makeStyles from '@mui/styles/makeStyles';
import Title from "./Title";
import HomeCard from "./HomeCard";

export default function ColaZeroesBram(props: any) {

    const [colaZeroBram, setColaZeroBram] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async function() {
            setLoading(true)
            const bram = await Person.Bram();
            const colaZero = await Consumable.ColaZero();
            const purchases = await bram.purchasesThisWeekOf(colaZero);
            setColaZeroBram(purchases.reduce((total, iti) => total + iti.attributes.amount, 0))
            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title='Cola Zeroes bought by Bram this week' loading={loading}>
            <Typography component="p" variant='h2'>
                {colaZeroBram}
            </Typography>
        </HomeCard>
    )
}