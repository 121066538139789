import React from 'react';
import Drawer from "../Drawer/Drawer";
import { Breakpoint, useTheme } from '@mui/material/styles';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import Basket from "./Basket";
import {BasketType} from "./Shopping"
import Product from "../../classes/DataBaseValue/Product";
import Actions from "./Actions";
import Category from "../../classes/DataBaseValue/Category";
import {PaymentType} from "../../classes/DataBaseValue/Transaction";
import Sale from '../../classes/DataBaseValue/Sale';
import CustomerSelect from "./CustomerSelect";
import Person from "../../classes/DataBaseValue/Person";
import Party from "../../classes/DataBaseValue/Party";
import useMediaQuery from '@mui/material/useMediaQuery';

function ShoppingDrawer(props: {
    add: (item: Product) => void,
    remove: (item: Product) => void,
    changeCategory: (category: Category) => void,
    basket: BasketType,
    categories: Array<Category>,
    onPay: (paymentType: PaymentType) => Promise<void>,
    onCustomerSelect: (val: Person | null) => void,
    onCancel: () => void,
    customer: Party,
    customerPerson: Person,
    resetKey: any
}) {

    const [state, setState] = React.useState({
        open: false
    });
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));

    return <Drawer
        open={state.open}
        onClose={() => setState({open: false})}
        onOpen={() => setState({open: true})}
        items={{
            'Customer': <CustomerSelect onCustomerSelect={props.onCustomerSelect} customer={props.customer} customerPerson={props.customerPerson} resetKey={props.resetKey} key={0} />,
            'Basket': <Basket customer={props.customer} basket={props.basket} remove={props.remove} key={1} />,
            'Actions': <Actions basket={props.basket} onPay={props.onPay} onCancel={props.onCancel} key={2} />
        }}
        anchor='right'
        variant={isXs ? 'temporary' : 'permanent'}
        over={isXs}
    />
}

export default ShoppingDrawer