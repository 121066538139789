import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Group, {RawGroupAttributes} from "./Group";

class ProductGroupsAssociation extends DataBaseValue {

    public attributes: ProductGroupsAssociationAttributes;

    constructor(params: RawProductGroupsAssociationAttributes) {
        super(params);
        this.attributes = {
            ...params,
            product_id: params.product_id,
            group: new Group(params.group)
        }
    }

    public static makeStub() {
        return new ProductGroupsAssociation({
            group: Group.makeStub().toRaw(),
            product_id: "",
            price: 0,
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawProductGroupsAssociationAttributes {
        return {
            group: this.attributes.group.toRaw(),
            product_id: this.attributes.product_id,
            price: this.attributes.price,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawProductGroupsAssociationAttributes): ProductGroupsAssociation {

        return new ProductGroupsAssociation({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            group_id: this.attributes.group.attributes.id,
            price: this.attributes.price,
            id: this.attributes.id ? this.attributes.id : undefined,
            _destroy: this.attributes.discarded_at !== null
        }
    }
}

export default ProductGroupsAssociation

export interface ProductGroupsAssociationAttributes extends DataBaseValueAttributes {
    group: Group,
    product_id: string,
    price: number
}

export interface RawProductGroupsAssociationAttributes extends RawDataBaseValueAttributes{
    group: RawGroupAttributes,
    product_id: string,
    price: number
}

export interface PartialRawProductGroupsAssociationAttributes extends PartialRawDataBaseValueAttributes{
    group?: RawGroupAttributes,
    product_id?: string,
    price?: number
}