import Content, {PrimitiveContent} from "./Content";
import React from "react";

function ArrayContent(props: {content: Content}) {
    return (
        <ul>
            {(props.content as Array<PrimitiveContent>).map((c, key) => <li key={key}>{c}</li>)}
        </ul>
    )
}

export default ArrayContent