import React, {useState, useContext} from 'react';
import { FormGroup, IconButton, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import PriceInput from "../../components/PriceInput/PriceInput";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Breakpoint, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import DataBaseValueAutoComplete from "../../components/DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import Person from "../../classes/DataBaseValue/Person";
import Transaction from "../../classes/DataBaseValue/Transaction";
import Party from "../../classes/DataBaseValue/Party";
import Company from "../../classes/DataBaseValue/Company";
import GlobalContext from '../../GlobalContext';
import useMediaQuery from '@mui/material/useMediaQuery';

export enum Variant {Send, Receive}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    margin: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: 1
    },
    button: {
        marginBottom: theme.spacing(2)
    },
    label: {
        width: theme.spacing(8),
        height: 'auto',
    }
}));

function AccountTransactions(props: {variant: Variant, updateHistory: () => Promise<void>}) {

    const [state, setState] = useState<Transaction>(
        Transaction.makeStub()
    )

    const { load, raiseSuccess } = useContext(GlobalContext)

    const [resetKey, setResetKey] = useState<number>(0)

    const classes = useStyles();
    const theme = useTheme();
    const showAsRow = useMediaQuery(theme.breakpoints.up("md"));

    const variant2Bool = () : boolean => props.variant === Variant.Send;
    const personIsSenderOrReceiver = variant2Bool() ? 'receiver' : 'sender'
    const kassaIsSenderOrReceiver = variant2Bool() ? 'sender' : 'receiver'
    const receivingOrSending = variant2Bool() ? 'Receiving' : 'Sending'

    const formFilled = () => state.attributes.amount !== 0 && (variant2Bool() ? state.attributes.receiver.attributes.id : state.attributes.sender.attributes.id) !== ""

    const transact = async () => {
        await load(async () => {

            const newState = state.merge({
                [kassaIsSenderOrReceiver]: (await Company.Kassa()).attributes.party.toRaw(),
                comment: `${receivingOrSending} €${state.attributes.amount / 100} ${variant2Bool() ? 'from' : 'to'} the kassa`
            })

            await newState.multiSenderPost([newState.attributes.sender])
            await props.updateHistory();
            setState(Transaction.makeStub())
            setResetKey(resetKey + 1)
            raiseSuccess(`Transferred €${newState.attributes.amount / 100} ${variant2Bool() ? 'to' : 'from'} ${newState.attributes[variant2Bool() ? 'receiver' : 'sender'].attributes.name}`)
        }, 'Committing transaction')
    }

    return (
        <form className={classes.root} >
            <FormGroup row={showAsRow} classes={{root: classes.formGroup}}>
                <PriceInput value={state.attributes.amount} className={classes.margin} label={variant2Bool() ? 'Add' : 'Subtract'} helperText="Select Amount" onChange={(amount) => setState(state.merge({amount}))} />
                <DataBaseValueAutoComplete
                    key={resetKey}
                    fullWidth={true}
                    className={classes.margin}
                    label={state.attributes[personIsSenderOrReceiver].isStub() ? (variant2Bool() ? 'to' : 'from') : state.attributes[personIsSenderOrReceiver].balanceToEuroWithSign()}
                    helperText="Select User"
                    Class={Person}
                    onSelect={(person) => setState(state.merge({[variant2Bool() ? 'receiver' : 'sender']: (person as Person).attributes.party.toRaw()}))}
                />
                <div>
                    <IconButton
                        color="secondary"
                        classes={{root: classes.button}}
                        disabled={!formFilled()}
                        onClick={transact}
                        size="large">
                        <PlayCircleFilledIcon classes={{root: classes.label}} />
                    </IconButton>
                    <p></p>
                </div>
            </FormGroup>
        </form>
    );
}

export default AccountTransactions;