import DataBaseValueCollection from "./DataBaseValueCollection";
import ScanTag, {RawScanTagAttributes} from "./ScanTag";
import {RawProductGroupsAssociationAttributes} from "./ProductGroupsAssociation";

class ScanTags extends DataBaseValueCollection {

    public scan_tags: Array<ScanTag>

    constructor(scan_tags: Array<RawScanTagAttributes>) {
        super();
        this.scan_tags = scan_tags.map(st => new ScanTag(st))
    }

    public static makeStub(): ScanTags {
        return new ScanTags([])
    }

    public toRaw(): Array<RawScanTagAttributes> {
        return this.scan_tags.map(st => st.toRaw())
    }

    public merge(toMerge: Array<RawScanTagAttributes>): ScanTags {
        const old = this.toRaw();

        const newEntries = toMerge
            .filter(m => !old.find(o => !o.discarded_at && o.tag === m.tag))

        const updatedEntries = old
            .filter(o => !o.discarded_at && toMerge.find(m => o.tag === m.tag))

        const discardedEntries = old
            .filter(o => o.discarded_at || !toMerge.find(m => m.tag === o.tag))

        const entries = [
            ...newEntries,
            ...updatedEntries.map(o => {
                const updated = toMerge.find(m => m.tag === o.tag) as RawScanTagAttributes;
                return {...o, tag: updated.tag}
            }),
            ...discardedEntries.map(o => ({...o, discarded_at: new Date()}))
        ]

        console.log(old, newEntries, updatedEntries, discardedEntries, entries)

        return new ScanTags(entries);
    }
}

export default ScanTags