import FormBody, {FormBodyProps, FormProps} from "./FormBody";
import Address from "../../../classes/DataBaseValue/Address";
import React from "react";
import TextField from "./TextField";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import Form from "../../../classes/DataBaseValue/Form";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import StrictTextField from "./StrictTextField";
import NumericalField from "./NumericalField";

function AddressForm(props: FormProps<Address>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    return (
        <FormBody>
            <StrictTextField instance={new StringValue(attributes.street)} hook={(update) => propagate("street", update)} name="Street" />
            <TextField instance={new StringValue(attributes.number)} hook={(update) => propagate("number", update)} name="Number" />
            <NumericalField instance={new NumberValue(parseInt(attributes.postal_code))} hook={(update) => propagate("postal_code", update)} name="Postal Code" />
            <StrictTextField instance={new StringValue(attributes.city)} hook={(update) => propagate("city", update)} name="City" />
        </FormBody>
    )
}

export default AddressForm