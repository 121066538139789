import { useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';

export type Log = {date: string, type: 'info' | 'error' | 'argv' | 'env', text: string}

export default function usePrint(callback: (data: Log) => void, dependencies: React.DependencyList | undefined = []) {
    useEffect(() => {
        createConsumer('http://localhost:3000/cable').subscriptions.create({ channel: "PrinterChannel" }, {
        received(data) {
            callback(data)
        }
    })
    }, [])
}