import DataBaseValueCollection from "./DataBaseValueCollection";
import ProductGroupsAssociation, {RawProductGroupsAssociationAttributes} from "./ProductGroupsAssociation";
import Product from "./Product";

class ProductGroupsAssociations extends DataBaseValueCollection {
    public product_groups_associations: Array<ProductGroupsAssociation>

    constructor(product_groups_associations: Array<RawProductGroupsAssociationAttributes>) {
        super();
        this.product_groups_associations = product_groups_associations.map(pga => new ProductGroupsAssociation(pga))
    }

    public static makeStub(product: Product) {
        return new ProductGroupsAssociations([])
    }

    public toRaw(): Array<RawProductGroupsAssociationAttributes> {
        return this.product_groups_associations.map(pga => pga.toRaw())
    }

    public sort(sortFn: (first: ProductGroupsAssociation, second: ProductGroupsAssociation) => number): Array<ProductGroupsAssociation> {
        return [...this.product_groups_associations].sort(sortFn)
    }

    public merge(toMerge: Array<RawProductGroupsAssociationAttributes>): ProductGroupsAssociations {

        const old = this.toRaw();

        const newEntries = toMerge
            .filter(m => !old.find(o => o.discarded_at === null && o.group.id === m.group.id))

        const updatedEntries = old
            .filter(o => o.discarded_at === null && toMerge.find(m => o.group.id === m.group.id))

        const discardedEntries = old
            .filter(o => o.discarded_at !== null || !toMerge.find(m => m.group.id === o.group.id))

        const entries = [
            ...newEntries,
            ...updatedEntries.map(o => {
                const updated = toMerge.find(m => m.group.id === o.group.id) as RawProductGroupsAssociationAttributes;
                return {...o, price: updated.price, group: updated.group}
            }),
            ...discardedEntries.map(o => ({...o, discarded_at: new Date()}))
        ]

        return new ProductGroupsAssociations(entries);
    }
}

export default ProductGroupsAssociations