import React, { useContext, useEffect } from 'react';
import io from 'socket.io-client';
import GlobalContext from '../GlobalContext';


export type ScanType = 'nfc' | 'barcode'

export default function useScan(callback: (type: ScanType, value: string) => void, dependencies: React.DependencyList | undefined = []) {

    const { raiseWarning, raiseInfo } = useContext(GlobalContext);

    useEffect(() => {

        const socket = io("http://localhost:8080");
        socket.io.reconnectionAttempts(0)

        socket.on("connect_error", () => {
            //raiseWarning('Scanners are unavailable')
            //gActions.raiseWarning('Scanners are unavailable')
        })

        socket.on('connect', () => {
            raiseInfo('Scanners are available')
        })

        socket.on('onCode', (code: {type: ScanType, value: string}) => callback(code.type, code.value))

        return () => {
            socket.disconnect()
        }
    }, dependencies)
}