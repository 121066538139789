import FormBody, {FormProps} from "./FormBody";
import ProductRightsAssociations from "../../../classes/DataBaseValue/ProductRightsAssociations";
import React from "react";
import Right from "../../../classes/DataBaseValue/Right";
import {DataBaseValueAutoCompleteMultiple} from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import ProductRightsAssociation from "../../../classes/DataBaseValue/ProductRightsAssociation";
import Product from "../../../classes/DataBaseValue/Product";

export interface ProductRightsAssociationsFormProps extends FormProps<ProductRightsAssociations> {
    product: Product
}

function ProductRightsAssociationsForm(props: ProductRightsAssociationsFormProps) {

    const {product_rights_associations} = props.instance

    return (
        <FormBody>
            <DataBaseValueAutoCompleteMultiple
                Class={Right}
                label="Select rights"
                fullWidth={false}
                value={product_rights_associations.filter(pra => pra.attributes.discarded_at === null).map(pra => pra.attributes.right)}
                onSelect={(update: Array<Right>) =>
                    props.hook(props.instance.merge(update.map(u => ProductRightsAssociation.makeStub().merge({
                        right: u.toRaw(),
                        sellable: props.product.attributes.kind === "consumable",
                        rentable: props.product.attributes.kind !== "consumable",
                    }).toRaw())))}
                preloadOptions={true}
            />
        </FormBody>
    )
}

export default ProductRightsAssociationsForm