import FormBody, {FormProps} from "./FormBody";
import Company from "../../../classes/DataBaseValue/Company";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";
import TextField from "./TextField";
import React from "react";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import GroupsSelection from "./GroupsSelection";

function CompanyForm(props: FormProps<Company>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    const propagatePartyAttributes = (key: string, value: AbstractValue) =>
        props.hook(props.instance.merge({
            party: {
                ...props.instance.attributes.party.toRaw(),
                [key]: value.toRaw()
            }
        }))

    return (
        <FormBody>
            <TextField instance={new StringValue(attributes.name)} hook={(update) => propagate("name", update)} name="Name" />
            <TextField instance={new StringValue(attributes.phone)} hook={(update) => propagate("phone", update)} name="Phone" />
            <TextField instance={new StringValue(attributes.bill_number)} hook={(update) => propagate("bill_number", update)} name="Bill Number" />
            <GroupsSelection instance={attributes.party.attributes.party_groups_associations} hook={(update) => propagatePartyAttributes("party_groups_associations", update)} name="Groups" />
        </FormBody>
    )
}

export default CompanyForm