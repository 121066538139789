import FormBody, {FormProps} from "./FormBody";
import ProductGroupsAssociations from "../../../classes/DataBaseValue/ProductGroupsAssociations";
import React, {useState} from "react";
import ProductGroupsAssociationForm from "./ProductGroupsAssociationForm";
import Group from "../../../classes/DataBaseValue/Group";
import { IconButton, Paper } from "@mui/material";
import DataBaseValueAutoComplete from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import AddIcon from "@mui/icons-material/Add";
import ProductGroupsAssociation from "../../../classes/DataBaseValue/ProductGroupsAssociation";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "./TextField";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import Product from "../../../classes/DataBaseValue/Product";
import DeleteIcon from "@mui/icons-material/Delete";
import PriceField from "./PriceField";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        width: '100%',
        minWidth: '100%'
    }
}));

function ProductGroupsAssociationsForm(props: FormProps<ProductGroupsAssociations>) {

    const {product_groups_associations} = props.instance

    const classes = useStyles();

    return (
        <FormBody>
            {product_groups_associations
                .filter(pra => pra.attributes.discarded_at === null)
                .map((pra, index) => (
                    <ProductGroupsAssociationForm key={index} instance={pra} hook={(update) => props.hook(props.instance.merge(product_groups_associations.map((pga, i) => index === i ? update.toRaw() : pga.toRaw())))} name={pra.attributes.group.attributes.name}>
                        <IconButton
                            onClick={() => props.hook(props.instance.merge(Object.assign([...product_groups_associations.slice(0, index), ...product_groups_associations.slice(index + 1)].map(pga => pga.toRaw()))))}
                            size="large">
                            <DeleteIcon color="error"/>
                        </IconButton>
                    </ProductGroupsAssociationForm>
                ))}
            <AddProductGroupsAssociation hook={(update) => props.hook(props.instance.merge([...props.instance.toRaw(), update.toRaw()]))} />
        </FormBody>
    );
}

export default ProductGroupsAssociationsForm

function AddProductGroupsAssociation(props: {hook: (update: ProductGroupsAssociation) => void}) {

    const classes = useStyles();

    const [state, setState] = useState<{group: Group | null, price: number | null}>({
        group: null,
        price: null
    })

    const onAdd = () => {
        if(state.group !== null && state.price !== null) {
            console.log(ProductGroupsAssociation.makeStub().merge({group: state.group.toRaw(), price: state.price}))
            props.hook(ProductGroupsAssociation.makeStub().merge({group: state.group.toRaw(), price: state.price}))
            setState({group: null, price: null})
        }
    }

    return (
        <Paper className={classes.root}>
            <DataBaseValueAutoComplete
                Class={Group}
                label="Select group"
                fullWidth={false}
                onSelect={(group) => group !== null && setState({...state, group: (group as Group)})}
                preloadOptions={true}
            />
            <PriceField
                instance={new NumberValue(state.price !== null ? state.price : NaN)}
                hook={(update) => setState({...state, price: update.value})}
                name="Price"
            />
            <IconButton
                color="secondary"
                disabled={state.group === null}
                onClick={() => onAdd()}
                size="large">
                <AddIcon />
            </IconButton>
        </Paper>
    );
}