import React, {useEffect, useState} from 'react';
import {
    CircularProgress, Container,
    createStyles,
    ImageList,
    ImageListItem, ImageListItemBar,
    Paper,
    Theme, Typography,
} from "@mui/material";

import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles"
import {Pagination} from "@mui/lab";
import Product from "../../classes/DataBaseValue/Product";
import Category from "../../classes/DataBaseValue/Category";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Party from "../../classes/DataBaseValue/Party";
import config from "../../config";
import Inventory from "../../classes/DataBaseValue/Inventory";
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from '../SearchBar';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        /*display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'*/
    },
    vAlign: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    },
    pagination: {
        margin: '1rem auto'
    },
    empty: {
        width: '100%',
        height: '100%',
        backgroundColor: '#cdcdcd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    emptyIcon: {
        width: '50%',
        height: '50%'
    },
    pointer: {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
    },
    tabs: {
        marginBottom: theme.spacing(1)
    },
    greyedOut: {
        filter: 'grayScale(70%)'
    },
    circularProgress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {
        objectFit: 'contain'
    },
}));

function ContentGrid(props: {
    category: Category,
    categories: Array<Category>,
    products: Array<Product>,
    inventory: Inventory,
    page: number,
    totalPages: number,
    changePage: (page: number) => void,
    changeCategory: (category: Category) => Promise<void>,
    add: (item: Product) => void,
    customer: Party,
    search: (searchText: string) => void,
    loading: boolean
}) {
    const classes = useStyles();

    const [searchText, setSearchText] = useState<string>("")


    const theme = useTheme();
    const setColAmount = useMediaQuery(theme.breakpoints.up("lg"))  ? 4 : 2;
    const isXs = useMediaQuery(theme.breakpoints.only("xs"))

    const currentTab = searchText === "" ? props.categories.findIndex(c => c.attributes.id === props.category.attributes.id) : -1



    return (
        <Paper className={classes["root"]} style={{width: isXs ? '100%' : `calc(100% - 250px)`}}>
            <div className={classes["vAlign"]} >
                <Tabs value={currentTab} indicatorColor="primary" textColor="primary" className={classes["tabs"]} variant='scrollable' scrollButtons='auto' >
                    {props.categories.map(category => (
                        <Tab key={category.attributes.id} label={category.attributes.name} onClick={async () => await props.changeCategory(category)} />
                    ))}
                </Tabs>
                
                <SearchBar
                    value={searchText}
                    onChange={(newValue) => {
                        setSearchText(newValue)
                        newValue === "" && props.search("")
                    }}
                    onRequestSearch={() => props.search(searchText)}
                />

                {props.loading
                    ? (
                        <div className={classes["circularProgress"]}>
                            <CircularProgress />
                            <Typography variant="h5" component="h1">
                                Loading products
                            </Typography>
                            <Typography variant="subtitle1" component="p" color={theme => 'gray'}>
                                Selecting a customer and using the barcode scanner is already available, you don't have to wait for this.
                            </Typography>
                        </div>)
                    : (
                        <ImageList cols={setColAmount} className={classes["gridList"]}>
                            {props.products.map((item: Product, index) => {
                                const inventoryItem = props.inventory.attributes.inventory_items.find(ii => ii.attributes.product_id === item.attributes.id)
                                const price = item.calculatePrice(props.customer)

                                const canBuy = item.hasRightToBuy(props.customer)

                                return (
                                    <ImageListItem cols={1} rows={1} className={`${canBuy ? classes["pointer"] : classes["greyedOut"]}`} key={index} onClick={() => canBuy && props.add(item)}>
                                        <img className={classes["img"]} src={item.attributes.photo ? `${config.api}${item.attributes.photo}` : '/images/Food.jpg'} alt={item.attributes.name} />
                                        <ImageListItemBar
                                            title={item.attributes.name}
                                            subtitle={`${price ? `€${price}` : 'Not for sale'} - ${inventoryItem ? inventoryItem.attributes.amount : 0} left`}
                                        />
                                    </ImageListItem>
                                )
                            })}
                        </ImageList>
                    )
                }
                <Pagination count={props.totalPages} className={classes["pagination"]} color='primary' onChange={(event, page) => props.changePage(page)} />
            </div>
        </Paper>
    );
    
        

    /**
     * 
     * return (
            <div className={classes.empty} style={{width: props.width === 'xs' ? '100%' : `calc(100% - 250px)`}}>
                <LocalMallOutlinedIcon color='disabled' className={classes.emptyIcon} />
                <Typography variant={props.width === 'xs' ? 'h4' : 'h1'} color='textSecondary'>No category selected</Typography>
                <Typography variant={props.width === 'xs' ? 'h5' : 'h2'} color='textSecondary'>Please select a category from the drawer on the right</Typography>
            </div>
        )
     * 
     * 
     */
}

export default ContentGrid