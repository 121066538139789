import React from 'react';
import { AppBar, Toolbar as MaterialToolbar, IconButton, Typography, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { MainDrawer } from '../Drawer/Drawer'
import {Link} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        zIndex: theme.zIndex.drawer + 1
    },
    logo: {
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

export default function Toolbar(props: any) {
    const [state, setState] = React.useState({
        open: false
    });

    const classes = useStyles();

    return (
        <AppBar position="relative" className={classes.root}>
            <MaterialToolbar color='primary'>
                <MainDrawer onClose={() => setState({open: false})} open={state.open} onOpen={() => setState({open: true})}/>
                <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => setState({open: true})}
                    size="large">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" style={{flexGrow: 1}} className={classes.logo} component={Link} to={'/'}>
                    IG5Tool
                </Typography>
            </MaterialToolbar>
        </AppBar>
    );
}