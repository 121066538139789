import React, {useEffect, useState} from "react";
import Consumable, {RawConsumableAttributes} from "../../classes/DataBaseValue/Consumable";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import Product from "../../classes/DataBaseValue/Product";
import HomeCard from "./HomeCard";
import Title from "./Title";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {DateTime} from "luxon";

export default function OutOfStockProducts() {

    const [products, setProducts] = useState<Array<Product>>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async function() {

            setLoading(true)

            const route = Consumable.route.addPredicate(new QueryPredicate('with_popularity_between[upper]', DateTime.now().toISODate()))

            setProducts((await Consumable.get<RawConsumableAttributes, Consumable>(0, route)).records)

            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title='Products Out-of-Stock' loading={loading}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {products.map((product) => (
                            <TableRow key={product.attributes.id}>
                                <TableCell>{product.attributes.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </HomeCard>
    )
}