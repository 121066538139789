import FormBody, {FormProps} from "./FormBody";
import ScanTag from "../../../classes/DataBaseValue/ScanTag";
import React from "react";
import TextField from "./TextField";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    sidebyside: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

export interface ScanTagFormProps extends FormProps<ScanTag> {
    children: React.ReactNode
}

function ScanTagForm(props: ScanTagFormProps) {

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    const classes = useStyles();

    return (
        <div className={classes.sidebyside}>
            <TextField instance={new StringValue(props.instance.attributes.tag)} hook={(update) => propagate("tag", update)} name="Tag" />
            {props.children}
        </div>
    )
}

export default ScanTagForm