import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField, Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Rent, {RawRentAttributes} from "../../classes/DataBaseValue/Rent";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DataBaseValueAutoComplete from "../../components/DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import Product from "../../classes/DataBaseValue/Product";
import Consumable from "../../classes/DataBaseValue/Consumable";
import Book from "../../classes/DataBaseValue/Book";
import Hardware from "../../classes/DataBaseValue/Hardware";
import GlobalContext from "../../GlobalContext";

/**RentsDisplay Component
 * 
 * Displaying active, incative, or overtime rents
 * @param props.rents - indicate what the context is
 * @param props.className - css class names
 */
export default function RentsDisplay(props: {
    rents: 'active' | 'inactive' | 'overtime',
    className: any
}) {

    const [page, setPage]   = useState<number>(0)
    const [pageTotals, setPageTotals] = useState<number>(0)
    const [rents, setRents] = useState<Array<Rent>>([])

    /* Indicate if the rent should be popped up or not and if yes, which rent should be in the popup */
    const [rentInDialog, setRentInDialog] = useState<null | Rent>(null)

    const { load, raiseSuccess } = useContext(GlobalContext)

    /* Effect triggered at start of component */
    useEffect(() => {
        (async function() {
            await load(async () => {
                setPageTotals((await Rent.getTotals(Rent.route.addRoute(props.rents))).pages)
            }, 'Getting pages')
        })();
    }, [])

    /* Effect triggered every time the page is set */
    useEffect(() => {
        (async function() {
            await load(async () => {
                setRents((await Rent.get<RawRentAttributes, Rent>(page, Rent.route.addRoute(props.rents))).records)
            }, 'Getting rents')
        })();
    }, [page])

    /* Short for return (which is a preserved keyword in JS)
     * What should happen when returning a product
     */
    const ret = async (rent: Rent, returnWarranty: boolean) => {
        await load(async () => {
            await rent.return(returnWarranty);
            raiseSuccess('Rent returned')
            setPageTotals((await Rent.getTotals(Rent.route.addRoute(props.rents))).pages)
            setPage(1)
            setRentInDialog(null)
        });
    }

    /* What should happen when extending a product */
    const extend = async (rent: Rent) => {
        await load(async () => {
            await rent.extend();
            const response = await Rent.get<RawRentAttributes, Rent>(page, Rent.route.addRoute(props.rents))
            setPageTotals(response.totals.pages)
            setRents(response.records)
            raiseSuccess('Rent extended')
        });
    }

    return (
        <div className={props.className}>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {Rent.attributeNames.map((name, index) => (
                                <TableCell key={index} >
                                    {name}
                                </TableCell>
                            ))}
                            {
                                /* Only show when the current context is not about active or overtime rents */
                                props.rents === 'inactive'
                                    ? null
                                    : (
                                        <TableCell>
                                            Return
                                        </TableCell>
                                    )
                            }
                            {
                                /* Only show when the current context is about active rents */
                                props.rents === 'overtime' || props.rents === 'inactive'
                                    ? null
                                    : (
                                        <TableCell>
                                            Extend
                                        </TableCell>
                                    )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rents.map((rent, index) => (
                            <TableRow key={index}>
                                {rent.attributesToTable().map(({Component, content}, index) => (
                                    <TableCell key={index}>
                                        <Component content={content} />
                                    </TableCell>
                                ))}
                                {
                                    props.rents === 'inactive'
                                        ? null
                                        : (
                                            <TableCell>
                                                <IconButton onClick={() => setRentInDialog(rent)} size="large">
                                                    <KeyboardReturnIcon />
                                                </IconButton>
                                            </TableCell>
                                        )
                                }
                                {
                                    props.rents === 'overtime' || props.rents === 'inactive'
                                        ? null
                                        : (
                                            <TableCell>
                                                <IconButton onClick={() => extend(rent)} size="large">
                                                    <RotateLeftIcon />
                                                </IconButton>
                                            </TableCell>
                                        )
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={pageTotals}
                onPageChange={(event, page) => setPage(page)}
                page={page}
                rowsPerPage={25}
            />

            <Dialog open={rentInDialog !== null} onClose={() => setRentInDialog(null)}>
                <DialogTitle>Check Product for damages</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        Please check the product for any damages.
                        If the product is damaged, the renter is not eligible for the refund of their warranty.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRentInDialog(null)}>
                        Cancel
                    </Button>
                    <Button onClick={() => rentInDialog !== null && ret(rentInDialog, true)} color="primary">
                        Product is OK
                    </Button>
                    <Button onClick={() => rentInDialog !== null && ret(rentInDialog, false)} color="primary">
                        Product is damaged
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}