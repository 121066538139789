import React, {useEffect, useState, useContext} from 'react';
import Transaction, {RawTransactionAttributes} from "../../classes/DataBaseValue/Transaction";
import {
    Backdrop,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Center from "../../components/Center/Center";
import {IHistory} from "./Transactions";
import DataBaseValueAutoComplete from '../../components/DataBaseValueAutoComplete/DataBaseValueAutoComplete';
import Person from '../../classes/DataBaseValue/Person';
import QueryPredicate from '../../classes/Route/QueryPredicate';
import GlobalContext from '../../GlobalContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        height: '100%',
        overflowY: 'scroll'
    }
}));

export default function History(props: {history: IHistory, setHistory: React.Dispatch<React.SetStateAction<IHistory>>}) {

    const classes = useStyles();


    const {history, setHistory} = props

    const { raiseError } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [sender, setSender] = useState<Person>(Person.makeStub())
    const [receiver, setReceiver] = useState<Person>(Person.makeStub())

    const load = async (toTry: Function) => {
        setLoading(true);
        try {
            await toTry();
        } catch (e) {
            raiseError(e)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        (async function() {

            const senderQueryPredicate = new QueryPredicate('with_sender', sender.attributes.party.attributes.id)
            const receiverQueryPredicate = new QueryPredicate('with_receiver', receiver.attributes.party.attributes.id)

            const route = !sender.isStub()
                ? !receiver.isStub()
                    ? Transaction.route.addPredicate(senderQueryPredicate).addPredicate(receiverQueryPredicate)
                    : Transaction.route.addPredicate(senderQueryPredicate)
                : !receiver.isStub()
                    ? Transaction.route.addPredicate(receiverQueryPredicate)
                    : Transaction.route

            await load(async () => {

                const response = await Transaction.get<RawTransactionAttributes, Transaction>(1, route)

                const totalPages = response.totals.pages;
                const transactions = response.records

                setHistory({
                    totalPages: totalPages,
                    page: 1,
                    transactions: transactions
                })
            })
        })();
    }, [sender, receiver]);

    const changePage = async (page: number) => setHistory({
        ...history,
        transactions: (await Transaction.get<RawTransactionAttributes, Transaction>(page+1)).records,
        page: page+1
    });

    if(loading) return (
        <Center>
            <CircularProgress color='secondary' />
        </Center>
    );
    else if(history.page > 0)
        return (
            <TableContainer className={classes.table} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <DataBaseValueAutoComplete
                                    Class={Person}
                                    label="From"
                                    fullWidth={false}
                                    onSelect={(val: Person | null) => val !== null && setSender(val)}
                                />
                                </TableCell>
                            <TableCell>
                            <DataBaseValueAutoComplete
                                    Class={Person}
                                    label="To"
                                    fullWidth={false}
                                    onSelect={(val: Person | null) => val !== null && setReceiver(val)}
                                />
                            </TableCell>
                            <TableCell>Payment Type</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.transactions.map(({attributes}) => (
                            <TableRow key={attributes.id} >
                                <TableCell>{attributes.sender.attributes.name}</TableCell>
                                <TableCell>{attributes.receiver.attributes.name}</TableCell>
                                <TableCell>{attributes.payment_type}</TableCell>
                                <TableCell>€{attributes.amount / 100}</TableCell>
                                <TableCell>{attributes.comment}</TableCell>
                                <TableCell>{attributes.created_at}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[25]}
                                rowsPerPage={25}
                                colSpan={6}
                                count={history.totalPages}
                                page={history.page - 1}
                                onPageChange={(event, page) => changePage(page)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        );
    else return null;
}