import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";
import HardwareClass from '../../classes/DataBaseValue/Hardware';
import React from "react";

/** Hardware Component
 * 
 * Database value overview for hardware
 */
export default function Hardware(props: any) {
    return <DataBaseValueOverview Class={HardwareClass} />
}