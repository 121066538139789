import FormBody, {FormProps} from "./FormBody";
import Category from "../../../classes/DataBaseValue/Category";
import React from "react";
import TextField from "./TextField";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";
import ProductKindSelection from "./ProductKindSelection";
import Form from "../../../classes/DataBaseValue/Form";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";

function CategoryForm(props: FormProps<Category>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    return (
        <FormBody>
            <TextField instance={new StringValue(attributes.name)} hook={(update) => propagate("name", update)} name="Name" />
            <ProductKindSelection instance={new StringValue(attributes.for_kind)} hook={(update) => propagate("for_kind", update)} name="For Kind"  />
        </FormBody>
    )
}

export default CategoryForm