import DataBaseValue from "../../classes/DataBaseValue/DataBaseValue";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, { useContext } from "react";
import {CrudValue} from "../../classes/DataBaseValue/CrudValue";
import GlobalContext from "../../GlobalContext";

export default function RemoveDialog(props: {Class: any, instance: CrudValue, open: boolean, onClose: () => void, onSubmit: () => void}) {

    const { load } = useContext(GlobalContext)

    const submit = async () => {
        await load(async () => await props.instance.delete())
        props.onSubmit()
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>{`Remove ${props.Class.asString}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to remove this ${props.Class.asString}? Removed values are not deleted from the database but merely discarded, so you can always revive this instance later.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={submit} color="primary">
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}