import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import React, {useContext, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import DataBaseValue, {RawDataBaseValueAttributes} from "../../classes/DataBaseValue/DataBaseValue";
import {FormProps} from "./FormBody/FormBody";
import GlobalContext from "../../GlobalContext";

export enum Purpose {Add, Edit}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            margin: theme["spacing"](1),
            width: '25ch',
        },
    },
    form: {

    }
}));

interface AddEditDialogProps<T extends DataBaseValue> {
    Class: new (params: RawDataBaseValueAttributes) => T,
    instance: T,
    hook: (update: T) => void,
    onSubmit: () => void,
    onClose: () => void,
    open: boolean,
    purpose: Purpose
}

function AddEditDialog<T extends DataBaseValue>(props: AddEditDialogProps<T>) {

    const classes = useStyles();

    const Form: React.FunctionComponent<FormProps<T>> = (props.Class as any).form;

    const [highlightErrors, setHighlightErrors] = useState<boolean>(false)

    const { load } = useContext(GlobalContext);

    const onSubmit = async () => {
        await load(async () => {
            try {
                await props.onSubmit();
            } catch (e) {
                setHighlightErrors(true)
                throw e;
            }
        }, props.purpose === Purpose.Add ? 'Adding' : 'Editing')
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{`${props.purpose === Purpose.Add ? 'Add' : 'Edit'} ${(props.Class as any).asString}`}</DialogTitle>
            <DialogContent>
                <Form instance={props.instance} hook={props.hook} name={(props.Class as any).asString} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEditDialog