import React from "react";
import { Button, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {PaymentType} from "../../classes/DataBaseValue/Transaction";
import {BasketType} from "./Shopping";
import Sale from "../../classes/DataBaseValue/Sale";
import Rent from "../../classes/DataBaseValue/Rent";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '3rem',
        marginRight: '3rem',
        marginTop: '1rem',
        alignItems: 'space-between',
        justifyContent: 'space-around',
        position: 'sticky',
        bottom: theme.spacing(1),
        height: '15%'
    },
    margin: {
        marginBottom: '0.5rem'
    }
}));

export default function Actions(props: {
    basket: BasketType,
    onPay: (paymentType: PaymentType) => Promise<void>,
    onCancel: () => void
}) {
    switch(props.basket.constructor) {
        case Sale: return <ActionsForSale {...props} />
        case Rent: return <ActionsForRent {...props} />
        default: return null;
    }
}

function ActionsForRent(props: {
    onPay: (paymentType: PaymentType) => Promise<void>,
    onCancel: () => void
}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Button variant="contained" color="primary" size="large" className={classes.margin} onClick={() => props.onPay('Cash')}>Rent</Button>
            <Button variant="outlined"  color="inherit" size="large" onClick={props.onCancel}>Cancel</Button>
        </div>
    )
}

function ActionsForSale(props: {
    onPay: (paymentType: PaymentType) => Promise<void>,
    onCancel: () => void
}) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Button variant="contained" color="primary" size="large" className={classes.margin} onClick={() => props.onPay('Account')} >Scribble</Button>
            <Button variant="contained" color="primary" size="large" className={classes.margin} onClick={() => props.onPay('Cash')}>Kaching</Button>
            <Button variant="outlined" color="inherit"  size="large" onClick={props.onCancel}>Cancel</Button>
        </div>
    );
}