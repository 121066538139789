import FormBody, {FormProps} from "./FormBody";
import Person from "../../../classes/DataBaseValue/Person";
import React from "react";
import TextField from "./TextField";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";
import AddressForm from "./AddressForm";
import Address from "../../../classes/DataBaseValue/Address";
import GroupsSelection from "./GroupsSelection";
import NumericalField from "./NumericalField";
import StrictTextField from "./StrictTextField";
import EmailField from "./EmailField";
import ScanTagsForm from "./ScanTagsForm";

function PersonForm(props: FormProps<Person>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    const propagatePartyAttributes = (key: string, value: AbstractValue) =>
        props.hook(props.instance.merge({
            party: {
                ...props.instance.attributes.party.toRaw(),
                [key]: value.toRaw()
            }
        }))

    return (
        <FormBody>
            <StrictTextField instance={new StringValue(attributes.first_name)} hook={(update) => propagate("first_name", update)} name="First Name" />
            <StrictTextField instance={new StringValue(attributes.last_name)} hook={(update) => propagate("last_name", update)} name="Last Name" />
            <StrictTextField instance={new StringValue(attributes.net_id)} hook={(update) => propagate("net_id", update)} name="NetID" />
            <NumericalField instance={new NumberValue(parseInt(attributes.role_number))} hook={(update) => propagate("role_number", update)} name="Role Number" />
            <EmailField instance={new StringValue(attributes.email)} hook={(update) => propagate("email", update)} name="Email" />
            <AddressForm instance={attributes.address ? attributes.address : Address.makeStub()} hook={(update) => propagate("address", update)} name="Address" />
            <GroupsSelection instance={attributes.party.attributes.party_groups_associations} hook={(update) => propagatePartyAttributes("party_groups_associations", update)} name="Groups" />
            <ScanTagsForm instance={attributes.scan_tags} hook={(update) => propagate("scan_tags", update)} name="Scan Tags" />
        </FormBody>
    )
}

export default PersonForm