import Right, {RawRightAttributes} from "./Right";
import DataBaseValueCollection from "./DataBaseValueCollection";

class Rights extends DataBaseValueCollection{

    public rights: Array<Right>

    constructor(rights: Array<RawRightAttributes>) {
        super();
        this.rights = rights.map(r => new Right(r))
    }

    public static makeStub(): Rights {
        return new Rights([])
    }

    public toRaw(): Array<RawRightAttributes> {
        return this.rights.map(r => r.toRaw());
    }

    public merge(toMerge: Array<RawRightAttributes>): Rights {
        return new Rights(toMerge);
    }
}

export default Rights