import QueryPredicate from "./QueryPredicate";

class Route {
    public routes: Array<string> = []
    public predicates: Array<QueryPredicate> = []

    constructor(routes: Array<string> = [], predicates: Array<QueryPredicate> = []) {
        this.routes = routes;
        this.predicates = predicates;
    }

    public addRoute(route: string | null) {
        return new Route([...this.routes, route ? route : ""], this.predicates);
    }

    public addPredicate(predicate: QueryPredicate) {
        return new Route(this.routes, [...this.predicates, predicate]);
    }

    public toString() {
        const routes = '/'.concat(this.routes.join('/'));
        const predicates = '?'.concat(this.predicates.map(p => `${p.key}=${p.val}`).join('&'));
        return `${routes}${predicates}`
    }

}

export default Route;