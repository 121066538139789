import React, {useContext, useEffect, useState} from "react";
import InventoryObject from "../../classes/DataBaseValue/Inventory";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import config from "../../config";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import makeStyles from '@mui/styles/makeStyles';
import DataBaseValueAutoComplete from "../../components/DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import Consumable from "../../classes/DataBaseValue/Consumable";
import Product, {RawProductAttributes} from "../../classes/DataBaseValue/Product";
import InventoryItem from "../../classes/DataBaseValue/InventoryItem";
import { Pagination } from '@mui/material';
import Hardware from "../../classes/DataBaseValue/Hardware";
import Book from "../../classes/DataBaseValue/Book";
import SearchBar from "../../components/SearchBar"
import Route from "../../classes/Route/Route";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import ProductFactory from "../../classes/DataBaseValue/ProductFactory";
import useScan from '../../Scanner/useScan';
import Sale from "../../classes/DataBaseValue/Sale";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import InventoryTransfer from "../../classes/DataBaseValue/InventoryTransfer";
import InventoryTransferItem from "../../classes/DataBaseValue/InventoryTransferItem";
import Company from "../../classes/DataBaseValue/Company";
import Transaction from "../../classes/DataBaseValue/Transaction";
import GlobalContext from "../../GlobalContext";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        overflowY: 'hidden',
        height: '100%'
    },
    searchBar: {
        height: '6%'
    },
    list: {
        height: '88%',
        overflowY: 'scroll'
    },
    pagination: {
        height: '6%',
        display: 'flex',
        justifyContent: 'center'
    },
    fabAdd: {
        position: 'absolute',
        bottom: '17%',
        right: '3%',
        zIndex: theme.zIndex.drawer + 3,
    },
    fabRemove: {
        position: 'absolute',
        bottom: '7%',
        right: '3%',
        zIndex: theme.zIndex.drawer + 2,
    },
    marginBetween: {
        marginTop: theme.spacing(1)
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    noMargin: {
        margin: 0
    }
}))

type RemovalReason = 'stock_correction' | 'added_too_much'

/**Inventory Component
 * 
 * This component showcases the inventory (specifically, the stock)
 */
function Inventory(props: any) {
    const [inventory, setInventory] = useState<InventoryObject>(InventoryObject.makeStub())
    const [products, setProducts] = useState<Array<Product>>([])
    const [product, setProduct] = useState<Product>(Consumable.makeStub())
    const [page, setPage] = useState<number>(0)
    const [searchText, setSearchText] = useState<string>("")
    const [route, setRoute] = useState<Route>(Product.route)
    const [pageTotals, setPageTotals] = useState<number>(0)
    const [dialogOpen, setDialogOpen] = useState<'closed' | 'add' | 'remove' | 'add_then_close' | 'remove_then_close'>('closed')

    const [inventoryItem, setInventoryItem] = useState<InventoryItem>(InventoryItem.makeStub())
    const [sellItems, setSellItems] = useState<boolean>(true)
    const [removalReason, setRemovalReason] = useState<RemovalReason | null>(null)

    const { load, raiseError, raiseSuccess } = useContext(GlobalContext);

    const classes = useStyles()

    useEffect(() => {
        (async function() {
            load(async () => {
                setInventory(await InventoryObject.Kassa());
            }, 'Fething kassa')
        })();
    }, [])

    useEffect(() => {
        (async function() {
            load(async () => {
                /* Get the sales inventory (our stock) */
                setProducts((await Product.get<RawProductAttributes, Product>(page, route)).records)
            }, 'Getting the stock')
        })();
    }, [page, route])

    useEffect(() => {
        (async function() {
            load(async () => {
                setPageTotals((await Product.getTotals(route)).pages)
            }, 'Getting pages')
        })();
    }, [products])

    /* Barcode scanner is available for use here */
    useScan(async (type, value) => {
        try {
            switch(type) {
                case 'barcode': 
                    openDialogWithProduct(await Product.getByScan(value));
                    break;
            }
        } catch (e) {
            raiseError(e)
        }
    }, [])

    const changeSearchText = (newText: string) => {
        setSearchText(newText)
        if(newText === "") cancelSearch()
    }

    const search = () => {
        setPage(0)
        setRoute(Product.route.addPredicate(new QueryPredicate('search', searchText)))
    }

    const cancelSearch = () => {
        setSearchText("")
        setRoute(Product.route)
        setPage(0)
    }

    const submit = async (mode: 'add' | 'remove' | 'add_then_close' | 'remove_then_close') => {
        load(async () => {
            const newInventoryItem = inventoryItem.merge({
                inventory_id: inventory.attributes.id,
                amount: mode === 'remove' || mode === 'remove_then_close' ? inventoryItem.attributes.amount * -1 : inventoryItem.attributes.amount
            })

            if(removalReason === 'stock_correction' && (dialogOpen === 'remove' || dialogOpen === 'remove_then_close')) {
                const sale = Sale.makeStub().merge({
                    inventory_transfer: InventoryTransfer.makeStub().merge({
                        inventory_transfer_items: [
                            InventoryTransferItem.makeStub().merge({
                                product: product.toRaw(),
                                amount: Math.abs(newInventoryItem.attributes.amount)
                            }).toRaw()
                        ],
                        from_id: inventory.attributes.id,
                        to_id: (await InventoryObject.External()).attributes.id
                    }).toRaw(),
                    transaction: Transaction.makeStub().merge({
                        payment_type: 'Cash',
                        // Amount does not matter. It will be correctly calculated in the backend, but making it 0 isn't well liked by the backend :)
                        amount: 1,
                        // The sender is non-member, always. Though this causes the selling of alcohol for example not to work...
                        sender: (await Company.External()).attributes.party.toRaw(),
                        receiver: (await Company.Kassa()).attributes.party.toRaw(),
                        // Some reasonable message
                        comment: `Stock correction`
                    }).toRaw()
                })

                await sale.post()
            } else {
                await newInventoryItem.post()
            }

            raiseSuccess(mode === 'add' || mode === 'add_then_close' ? `Added to inventory` : `Removed from inventory`)
            setInventoryItem(InventoryItem.makeStub());
            setInventory(await InventoryObject.Kassa());
            setRemovalReason(null)
            if(dialogOpen === 'add_then_close' || dialogOpen === 'remove_then_close')
                setDialogOpen('closed')
        })
    }

    const openDialogWithProduct = (product: Product) => {
        setProduct(product)
        setInventoryItem(inventoryItem.merge({product_id: product.attributes.id}))
        setDialogOpen('add_then_close')
    }

    const formIncomplete = () =>
        inventoryItem.attributes.product_id === "" || isNaN(inventoryItem.attributes.amount) || inventoryItem.attributes.amount <= 0 || (dialogOpen == "remove" && removalReason === null)

    return (
        <div className={classes.root}>
            <SearchBar
                value={searchText}
                onChange={changeSearchText}
                onRequestSearch={search}
            />
            
            <List className={classes.list}>
                {products.map((product, index) => {
                    const inventoryItem = inventory.attributes.inventory_items.find(ii => ii.attributes.product_id === product.attributes.id)
                    return (
                        <ListItem key={index} onClick={() => openDialogWithProduct(product)} className={classes.pointer}>
                            <ListItemAvatar>
                                <Avatar
                                    src={product.attributes.photo ? `${config.api}${product.attributes.photo}` : '/images/Food.jpg'}/>
                            </ListItemAvatar>
                            <ListItemText primary={product.attributes.name}
                                          secondary={`${inventoryItem ? inventoryItem.attributes.amount : 0} in stock`}/>
                        </ListItem>
                    );
                })}
            </List>
            <Fab color="secondary" className={classes.fabAdd} onClick={() => setDialogOpen('add')} sx={{position: "absolute"}}>
                <AddIcon />
            </Fab>
            <Fab color="secondary" className={classes.fabRemove} onClick={() => setDialogOpen('remove')} sx={{position: "absolute"}}>
                <RemoveIcon />
            </Fab>
            <Dialog open={dialogOpen !== 'closed'} onClose={() => setDialogOpen('closed')}>
                <DialogTitle>{dialogOpen === 'add' || dialogOpen === 'add_then_close' ? 'Add item to inventory' : 'Remove item from inventory'}</DialogTitle>
                <DialogContent className={classes.flexColumn}>
                    <DataBaseValueAutoComplete
                        Class={Product}
                        label='Select product'
                        fullWidth={true}
                        value={product}
                        onSelect={(product: Product | null) => {
                            if(product !== null) {
                                /*
                                * Typescript can be a mess sometimes... The regular type casting with 'as' does
                                * not work for some reason in this setting, so we have to manually set the constructor
                                * from Product to the correct subtype. This may actually also affect other places in
                                * the code, so if you ever get an error in the type of 'cannot read attribute author
                                * of undefined', this may be related
                                * */
                                switch(product.attributes.kind) {
                                    case "consumable": product.constructor = Consumable;break;
                                    case "book": product.constructor = Book;break;
                                    case "hardware": product.constructor = Hardware;break;
                                }

                                setProduct(product)
                                setInventoryItem(inventoryItem.merge({product_id: product.attributes.id}))
                            }
                        }}
                    />
                    <TextField
                        className={classes.marginBetween}
                        label='Enter amount'
                        variant='outlined'
                        value={isNaN(inventoryItem.attributes.amount) ? "" : inventoryItem.attributes.amount.toString()}
                        onChange={(e) =>
                            setInventoryItem(inventoryItem.merge({amount: isNaN(parseInt(e.target.value)) ? NaN : parseInt(e.target.value)}))}
                    />
                    {
                        (dialogOpen === 'remove' || dialogOpen === 'remove_then_close')
                            ? (
                                <FormControl variant='outlined' className={classes.marginBetween}>
                                    <InputLabel id='removal-reason'>Reason for removal of item</InputLabel>
                                    <Select variant='outlined' id='removal-reason' value={removalReason} label='Reason for removal of item' onChange={(event) => setRemovalReason((event as any).target.value)} >
                                        <MenuItem value='stock_correction'>Some idiot forgot to sell items and now the stock needs correcting</MenuItem>
                                        <MenuItem value='added_too_much'>I'm an idiot and I accidentally added too many products</MenuItem>
                                    </Select>
                                </FormControl>
                            )
                            : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen('closed')}>
                        Cancel
                    </Button>
                    <Button disabled={formIncomplete()} onClick={() => dialogOpen !== 'closed' && submit(dialogOpen)} color="primary">
                        {dialogOpen === "add" || dialogOpen === "add_then_close" ? "Add" : "Remove"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Pagination
                className={classes.pagination}
                count={pageTotals}
                color='primary'
                onChange={(event, newPage) => setPage(newPage)}
            />
        </div>
    )

}

export default Inventory