import React from "react";
import { CircularProgress, Paper, Theme, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Title from "./Title";
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => createStyles({
    padding: {
        padding: theme.spacing(1)
    }
}));

function HomeCard(props: {children: React.ReactNode, title: string, loading?: boolean}) {

    const classes = useStyles();

    const theme = useTheme()

    return (
        <Paper className={classes.padding}>
            <Title>{props.title}</Title>
            {
                props.loading
                    ? (
                        <div>
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangular" width={400} height={240} />
                        </div>
                    )
                    : props.children
            }
        </Paper>
    );
}

export default HomeCard