import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from "notistack";
import GlobalContext, { IGlobalMessage } from '../../GlobalContext';

export default function Message() {
    const { enqueueSnackbar } = useSnackbar();
    const [prevMessage, setPrevMessage] = useState<IGlobalMessage>();

    const { message } = useContext(GlobalContext);

    useEffect(() => {
        if (message != prevMessage && message != false) {
            enqueueSnackbar(message.text, { variant: message.type });
            setPrevMessage(message);
        }
    }, [message])

    return <></>
}
