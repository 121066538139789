import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import {GETReturns, ProductKind} from "../types";
import {CrudValue} from "./CrudValue";
import CategoryForm from "../../components/DataBaseValueOverview/FormBody/CategoryForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";

class Category extends CrudValue {

    public attributes: CategoryAttributes;

    public static route = new Route(['categories']);
    public static paginates = false;
    public static asString = 'category';

    public static attributeNames: Array<string> = ["Name", "For Kind"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: BasicContent, content: this.attributes.for_kind},
        ];
    }

    constructor(params: RawCategoryAttributes) {
        super(params);
        this.attributes = params;
    }

    public static async NonAlcoholic(): Promise<Category> {
        return await this.getOne(Category.route.addRoute('non_alcoholic'))
    }

    public toString() {
        return this.attributes.name
    }

    public static makeStub(): Category {
        return new Category({
            name: "",
            for_kind: "consumable",
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawCategoryAttributes {
        return {
            name: this.attributes.name,
            for_kind: this.attributes.for_kind,
            ...super.toRaw()
        }
    }

    public static form = CategoryForm

    public serializeToJson(): { [p: string]: any } {
        return {
            category: {
                id: this.attributes.id ? this.attributes.id : undefined,
                name: this.attributes.name,
                for_kind: this.attributes.for_kind
            }
        }
    }

    public merge(toMerge: PartialRawCategoryAttributes): Category {
        return new Category({...this.toRaw(), ...toMerge});
    }

    public static async getByKind(kind: ProductKind): Promise<GETReturns<Category>> {
        return await this.get(-1, this.route.addPredicate(new QueryPredicate("for_kind", kind)))
    }

    public static async searchByKind(predicate: string, kind: ProductKind) {
        return (await this.search<RawCategoryAttributes, Category>(predicate, 0, this.route.addPredicate(new QueryPredicate("for_kind", kind)))).records
    }
}

export interface CategoryAttributes extends DataBaseValueAttributes {
    name: string,
    for_kind: ProductKind
}

export interface RawCategoryAttributes extends RawDataBaseValueAttributes{
    name: string
    for_kind: ProductKind
}

export interface PartialRawCategoryAttributes extends PartialRawDataBaseValueAttributes{
    name?: string
    for_kind?: ProductKind
}

export default Category;