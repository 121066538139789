import FormBody, {FormProps} from "./FormBody";
import Category from "../../../classes/DataBaseValue/Category";
import DataBaseValueAutoComplete from "../../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import React from "react";
import {ProductKind} from "../../../classes/types";

export interface CategorySelectionProps extends FormProps<Category> {
    kind: ProductKind
}

function CategorySelection(props: CategorySelectionProps) {

    return (
        <FormBody>
            <DataBaseValueAutoComplete
                Class={Category}
                label={props.name}
                value={props.instance}
                fullWidth={false}
                onSelect={(update: Category | null) => update !== null && props.hook(update)}
                customSearch={(value) => Category.searchByKind(value, props.kind)}
                preloadOptions={true}
            />
        </FormBody>
    )
}

export default CategorySelection