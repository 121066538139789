import React, {useEffect, useState} from "react";
import Person from "../../classes/DataBaseValue/Person";
import HomeCard from "./HomeCard";
import {Typography} from "@mui/material";

export default function CoolestPerson(props: any) {
    const [coolestPerson, setCoolestPerson] = useState<Person>(Person.makeStub())
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async function() {
            setLoading(true)
            const coolestPerson = await Person.CoolestPerson()
            setCoolestPerson(coolestPerson)
            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title='Coolest person of the month'>
            <Typography component="p" variant="h5">
                {coolestPerson.isStub() ? '' : coolestPerson.fullName()}
            </Typography>
        </HomeCard>
    )
}