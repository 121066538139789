import FormBody, {FormProps} from "./FormBody";
import Right from "../../../classes/DataBaseValue/Right";
import React from "react";
import TextField from "./TextField";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";

function RightForm(props: FormProps<Right>) {

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    return (
        <FormBody>
            <TextField instance={new StringValue(props.instance.attributes.name)} hook={(update) => propagate("name", update)} name="Name" />
        </FormBody>
    )
}

export default RightForm