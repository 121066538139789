import FormBody, {FormProps} from "./FormBody";
import ScanTags from "../../../classes/DataBaseValue/ScanTags";
import React, {useState} from "react";
import ScanTagForm from "./ScanTagForm";
import ScanTag from "../../../classes/DataBaseValue/ScanTag";
import { IconButton, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import useScan from "../../../Scanner/useScan";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        width: '100%',
        minWidth: '100%'
    }
}));

function ScanTagsForm(props: FormProps<ScanTags>) {

    const {scan_tags} = props.instance;
    
    useScan(async (type, value) =>
        addScanTag(ScanTag.makeStub().merge({tag: value})))

    const addScanTag = (value: ScanTag) =>
        props.hook(props.instance.merge([...props.instance.toRaw(), value.toRaw()]))

    return (
        <FormBody>
            {scan_tags
                .filter(scan_tag => !scan_tag.attributes.discarded_at)
                .map((scan_tag, index) =>
                <ScanTagForm key={index} instance={scan_tag} hook={(update) => props.hook(props.instance.merge(scan_tags.map((st, i) => i === index ? update.toRaw() : st.toRaw())))} name={scan_tag.attributes.tag}>
                    <IconButton
                        onClick={() => props.hook(props.instance.merge(Object.assign([...scan_tags.slice(0, index), ...scan_tags.slice(index + 1)].map(st => st.toRaw()))))}
                        size="large">
                        <DeleteIcon color="error"/>
                    </IconButton>
                </ScanTagForm>)}
            <AddScanTagAssociation hook={(update) => props.hook(props.instance.merge([...props.instance.toRaw(), update.toRaw()]))} />
        </FormBody>
    );
}

export default ScanTagsForm

function AddScanTagAssociation(props: {hook: (update: ScanTag) => void}) {

    const classes = useStyles();

    const [state, setState] = useState<{tag: string}>({
        tag: ""
    })

    const onAdd = () => {
        if(state.tag !== "") {
            props.hook(ScanTag.makeStub().merge({tag: state.tag}))
            setState({tag: ""})
        }
    }

    return (
        <Paper className={classes.root}>
            <TextField
                label="Scantag"
                variant="outlined"
                value={state.tag}
                onChange={(e) => setState({...state, tag: e.target.value}) }
            />
            <IconButton
                color="secondary"
                disabled={state.tag === ""}
                onClick={() => onAdd()}
                size="large">
                <AddIcon />
            </IconButton>
        </Paper>
    );
}