import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview";
import Company from "../../classes/DataBaseValue/Company";
import React from "react";

/** Companies Component
 * 
 * Database value overview for companies
 */
export default function Companies(props: any) {
    return <DataBaseValueOverview Class={Company} />
}