import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Party, {PartialRawPartyAttributes, RawPartyAttributes} from "./Party";
import Route from "../Route/Route";
import Address, {RawAddressAttributes} from "./Address";
import {CrudValue} from "./CrudValue";
import ScanTag, {RawScanTagAttributes} from "./ScanTag";
import ScanTags from "./ScanTags";
import PersonForm from "../../components/DataBaseValueOverview/FormBody/PersonForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import QueryPredicate from "../Route/QueryPredicate";
import Product from "./Product";
import InventoryTransferItem, {RawInventoryTransferItemAttributes} from "./InventoryTransferItem";

class Person extends CrudValue {

    public attributes: PersonAttributes;

    public static route = new Route(['persons']);
    public static paginated = true;
    public static asString = 'person';

    public static attributeNames: Array<string> = ["First Name", "Last Name", "NetID", "Role Number", "Email", "Balance", "Groups"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.first_name},
            {Component: BasicContent, content: this.attributes.last_name},
            {Component: BasicContent, content: this.attributes.net_id},
            {Component: BasicContent, content: this.attributes.role_number},
            {Component: BasicContent, content: this.attributes.email},
            {Component: BasicContent, content: this.attributes.party.attributes.balance / 100},
            {Component: ArrayContent, content: this.attributes.party.attributes.party_groups_associations.party_groups_associations.map(g => g.attributes.group.attributes.name)},
        ]
    }

    public toString() {
        return `${this.attributes.first_name} ${this.attributes.last_name}`
    }

    constructor(params: RawPersonAttributes) {
        super(params);

        this.attributes = {
            ...params,
            party: new Party(params.party),
            address: params.address ? new Address(params.address) : null,
            scan_tags: new ScanTags(params.scan_tags)
        }
    }

    public static makeStub(): Person {
        return new Person({
            address: {
                city: "",
                street: "",
                number: "",
                postal_code: "",
                id: "",
                created_at: null,
                updated_at: null,
                discarded_at: null
            },
            created_at: null,
            updated_at: null,
            discarded_at: null,
            id: "",
            scan_tags: [],
            first_name: "",
            last_name: "",
            net_id: "",
            role_number: "",
            email: "",
            party: Party.makeStub().toRaw()
        })
    }

    public toRaw(): RawPersonAttributes {
        return {
            first_name: this.attributes.first_name,
            last_name: this.attributes.last_name,
            net_id: this.attributes.net_id,
            role_number: this.attributes.role_number,
            email: this.attributes.email,
            party: this.attributes.party.toRaw(),
            address: this.attributes.address? this.attributes.address.toRaw() : null,
            scan_tags: this.attributes.scan_tags.toRaw(),
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawPersonAttributes): Person {
        return new Person({...this.toRaw(), ...toMerge});
    }

    public fullName() {
        return `${this.attributes.first_name} ${this.attributes.last_name}`
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            person: {
                first_name: this.attributes.first_name,
                last_name: this.attributes.last_name,
                net_id: this.attributes.net_id,
                role_number: this.attributes.role_number,
                email: this.attributes.email,
                party_attributes: this.attributes.party.serializeToJson(),
                address_attributes: this.attributes.address?.serializeToJson(),
                scan_tags_attributes: this.attributes.scan_tags.scan_tags.map(st => ({
                    tag: st.attributes.tag,
                    id: st.attributes.id ? st.attributes.id : undefined,
                    _destroy: !!st.attributes.discarded_at
                }))
            }
        }
    }

    public static async getByScan(value: string): Promise<Person> {
        const matching: Array<Person> = (await this.get<RawPersonAttributes, Person>(0, Person.route.addPredicate(new QueryPredicate('scan', value)))).records
        if(matching.length < 1)
            throw new Error(`No person identified with this scan`)
        else
            return matching[0];
    }

    public async purchasesThisWeekOf(product: Product): Promise<Array<InventoryTransferItem>> {
        return (await InventoryTransferItem.get<RawInventoryTransferItemAttributes, InventoryTransferItem>(false, Person.route.addRoute(this.attributes.id).addRoute('purchases_this_week').addRoute(product.attributes.id))).records
    }

    public static async Bram(): Promise<Person> {
        return (await Person.get<RawPersonAttributes, Person>(0, Person.route.addPredicate(new QueryPredicate('with_net_id', 'bvdbogae')))).records[0]
    }

    public static async CoolestPerson(): Promise<Person> {
        return (await Person.get<RawPersonAttributes, Person>(0, Person.route.addPredicate(new QueryPredicate('with_net_id', 'nmattela')))).records[0]
    }

    public static form = PersonForm
}

export interface PersonAttributes extends DataBaseValueAttributes {
    first_name: string,
    last_name: string,
    net_id: string,
    role_number: string,
    email: string,
    party: Party,
    address: Address | null,
    scan_tags: ScanTags
}

export interface RawPersonAttributes extends RawDataBaseValueAttributes {
    first_name: string,
    last_name: string,
    net_id: string,
    role_number: string,
    email: string,
    party: RawPartyAttributes,
    address: RawAddressAttributes | null,
    scan_tags: Array<RawScanTagAttributes>
}

export interface PartialRawPersonAttributes extends PartialRawDataBaseValueAttributes {
    first_name?: string,
    last_name?: string,
    net_id?: string,
    role_number?: string,
    email?: string,
    party?: RawPartyAttributes,
    address?: RawAddressAttributes | null,
    scan_tags?: Array<RawScanTagAttributes>
}


export default Person;