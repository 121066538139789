import DataBaseValue, {RawDataBaseValueAttributes} from "../../classes/DataBaseValue/DataBaseValue";
import React, {useEffect, useState, useContext} from "react";
import {
    Fab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { Pagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchBar from "../../components/SearchBar"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import AddDialog from "./AddDialog";
import EditDialog from "./EditDialog";
import {CrudValue} from "../../classes/DataBaseValue/CrudValue";
import RemoveDialog from "./RemoveDialog";
import GlobalContext from "../../GlobalContext";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    scrollable: {
        overflowY: 'scroll',
        height: '100%'
    },
    pagination: {
        margin: '1rem auto'
    },
    fab: {
        position: 'absolute',
        bottom: '7%',
        right: '3%',
        zIndex: theme.zIndex.drawer + 2,
    }
}))

enum CurrentOpenDialog { None, Add, Edit, Remove }

function DataBaseValueOverview<C extends RawDataBaseValueAttributes, V extends CrudValue>(props: {Class: any}) {
    const [state, setState] = useState<{instances: Array<V>, page: number, totalPages: number, searchText: string, currentOpenDialog: CurrentOpenDialog, selectedInstance: CrudValue | null}>({
        instances: [],
        page: 0,
        totalPages: 0,
        searchText: "",
        currentOpenDialog: CurrentOpenDialog.None,
        selectedInstance: null
    });

    const classes = useStyles();

    useEffect(() => {
        search("", 1)
    }, []);

    const { load } = useContext(GlobalContext)


    const search = async (searchText: string, page: number) => {
        load(async (loadHook) => {

            const response = (await props.Class.search(searchText, page))

            setState({
                ...state,
                page: page,
                instances: response.records,
                totalPages: response.totals.pages,
                searchText: searchText
            })
        }, `Searching for "${searchText}"`)
    }

    const refresh = async (reset: boolean) => {
        load(async () => {

            const response = await props.Class.search(reset ? "" : state.searchText, reset ? 1 : state.page)

            setState({
                ...state,
                page: reset ? 1 : state.page,
                instances: response.records,
                totalPages: response.totals.pages,
                searchText: reset ? "" : state.searchText,
                currentOpenDialog: CurrentOpenDialog.None
            })
        })
    }

    const selectForEdit = (instance: V) =>
        setState({
            ...state,
            currentOpenDialog: CurrentOpenDialog.Edit,
            selectedInstance: instance
        })

    const selectForRemove = (instance: V) =>
        setState({
            ...state,
            currentOpenDialog: CurrentOpenDialog.Remove,
            selectedInstance: instance
        })

    return (
        <div className={classes.root}>
            <SearchBar
                value={state.searchText}
                onChange={(newValue) => newValue === "" ? search("", 1) : setState({...state, searchText: newValue})}
                onRequestSearch={() => search(state.searchText, 1)}
            />
            <TableContainer className={classes.scrollable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.Class.attributeNames.map((attribute: string, index: number) => (
                                <TableCell key={index}>
                                    {attribute}
                                </TableCell>
                            ))}
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.instances.map((instance: V, index) => (
                            <TableRow key={index}>

                                {instance.attributesToTable().map(({Component, content}, index) =>
                                    <TableCell key={index}>
                                        <Component content={content} />
                                    </TableCell>
                                )}

                                {props.Class.attributes.map((attribute: string, index: number) => (
                                    <TableCell key={index}>
                                        {`${(instance.attributes as any)[attribute]}`}
                                    </TableCell>
                                ))}
                                <TableCell><IconButton color="secondary" onClick={() => selectForEdit(instance)} size="large"><EditIcon/></IconButton></TableCell>
                                <TableCell><IconButton onClick={() => selectForRemove(instance)} size="large"><DeleteIcon color="error"/></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.Class.paginated
                ?(
                    <Pagination
                        className={classes.pagination}
                        count={state.totalPages}
                        color='primary'
                        onChange={(event, newPage) => search(state.searchText, newPage)}
                    />
                )
                : null}
            <Fab color="secondary" className={classes.fab} onClick={() => setState({...state, currentOpenDialog: CurrentOpenDialog.Add})} sx={{position: "absolute"}}>
                <AddIcon />
            </Fab>
            <AddDialog
                Class={props.Class}
                open={state.currentOpenDialog === CurrentOpenDialog.Add}
                onClose={() => setState({...state, currentOpenDialog: CurrentOpenDialog.None})}
                onSubmit={() => refresh(true)}
            />
            {
                state.selectedInstance !== null
                    ? (
                        <div>
                            <EditDialog
                                instance={state.selectedInstance}
                                Class={props.Class}
                                open={state.currentOpenDialog === CurrentOpenDialog.Edit}
                                onClose={() => setState({...state, currentOpenDialog: CurrentOpenDialog.None})}
                                onSubmit={() => refresh(false)} />
                            <RemoveDialog
                                Class={props.Class}
                                instance={state.selectedInstance}
                                open={state.currentOpenDialog === CurrentOpenDialog.Remove}
                                onClose={() => setState({...state, currentOpenDialog: CurrentOpenDialog.None})}
                                onSubmit={() => refresh(false)} />
                        </div>
                    )
                    : null
            }

        </div>
    );
}

export default DataBaseValueOverview