import React, {useContext, useEffect, useState} from 'react';
import Shopping, {Purpose} from "../../components/Shopping/Shopping";
import Consumable from "../../classes/DataBaseValue/Consumable";
import Sale from '../../classes/DataBaseValue/Sale';
import Product from '../../classes/DataBaseValue/Product';
import Inventory from '../../classes/DataBaseValue/Inventory';
import InventoryTransferItem from '../../classes/DataBaseValue/InventoryTransferItem';
import InventoryItem from '../../classes/DataBaseValue/InventoryItem';
import { PaymentType } from '../../classes/DataBaseValue/Transaction';
import Company from '../../classes/DataBaseValue/Company';
import Person from '../../classes/DataBaseValue/Person';
import Party from "../../classes/DataBaseValue/Party";
import GlobalContext from '../../GlobalContext';

/**Sales Component
 * 
 * Component showing the sales. This particular component is responsible for keeping track of the sale object, as well as the inventory, but not the products or customer
 */
export default function Sales(props: any) {

    /* We start with a stub sale object, and progressively add information to it, until it can be sent */
    const [sale, setSale] = useState<Sale>(Sale.makeStub());
    const [inventory, setInventory] = useState<Inventory>(Inventory.makeStub());

    const { load, raiseSuccess } = useContext(GlobalContext);

    /* Effect takes place immediately when component is shown */
    useEffect(() => {
        if(inventory.isStub()) {
            (async function() {
                load(async (loadHook) => {
                    setInventory(await Inventory.Kassa(loadHook))
                }, 'Getting kassa')
            })();
        }
    }, [])

    /* Check if item is in the sale object (so, is in the basket) */
    const itemInSale = (item: Product, amount: number): boolean => {
        const inventoryTransferItemExists = sale.attributes.inventory_transfer.attributes.inventory_transfer_items
            .find(iti => iti.attributes.product.attributes.id === item.attributes.id);
        return !!inventoryTransferItemExists && inventoryTransferItemExists.attributes.amount >= amount
    }

    /* Check if item is in the inventory (so, is in stock) */
    const itemInInventory = (item: Product, amount: number): boolean => {
        const inventoryItemExists = inventory.attributes.inventory_items
            .find(ii => ii.attributes.product_id === item.attributes.id)
        return !!inventoryItemExists && inventoryItemExists.attributes.amount >= amount
    }

    /* Quite a complex function that simply adds/removes the amount of items to/from the sale (so our basket basically). amount can be negative */
    const changeItemInSale = (item: Product, amount: number) => {

        // Get all inventory transfer items (your basket)
        const inventoryTransferItems = sale.attributes.inventory_transfer.attributes.inventory_transfer_items
        // Get the inventory transfer item of the item to be added if already exists
        const inventoryTransferItemExists = inventoryTransferItems.find(iti => iti.attributes.product.attributes.id === item.attributes.id)
        // If it exists, add amount. If it does not exist, create a new one and add amount
        const inventoryTransferItem = inventoryTransferItemExists
            ? inventoryTransferItemExists.merge({amount: inventoryTransferItemExists.attributes.amount + amount})
            : InventoryTransferItem.makeStub().merge({product: item.toRaw(), amount: amount})
        // Get all the other inventory transfer items
        const inventoryTransferItemsWithoutItem = inventoryTransferItems.filter(iti => iti.attributes.product.attributes.id !== item.attributes.id)
        // Add to it the new/modified inventory transfer item, and filter away any products whose amount was decreased, then convert to raw
        const newInventoryTransferItems = [...inventoryTransferItemsWithoutItem, inventoryTransferItem]
            .filter(iti => iti.attributes.amount > 0)
            .map(iti => iti.toRaw())
        // Merge new inventory transfer items with the state
        const newSale = sale.merge({
            inventory_transfer: sale.attributes.inventory_transfer.merge({
                inventory_transfer_items: newInventoryTransferItems
            }).toRaw()
        })
        // Set the state
        setSale(newSale)
    }

    /* Make a nice looking comment to add to the transaction */
    const prettyPrint = () => {
        const buyer = sale.attributes.transaction.attributes.sender.attributes.name
        const inventoryTransferItems = sale.attributes.inventory_transfer.attributes.inventory_transfer_items
            .map(iti => `${iti.attributes.amount}x ${iti.attributes.product}`)
            .join(', ')

        return `${buyer} bought ${inventoryTransferItems}`
    }

    /* Update the inventory when an item from an inventory gets removed when it is placed in the basket. These changes are not reflected in the database (backend handles it for us) */
    const changeItemInInventory = (item: Product, amount: number) => {
        const inventoryItems = inventory.attributes.inventory_items
        const inventoryItemExists = inventoryItems.find(ii => ii.attributes.product_id === item.attributes.id)
        const inventoryItem = inventoryItemExists
            ? inventoryItemExists.merge({amount: inventoryItemExists.attributes.amount + amount})
            : InventoryItem.makeStub().merge({product_id: item.attributes.id, amount: amount})
        const inventoryItemsWithoutItem = inventoryItems.filter(ii => ii.attributes.product_id !== item.attributes.id)
        const newInventoryItems = [...inventoryItemsWithoutItem, inventoryItem]
            .filter(ii => ii.attributes.amount > 0)
            .map(ii => ii.toRaw())
        const newInventory = inventory.merge({
            inventory_items: newInventoryItems
        })
        setInventory(newInventory)
    }

    /* Depending on if we are adding/removing items to/from the basket, we must invoke changeItemInSale and changeItemInInventory differently (with opposing sign)  */
    const changeItemInBasket = (item: Product, amount: number) => {
        if(amount > 0) {
            if(itemInInventory(item, amount)) {
                changeItemInSale(item, amount);
                changeItemInInventory(item, amount * -1)
            }
        } else if(amount < 0) {
            if(itemInSale(item, amount * -1)) {
                changeItemInSale(item, amount);
                changeItemInInventory(item, amount * -1)
            }
        }
    }

    /* onPay callback when either kaching or scribble is clicked */
    const onPay = async (paymentType: PaymentType) => {

        const newSale = sale.merge({
            transaction: sale.attributes.transaction.merge({
                payment_type: paymentType,
                // Amount does not matter. It will be correctly calculated in the backend, but making it 0 isn't well liked by the backend :)
                amount: 1,
                // The receiving end is the kassa, but we should really change the naming convention
                receiver: (await Company.Kassa()).attributes.party.toRaw(),
                comment: prettyPrint()
            }).toRaw(),
            inventory_transfer: sale.attributes.inventory_transfer.merge({
                from_id: inventory.attributes.id,
                to_id: (await Inventory.External()).attributes.id
            }).toRaw()
        })

        await newSale.post()
        setSale(Sale.makeStub())

        raiseSuccess(`Transaction completed`)
    }

    /* When a customer is selected, we add it to the sale. If the given customer is null, the default user is external money (non-member) */
    const onCustomerSelect = async (val: Party | null) => {

        const newSale = sale.merge({
            transaction: sale.attributes.transaction.merge({
                sender: val !== null ? val.toRaw() : (await Company.External()).attributes.party.toRaw()
            }).toRaw()
        })
        setSale(newSale)
    }

    /* When the cancel button is clicked, reset everything. */
    const onCancel = async () => {
        load(async () => {
            setSale(Sale.makeStub())
            setInventory(await Inventory.Kassa())
        })
    }

    /* The component that is doing the actual rendering of everything is Shopping, which is also used for rents */
    return (
        <Shopping 
            ProductClass={Consumable} 
            basket={sale}
            inventory={inventory}
            changeItemInBasket={changeItemInBasket}
            onPay={onPay}
            onCustomerSelect={onCustomerSelect}
            onCancel={onCancel}
        />
    );
}