import {useEffect, useState} from "react";
import Person, {RawPersonAttributes} from "../../classes/DataBaseValue/Person";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import Title from "./Title";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import React from 'react';
import HomeCard from "./HomeCard";
import {DateTime} from "luxon";


export default function NegativeBalances(props: any) {

    const [persons, setPersons] = useState<Array<Person>>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async function() {

            setLoading(true)

            const route = Person.route.addPredicate(new QueryPredicate('with_negative_balance', 'true'))

            setPersons((await Person.get<RawPersonAttributes, Person>(false, route)).records)

            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title='Users with negative balance' loading={loading}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {persons.map((person) => (
                            <TableRow key={person.attributes.id}>
                                <TableCell>{person.fullName()}</TableCell>
                                <TableCell align="right">€{(person.attributes.party.attributes.balance / 100).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography component="p" variant='h5'>
                Please fix this immediately!
            </Typography>
        </HomeCard>
    )
}