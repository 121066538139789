import React from "react"
import DataBaseValueOverview from "../../components/DataBaseValueOverview/DataBaseValueOverview"
import Group from "../../classes/DataBaseValue/Group"

/** Groups Component
 * 
 * Database value overview for groups
 */
export default function Groups(props: any) {
    return <DataBaseValueOverview Class={Group} />
}