import FormBody, {FormProps} from "./FormBody";
import Group from "../../../classes/DataBaseValue/Group";
import React from "react";
import TextField from "./TextField";
import NumericalField from "./NumericalField";
import RightsSelection from "./RightsSelection";
import {NumberValue, StringValue} from "../../../classes/DataBaseValue/BasicValues";
import AbstractValue from "../../../classes/DataBaseValue/AbstractValue";

function GroupForm(props: FormProps<Group>) {

    const {attributes} = props.instance

    const propagate = (key: string, value: AbstractValue) => props.hook(props.instance.merge({[key]: value.toRaw()}))

    return (
        <FormBody>
            <TextField instance={new StringValue(attributes.name)} hook={(update) => propagate("name", update)} name="Name" />
            <NumericalField instance={new NumberValue(attributes.fee)} hook={(update) => propagate("fee", update)} name="Fee" />
            <NumericalField instance={new NumberValue(attributes.validity_time)} hook={(update) => propagate("validity_time", update)} name="Validity Time" />
            <RightsSelection instance={attributes.rights} hook={(update) => propagate("rights", update)} name="Rights" />
        </FormBody>
    )
}

export default GroupForm