import React from 'react';
import {
    ButtonBase,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {BasketType} from "./Shopping";
import makeStyles from '@mui/styles/makeStyles';
import Product from "../../classes/DataBaseValue/Product";
import Sale from '../../classes/DataBaseValue/Sale';
import Party from "../../classes/DataBaseValue/Party";
import Rent from "../../classes/DataBaseValue/Rent";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '70%',
        maxHeight: '70%',
        overflowX: 'hidden',
        position: 'relative',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f1f1f1'
        }
    },
    footer: {
        '& td': {
            backgroundColor: '#fafafa',
            position: 'sticky',
            zIndex: 2,
            bottom: 0,
            left: 0
        },
    },
    hover: {
        cursor: 'pointer'
    }
}));

export default function Basket(props: {customer: Party, basket: BasketType, remove: (product: Product) => void}) {
    switch(props.basket.constructor) {
        case Sale: return <BasketForSale {...props} sale={props.basket as Sale} />
        case Rent: return <BasketForRent {...props} rent={props.basket as Rent} />
        default: return null
    }
}

function BasketForRent(props: {customer: Party, rent: Rent, remove: (product: Product) => void}) {
    const classes = useStyles();

    const item = props.rent.attributes.product;

    const price = item.calculatePrice(props.customer)

    return (
        <TableContainer>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Warranty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow onClick={() => props.remove(item)}>
                        <TableCell>{item.attributes.name}</TableCell>
                        <TableCell>{price ? `€${price}` : '-'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function BasketForSale(props: {customer: Party, sale: Sale, remove: (product: Product) => void}) {

    const classes = useStyles();

    const items = props.sale.attributes.inventory_transfer.attributes.inventory_transfer_items

    const totalPrice: string | null = items.reduce((acc: string | null, val) => {
        const rawPrice = val.attributes.product.calculatePrice(props.customer)

        if(rawPrice) {
            const price = parseFloat(rawPrice)

            if(acc) {
                const parsedAcc = parseFloat(acc)

                return (parsedAcc + (price * val.attributes.amount)).toFixed(2)
            } else {
                return acc
            }
        } else {
            return null;
        }
    }, "0")

    return (
        <TableContainer component={Paper} className={classes.root} key={-1}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items.map((iti, index) => {

                            const name = iti.attributes.product.attributes.name
                            const conciseName = name.length > 20 ? `${iti.attributes.product.attributes.name.slice(0, 20)}...` : name

                            const price = iti.attributes.product.calculatePrice(props.customer)

                            return (
                                <TableRow hover={true} onClick={() => props.remove(iti.attributes.product)}
                                          classes={{hover: classes.hover}} key={index}>
                                    <TableCell>{conciseName}</TableCell>
                                    <TableCell>{iti.attributes.amount}</TableCell>
                                    <TableCell>{price ? `€${price}` : '-'}</TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
                <TableFooter className={classes.footer}>
                    <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell>{items.reduce((acc, val) => acc + val.attributes.amount, 0)}</TableCell>
                        <TableCell>{totalPrice ? `€${totalPrice}` : '-'}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}