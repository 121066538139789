import React, {useEffect, useState} from "react";
import Consumable from "../../classes/DataBaseValue/Consumable";
import QueryPredicate from "../../classes/Route/QueryPredicate";
import {DateTime} from "luxon";
import Product, {RawProductAttributes} from "../../classes/DataBaseValue/Product";
import HomeCard from "./HomeCard";
import Title from "./Title";
import Category from "../../classes/DataBaseValue/Category";
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import Party, {RawPartyAttributes} from "../../classes/DataBaseValue/Party";

export default function MostPopularProduct(props: {category: string}) {

    const [product, setProduct] = useState<{product: Product | null, buyers: Array<Party>}>({product: null, buyers: []})

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async function() {

            setLoading(true)

            const category = await Category.getOne(Category.route.addRoute(props.category))

            const route = Consumable.route
                .addPredicate(new QueryPredicate('from_category', category.attributes.id))
                .addPredicate(new QueryPredicate('with_popularity_between[lower]', DateTime.now().minus({months: 1}).toISODate()))
                .addPredicate(new QueryPredicate('with_popularity_between[upper]', DateTime.now().plus({days: 1}).toISODate()))

            const product: Consumable = (await Consumable.get(0, route)).records[0] as Consumable

            if(product) {
                const buyers = (await Party.get<RawPartyAttributes, Party>(false, Product.route.addRoute(product.attributes.id).addRoute('most_popular_buyers'))).records

                setProduct({product, buyers})
            }

            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title={`Most popular ${props.category === 'snack' || props.category === 'food' ? props.category.toLowerCase() : `${props.category.humanize().toLowerCase()} product`}`} loading={loading}>
            <Typography component="p" variant='h5'>
                {product.product?.attributes.name ?? 'None'}
            </Typography>
            <Typography component="p" variant="body2">
                Top Buyers
            </Typography>
            <Table>
                <TableBody>
                    {product.buyers.slice(0, 3).map((party, index) => (
                        <TableRow key={party.attributes.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{party.attributes.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </HomeCard>
    )
}