import React, {useEffect, useState} from "react";
import Sale from "../../classes/DataBaseValue/Sale";
import {DateTime, DateTimeFormatOptions} from "luxon";
import { Paper, Theme, Typography, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {LineChart, XAxis, YAxis, Line, CartesianGrid, Label} from 'recharts';
import {dateTimeRange} from "../../index";
import HomeCard from "./HomeCard";

export default function SaleStats(props: any) {

    const theme = useTheme();

    const [statData, setStatData] = useState<Array<{date: string, amount: number}>>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async function() {

            setLoading(true)

            const pastMonth = DateTime.now().minus({months: 1})
            const today = DateTime.now().plus({days: 1})

            const dailySales = await Sale.getDailyStats(pastMonth, today)
            const range = dateTimeRange(pastMonth, today)

            const data = range.map(d => ({
                date: d.toISODate(),
                amount: dailySales[d.toISODate()]
                    ? dailySales[d.toISODate()]
                        .reduce((amount, sale) => amount + sale.attributes.inventory_transfer.attributes.inventory_transfer_items
                            .reduce((amount, iti) => amount + iti.attributes.amount, 0), 0)
                    : 0
            }))

            setStatData(data)

            setLoading(false)
        })()
    }, [])

    return (
        <HomeCard title='Monthly sales' loading={loading}>
            <LineChart data={statData} height={240} width={800} margin={{
                top: 16,
                right: 100,
                bottom: 0,
                left: 24,
            }} >
                <XAxis dataKey="date" tick={{ fontFamily: 'sans-serif' }} />
                <YAxis tick={{ fontFamily: 'sans-serif' }} >
                    <Label
                        angle={270}
                        position="left"
                        style={{
                            textAnchor: 'middle',
                            fill: theme.palette.text.primary,
                            ...theme.typography.body1,
                        }}
                    >
                        Product count
                    </Label>
                </YAxis>
                <Line
                    type='monotone'
                    dataKey="amount"
                    stroke={theme.palette.primary.main}
                    dot={false}
                />
            </LineChart>
        </HomeCard>
    )
}