import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Right, {RawRightAttributes} from "./Right";

class ProductRightsAssociation extends DataBaseValue {

    public attributes: ProductRightsAssociationAttributes;


    constructor(params: RawProductRightsAssociationAttributes) {
        super(params);
        this.attributes = {
            ...params,
            right: new Right(params.right),
            product_id: params.product_id
        }
    }

    public static makeStub(): ProductRightsAssociation {
        return new ProductRightsAssociation({
            right: Right.makeStub().toRaw(),
            product_id: "",
            sellable: false,
            rentable: false,
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawProductRightsAssociationAttributes {
        return {
            right: this.attributes.right.toRaw(),
            product_id: this.attributes.product_id,
            sellable: this.attributes.sellable,
            rentable: this.attributes.rentable,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawProductRightsAssociationAttributes): ProductRightsAssociation {
        return new ProductRightsAssociation({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            right_id: this.attributes.right.attributes.id,
            sellable: this.attributes.sellable,
            rentable: this.attributes.rentable,
            id: this.attributes.id ? this.attributes.id : undefined,
            _destroy: this.attributes.discarded_at !== null
        }
    }
}

export interface ProductRightsAssociationAttributes extends DataBaseValueAttributes {
    right: Right,
    product_id: string,
    sellable: boolean,
    rentable: boolean
}

export interface RawProductRightsAssociationAttributes extends RawDataBaseValueAttributes {
    right: RawRightAttributes,
    product_id: string,
    sellable: boolean
    rentable: boolean
}

export interface PartialRawProductRightsAssociationAttributes extends PartialRawDataBaseValueAttributes {
    right?: RawRightAttributes,
    product_id?: string,
    sellable?: boolean
    rentable?: boolean
}

export default ProductRightsAssociation