import {CrudValue} from "./CrudValue";
import {DataBaseValueAttributes, PartialRawDataBaseValueAttributes, RawDataBaseValueAttributes} from "./DataBaseValue";
import Party, {RawPartyAttributes} from "./Party";
import {Jsonable} from "./Form";
import React from "react";
import Content from "../../components/DataBaseValueOverview/TableCellContent/Content";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import CompanyForm from "../../components/DataBaseValueOverview/FormBody/CompanyForm";
import Route from "../Route/Route";

class Company extends CrudValue {

    public attributes: CompanyAttributes;

    public static route = new Route(['companies']);
    public static paginated = true;
    public static asString = 'companies';

    public static attributeNames: Array<string> = ["Name", "Phone", "Bill Number", "Balance", "Groups"]

    attributesToTable(): Array<{ Component: React.FunctionComponent<{ content: Content }>; content: Content }> {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: BasicContent, content: this.attributes.phone},
            {Component: BasicContent, content: this.attributes.bill_number},
            {Component: BasicContent, content: this.attributes.party.attributes.balance},
            {Component: ArrayContent, content: this.attributes.party.attributes.party_groups_associations.party_groups_associations.map(g => g.attributes.group.attributes.name)}
        ];
    }

    public static form = CompanyForm

    public toString() {
        return this.attributes.name;
    }

    public toRaw(): RawCompanyAttributes {
        return {
            name: this.attributes.name,
            phone: this.attributes.phone,
            bill_number: this.attributes.bill_number,
            party: this.attributes.party.toRaw(),
            ...super.toRaw()
        }
    }

    merge(toMerge: PartialRawCompanyAttributes): Company {
        return new Company({...this.toRaw(), ...toMerge});
    }

    serializeToJson(): Jsonable {
        return {
            company: {
                name: this.attributes.name,
                phone: this.attributes.phone,
                bill_number: this.attributes.bill_number,
                party_attributes: this.attributes.party.serializeToJson()
            }
        };
    }

    public static async Kassa(): Promise<Company> {
        return await Company.getOne(Company.route.addRoute('kassa'))
    }

    public static async External(): Promise<Company> {
        return await Company.getOne(Company.route.addRoute('external'))
    }

    public static makeStub(): Company {
        return new Company({
            name: "",
            phone: "",
            bill_number: "",
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            party: Party.makeStub().toRaw()
        })
    }

    constructor(params: RawCompanyAttributes) {
        super(params);

        this.attributes = {
            ...params,
            party: new Party(params.party)
        }
    }

}

export interface RawCompanyAttributes extends RawDataBaseValueAttributes{
    name: string,
    phone: string,
    bill_number: string,
    party: RawPartyAttributes
}

export interface PartialRawCompanyAttributes extends PartialRawDataBaseValueAttributes {
    name?: string,
    phone?: string,
    bill_number?: string,
    party?: RawPartyAttributes
}

export interface CompanyAttributes extends DataBaseValueAttributes {
    name: string,
    phone: string,
    bill_number: string,
    party: Party
}

export default Company