import {CrudValue} from "./CrudValue";
import {DataBaseValueAttributes, PartialRawDataBaseValueAttributes, RawDataBaseValueAttributes} from "./DataBaseValue";
import Inventory, {RawInventoryAttributes} from "./Inventory";
import Product, {RawProductAttributes} from "./Product";
import Route from "../Route/Route";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import Consumable from "./Consumable";
import ProductFactory from "./ProductFactory";

class InventoryItem extends CrudValue {
    public attributes: InventoryItemAttributes;

    public static route = new Route(['inventory_items']);
    public static paginated = true;
    public static asString = 'inventory_items';

    public static attributeNames: Array<string> = ["Inventory", "Product", "Amount"];

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.inventory_id},
            {Component: BasicContent, content: this.attributes.product_id},
            {Component: BasicContent, content: this.attributes.amount}
        ]
    }

    public toString() {
        return `${this.attributes.product_id} x${this.attributes.amount}`
    }

    constructor(params: RawInventoryItemAttributes) {
        super(params);

        this.attributes = {
            ...params,
            inventory_id: params.inventory_id,
            product_id: params.product_id,
            amount: params.amount
        }
    }

    public static makeStub(): InventoryItem {
        return new InventoryItem({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            inventory_id: "",
            product_id: "",
            amount: NaN
        })
    }

    public toRaw(): RawInventoryItemAttributes {
        return {
            inventory_id: this.attributes.inventory_id,
            product_id: this.attributes.product_id,
            amount: this.attributes.amount,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawInventoryItemAttributes): InventoryItem {
        return new InventoryItem({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            inventory_item: {
                inventory_id: this.attributes.inventory_id,
                product_id: this.attributes.product_id,
                amount: this.attributes.amount
            }
        }
    }
}

export interface InventoryItemAttributes extends DataBaseValueAttributes {
    inventory_id: string,
    product_id: string,
    amount: number
}

export interface RawInventoryItemAttributes extends RawDataBaseValueAttributes {
    inventory_id: string,
    product_id: string,
    amount: number
}

export interface PartialRawInventoryItemAttributes extends PartialRawDataBaseValueAttributes {
    inventory_id?: string,
    product_id?: string,
    amount?: number
}

export default InventoryItem