import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Group, {RawGroupAttributes} from "./Group";

class PartyGroupsAssociation extends DataBaseValue {

    public attributes: PartyGroupsAssociationAttributes;

    constructor(params: RawPartyGroupsAssociationAttributes) {
        super(params);

        this.attributes = {
            ...params,
            group: new Group(params.group)
        }
    }

    public static makeStub(): PartyGroupsAssociation {
        return new PartyGroupsAssociation({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            group: Group.makeStub().toRaw()
        })
    }

    public toRaw(): RawPartyGroupsAssociationAttributes {
        return {
            group: this.attributes.group.toRaw(),
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawPartyGroupsAssociationAttributes): PartyGroupsAssociation {
        return new PartyGroupsAssociation({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            group_id: this.attributes.group.attributes.id,
            id: this.attributes.id,
            _destroy: this.attributes.discarded_at !== null
        }
    }

}

export default PartyGroupsAssociation

export interface PartyGroupsAssociationAttributes extends DataBaseValueAttributes {
    group: Group
}

export interface RawPartyGroupsAssociationAttributes extends RawDataBaseValueAttributes {
    group: RawGroupAttributes
}

export interface PartialRawPartyGroupsAssociationAttributes extends PartialRawDataBaseValueAttributes {
    group?: RawGroupAttributes
}