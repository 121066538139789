import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Party, {PartyAttributes, RawPartyAttributes} from "./Party";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import {CrudValue} from "./CrudValue";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import config from "../../config";
import axios from "axios";

export type PaymentType = 'Cash' | 'Account'

class Transaction extends CrudValue{

    public attributes: TransactionAttributes

    public static route = new Route(['transactions']);
    public static paginated = true;
    public static asString = 'transaction';

    public static attributeNames: Array<string> = ["Sender", "Receiver", "Amount", "Payment Type", "Comment"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.sender.attributes.id},
            {Component: BasicContent, content: this.attributes.receiver.attributes.id},
            {Component: BasicContent, content: this.attributes.amount},
            {Component: BasicContent, content: this.attributes.payment_type},
            {Component: BasicContent, content: this.attributes.comment}
        ]
    }

    //public PUTBody: HTTPPutBody = new HTTPPutBody(this, {})

    public toString() {
        return ""
    }

    constructor(params: RawTransactionAttributes) {
        super(params);
        this.attributes = {
            ...params,
            sender: new Party(params.sender),
            receiver: new Party(params.receiver)
        }
    }

    public toRaw(): RawTransactionAttributes {
        return {
            payment_type: this.attributes.payment_type,
            comment: this.attributes.comment,
            amount: this.attributes.amount,
            sender: this.attributes.sender.toRaw(),
            receiver: this.attributes.receiver.toRaw(),
            ...super.toRaw()
        }
    }

    public static makeStub(): Transaction {
        return new Transaction({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            payment_type: 'Account',
            comment: "",
            amount: NaN,
            sender: Party.makeStub().toRaw(),
            receiver: Party.makeStub().toRaw()
        })
    }

    public merge(toMerge: PartialRawTransactionAttributes): Transaction {
        return new Transaction({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            transaction: {
                payment_type: this.attributes.payment_type,
                comment: this.attributes.comment,
                amount: this.attributes.amount,
                sender_id: this.attributes.sender.attributes.id,
                receiver_id: this.attributes.receiver.attributes.id
            }
        }
    }

    public static combine(transactions: Array<Transaction>) {

        console.assert(transactions.length > 0)

        const {payment_type, comment, amount, receiver} = transactions[0].attributes
        const senders = transactions.map(t => t.attributes.sender)

        return {
            transaction: {
                payment_type,
                comment,
                amount,
                sender_id: senders.map(s => s.attributes.id),
                receiver_id: receiver.attributes.id
            }
        }
    }

    public multiSenders(senders: Array<Party>) {
        return {
            transaction: {
                payment_type: this.attributes.payment_type,
                comment: this.attributes.comment,
                amount: this.attributes.amount,
                sender_id: senders.map(s => s.attributes.id),
                receiver_id: this.attributes.receiver.attributes.id
            }
        }
    }

    public async multiSenderPost(senders: Array<Party>) {
        return await axios({
            method: 'post',
            url: `${config.api}${Transaction.route.toString()}`,
            data: this.multiSenders(senders),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
    }
}

export interface TransactionAttributes extends DataBaseValueAttributes {
    payment_type: PaymentType,
    comment: string,
    amount: number,
    sender: Party,
    receiver: Party
}

export interface RawTransactionAttributes extends RawDataBaseValueAttributes{
    payment_type: PaymentType,
    comment: string,
    amount: number,
    sender: RawPartyAttributes,
    receiver: RawPartyAttributes
}

export interface PartialRawTransactionAttributes extends PartialRawDataBaseValueAttributes{
    payment_type?: PaymentType,
    comment?: string,
    amount?: number,
    sender?: RawPartyAttributes,
    receiver?: RawPartyAttributes
}

export default Transaction;