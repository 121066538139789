import Consumable from "./Consumable";
import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Product, {RawProductAttributes} from "./Product";
import ProductFactory from "./ProductFactory";
import {CrudValue} from "./CrudValue";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";

class InventoryTransferItem extends CrudValue {
    public attributes: InventoryTransferItemAttributes;

    public static asString = "inventory_transfer_item";

    constructor(params: RawInventoryTransferItemAttributes) {
        super(params);

        this.attributes = {
            ...params,
            product: ProductFactory.create(params.product),
            amount: params.amount
        }
    }

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.product.attributes.name},
            {Component: BasicContent, content: this.attributes.amount}
        ]
    }

    public static makeStub(): InventoryTransferItem {
        return new InventoryTransferItem({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            product: Consumable.makeStub().toRaw(),
            amount: 0
        })
    }

    public toRaw(): RawInventoryTransferItemAttributes {
        return {
            product: this.attributes.product.toRaw(),
            amount: this.attributes.amount,
            ...super.toRaw()
        }
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            product_id: this.attributes.product.attributes.id,
            amount: this.attributes.amount
        }
    }

    public merge(toMerge: PartialRawInventoryTransferItemAttributes): InventoryTransferItem {
        return new InventoryTransferItem({...this.toRaw(), ...toMerge})
    }
}

export interface InventoryTransferItemAttributes extends DataBaseValueAttributes {
    product: Product,
    amount: number
}

export interface RawInventoryTransferItemAttributes extends RawDataBaseValueAttributes {
    product: RawProductAttributes,
    amount: number
}

export interface PartialRawInventoryTransferItemAttributes extends PartialRawDataBaseValueAttributes {
    product?: RawProductAttributes,
    amount?: number
}

export default InventoryTransferItem