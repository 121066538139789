import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CloseButtonWrapper = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1, 1),
    marginRight: 15,
    right: 0
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: "100%",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
}));

export default function SearchBar(props: {value: string, onChange, onRequestSearch}) {

  function onInputChange(event) {
    props.onChange(event.target.value)
  }

  function onKeyDown(event) {
    if (event.key == "Enter") {
      props.onRequestSearch()
    } else if (event.key == "Escape") {
      props.onChange("")
    }
  }

  function onClickClose(event) {
    props.onChange("")
  }

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        value={props.value}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        inputProps={{ 'aria-label': 'search' }}
      />
      <CloseButtonWrapper onClick={onClickClose}>
        <CloseIcon />
      </CloseButtonWrapper>
    </Search>
  );
}
