import DataBaseValue, {LoadHook, RawDataBaseValueAttributes} from "./DataBaseValue";
import axios from "axios";
import config from "../../config";
import Route from "../Route/Route";
import {GETReturns, RawGETReturns, RawRecords, Records, Totals} from "../types";
import QueryPredicate from "../Route/QueryPredicate";
import Content from "../../components/DataBaseValueOverview/TableCellContent/Content";
import React from "react";

export abstract class CrudValue extends DataBaseValue {

    /*public static POSTBody: HTTPPostBody;
    abstract PUTBody: HTTPPutBody;*/

    constructor(props: RawDataBaseValueAttributes) {
        super(props);
    }

    public static addPredicates(route: Route, queryPredicates: Array<QueryPredicate>): Route {
        return queryPredicates.reduce((acc, val) => acc.addPredicate(val), route)
    }

    public static async getOne<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(route: Route = this.route, loadHook?: LoadHook): Promise<V> {
        const response = await this.GETRequest(route, loadHook);
        if(!response.hasOwnProperty("records") && !response.hasOwnProperty("totals")) {
            const Class: new (params: C) => V = (this as any) as new (params: C) => V;
            return new Class(response as C);
        } else throw new Error(`Returned value was not a single record`)
    }

    public static async get<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(page: number | false = 0, route: Route = this.route, loadHook?: LoadHook): Promise<GETReturns<V>> {

        const response = await this.GETRequest<C>(route.addPredicate(new QueryPredicate("page", page.toString())), loadHook);
        const Class: new (params: C) => V = (this as any) as new (params: C) => V;


        if(response.hasOwnProperty("records") && response.hasOwnProperty("totals")) {
            const records = (response as { totals: Totals, records: RawRecords<C> }).records.map((json: C) => new Class(json));
            const totals: Totals = (response as { totals: Totals, records: RawRecords<C> }).totals

            return {totals, records}
        } else if(Array.isArray(response)) {
            const records = response.map((json: C) => new Class(json))
            return {totals: {records: records.length, pages: 1}, records}
        } else throw new Error(`Returned value was not an array of records`)
    }

    public static async getTotals<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(route: Route = this.route, loadHook?: LoadHook): Promise<Totals> {

        const response = await this.GETRequest(route, loadHook)

        if(response.hasOwnProperty("records") && response.hasOwnProperty("totals")) {
            return (response as {records: RawRecords<C>, totals: Totals}).totals
        } else if(Array.isArray(response)) {
            return {records: response.length, pages: 1}
        } else throw new Error(`No totals provided in route fetch`)
    }

    public static async search<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(predicate: string, page: number = 0, route: Route = this.route): Promise<GETReturns<V>> {
        return await this.get(page, route.addPredicate(new QueryPredicate("search", predicate)));
    }

    public static async getTotalsFromSearch<C extends RawDataBaseValueAttributes, V extends DataBaseValue>(predicate: string, route: Route = this.route): Promise<Totals> {
        return await this.getTotals(route.addPredicate(new QueryPredicate("search", predicate)))
    }

    public async post(route: Route = (this.constructor as any).route as Route) {

        const serialized = this.serialize();

        return await this.POSTRequest({
            route: route,
            data: serialized,
            headers: {
                'Content-Type': this.containsFile() ? 'multipart/form-data' : 'application/json'
            }
        })
    }

    public async put(route: Route = (this.constructor as any).route as Route, keepRoute: boolean = false, data = this.serialize()) {

        return await this.PUTRequest({
            route: route,
            keepRoute: keepRoute,
            data: data,
            headers: {
                'Content-Type': this.containsFile() ? 'multipart/form-data' : 'application/json'
            }
        });
    }

    public async delete() {
        const route = (this.constructor as any).route as Route;
        return await this.DELETERequest({route: route})
    }

    abstract attributesToTable(): Array<{Component: React.FunctionComponent<{content: Content}>, content: Content}>
}