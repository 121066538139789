import Product, {PartialRawProductAttributes, ProductAttributes, RawProductAttributes} from "./Product";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import {ProductKind} from "../types";
import Category from "./Category";
import ProductRightsAssociations from "./ProductRightsAssociations";
import ProductGroupsAssociations from "./ProductGroupsAssociations";
import ScanTags from "./ScanTags";
import HardwareForm from "../../components/DataBaseValueOverview/FormBody/HardwareForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";

class Hardware extends Product {

    public attributes: HardwareAttributes

    public static kind: ProductKind = "hardware";
    public static asString = "hardware";
    public static paginated = true;
    public static route = new Route(['products']).addPredicate(new QueryPredicate("of_kind", Hardware.kind));

    public static attributeNames: Array<string> = ["Name", "Brand", "Description", "Rental period (days)", "Extension period (days)", "Rights", "Scan Tags"];

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: BasicContent, content: this.attributes.hardware.brand},
            {Component: BasicContent, content: this.attributes.hardware.description},
            {Component: BasicContent, content: this.attributes.rental_period},
            {Component: BasicContent, content: this.attributes.extension_period},
            {Component: ArrayContent, content: this.attributes.product_rights_associations.product_rights_associations.map(pra => pra.attributes.right.attributes.name)},
            {Component: ArrayContent, content: this.attributes.scan_tags.scan_tags.map(st => st.attributes.tag)},
        ]
    }

    /*public static POSTBody = new HTTPPostBody(Hardware, {
        product: new HTTPFormStructure({
            name: new TextField(),
            photo: new FileUpload("", {required: false}),
            category: new CategorySelection(Hardware.kind),
            hardware_attributes: new HTTPFormStructure({
                brand: new TextField(),
                description: new TextField()
            }),
            product_rights_associations_attributes: new ProductRightsAssociationsForm(false, true)
        })
    })
    public PUTBody: HTTPPutBody;*/

    public toString() {
        return `${this.attributes.name} by ${this.attributes.hardware.brand}`
    }

    public static makeStub(): Hardware {
        return new Hardware({
            hardware: {id: "", brand: "", description: ""},
            category: {
                name: "",
                for_kind: "hardware",
                id: "",
                created_at: null,
                updated_at: null,
                discarded_at: null
            },
            created_at: null,
            updated_at: null,
            discarded_at: null,
            id: "",
            kind: "hardware",
            name: "",
            photo: "",
            rental_period: 21,
            extension_period: 14,
            product_groups_associations: [],
            product_rights_associations: [],
            scan_tags: []

        })
    }

    public toRaw(): RawHardwareAttributes {
        return {
            ...super.toRaw(),
            hardware: this.attributes.hardware,
            category: this.attributes.category.toRaw(),
            kind: this.attributes.kind,
            name: this.attributes.name,
            photo: this.attributes.photo,
            product_groups_associations: this.attributes.product_groups_associations.toRaw(),
            product_rights_associations: this.attributes.product_rights_associations.toRaw(),
        }
    }

    public merge(toMerge: PartialRawHardwareAttributes): Hardware {
        return new Hardware({...this.toRaw(), ...toMerge} as RawHardwareAttributes);
    }

    public static form = HardwareForm

    public serializeToJson(): { [p: string]: any } {
        return {
            product: {
                ...super.serializeToJson(),
                hardware_attributes: {
                    description: this.attributes.hardware.description,
                    brand: this.attributes.hardware.brand,
                    id: this.attributes.hardware.id ? this.attributes.hardware.id : undefined
                }
            }
        }
    }

    constructor(params: RawHardwareAttributes) {
        super(params)
        this.attributes = {
            ...params,
            category: new Category(params.category),
            product_rights_associations: new ProductRightsAssociations(params.product_rights_associations),
            product_groups_associations: new ProductGroupsAssociations(params.product_groups_associations),
            scan_tags: new ScanTags(params.scan_tags)
        }
        /*this.PUTBody = new HTTPPutBody(this, {
            product: new HTTPFormStructure({
                name: new TextField(this.name),
                photo: new FileUpload("", {required: false}),
                category: new CategorySelection(Hardware.kind, this.category.id),
                hardware_attributes: new HTTPFormStructure({
                    brand: new TextField(this.brand),
                    description: new TextField(this.description)
                }),
                product_rights_associations_attributes: new ProductRightsAssociationsForm(false, true, Object.fromEntries(this.product_rights_associations.map(pra => [pra.id, new ProductRightsAssociationsNameDisplay(pra)])))
            })
        })*/
    }
}

export interface HardwareAttributes extends ProductAttributes {
    hardware: {
        brand: string,
        description: string,
        id: string
    }
}

export interface RawHardwareAttributes extends RawProductAttributes {
    hardware: {
        brand: string,
        description: string,
        id: string
    }
}

export interface PartialRawHardwareAttributes extends PartialRawProductAttributes {
    hardware?: {
        brand?: string,
        description?: string,
        id?: string
    }
}

export default Hardware;