import AddEditDialog, {Purpose} from "./AddEditDialog";
import DataBaseValue, {RawDataBaseValueAttributes} from "../../classes/DataBaseValue/DataBaseValue";
import React, {useContext, useEffect, useState} from "react";
import {CrudValue} from "../../classes/DataBaseValue/CrudValue";
import GlobalContext from "../../GlobalContext";

export default function AddDialog<T extends CrudValue>(props: {Class: new (params: RawDataBaseValueAttributes) => T, open: boolean, onClose: () => void, onSubmit: () => void}) {

    const [state, setState] = useState<T>((props.Class as any).makeStub())

    const { raiseSuccess } = useContext(GlobalContext);

    const submit = async () => {

        await state.post();
        raiseSuccess(`${(props.Class as any).asString.capitalizeFirst()} added`);
        props.onSubmit();

        /*await state.post();
        gActions.raiseSuccess(`${props.Class.asString.charAt(0).toUpperCase() + props.Class.asString.slice(1)} added`);
        props.onSubmit();*/
    }

    useEffect(() => {
        setState((props.Class as any).makeStub())
    }, [props.open])

    return (
        <AddEditDialog
            Class={props.Class}
            instance={state}
            hook={(update) => setState(update as T)}
            open={props.open}
            onClose={props.onClose}
            onSubmit={submit}
            purpose={Purpose.Add}
        />
    )
}