import Product, {PartialRawProductAttributes, ProductAttributes, RawProductAttributes} from "./Product";
import Route from "../Route/Route";
import QueryPredicate from "../Route/QueryPredicate";
import {ProductKind} from "../types";
import Category from "./Category";
import ProductRightsAssociations from "./ProductRightsAssociations";
import ProductGroupsAssociations from "./ProductGroupsAssociations";
import ScanTags from "./ScanTags";
import BookForm from "../../components/DataBaseValueOverview/FormBody/BookForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import ArrayContent from "../../components/DataBaseValueOverview/TableCellContent/ArrayContent";
import React from "react";
import Content from "../../components/DataBaseValueOverview/TableCellContent/Content";

class Book extends Product {

    public attributes: BookAttributes

    public static kind: ProductKind = "book";
    public static asString = "book";
    public static paginated = true;
    public static route = new Route(['products']).addPredicate(new QueryPredicate("of_kind", Book.kind));

    public static attributeNames: Array<string> = ["Name", "Author", "Year", "Rental period (days)", "Extension period (days)", "Rights", "Scan Tags"]

    public attributesToTable(): Array<{Component: React.FunctionComponent<{content: Content}>, content: Content}> {
        return [
            {Component: BasicContent, content: this.attributes.name},
            {Component: BasicContent, content: this.attributes.book.author},
            {Component: BasicContent, content: this.attributes.book.year},
            {Component: BasicContent, content: this.attributes.rental_period},
            {Component: BasicContent, content: this.attributes.extension_period},
            {Component: ArrayContent, content: this.attributes.product_rights_associations.product_rights_associations.map(pra => pra.attributes.right.attributes.name)},
            {Component: ArrayContent, content: this.attributes.scan_tags.scan_tags.map(st => st.attributes.tag)},
            ]
    }

    public toString() {
        return `${this.attributes.name} by ${this.attributes.book.author}`
    }

    public static makeStub(): Book {
        return new Book({
            book: {author: "", id: "", year: NaN},
            category: Category.makeStub().toRaw(),
            created_at: null,
            discarded_at: null,
            rental_period: 21,
            extension_period: 14,
            id: "",
            kind: "book",
            name: "",
            photo: "",
            product_groups_associations: [],
            product_rights_associations: [],
            scan_tags: [],
            updated_at: null

        })
    }

    public toRaw(): RawBookAttributes {
        return {
            book: this.attributes.book,
            ...super.toRaw()
        }
    }

    public merge(toMerge: PartialRawBookAttributes): Book {
        return new Book({...this.toRaw(), ...toMerge} as RawBookAttributes);
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            product: {
                ...super.serializeToJson(),
                book_attributes: {
                    author: this.attributes.book.author,
                    year: this.attributes.book.year,
                    id: this.attributes.book.id ? this.attributes.book.id : undefined
                }
            }
        }
    }

    public static form = BookForm;

    constructor(params: RawBookAttributes) {
        super(params);
        this.attributes = {
            ...params,
            book: params.book,
            category: new Category(params.category),
            product_rights_associations: new ProductRightsAssociations(params.product_rights_associations),
            product_groups_associations: new ProductGroupsAssociations(params.product_groups_associations),
            scan_tags: new ScanTags(params.scan_tags)
        };
    }
}

export interface BookAttributes extends ProductAttributes {
    book: {
        author: string,
        year: number,
        id: string
    }
}

export interface RawBookAttributes extends RawProductAttributes {
    book: {
        author: string,
        year: number,
        id: string
    }
}

export interface PartialRawBookAttributes extends PartialRawProductAttributes {
    book?: {
        author?: string,
        year?: number,
        id?: string
    }
}

export default Book;