import { CrudValue } from "./CrudValue";
import DataBaseValue, {
    DataBaseValueAttributes,
    PartialRawDataBaseValueAttributes,
    RawDataBaseValueAttributes
} from "./DataBaseValue";
import Right, {RawRightAttributes} from "./Right";
import Route from "../Route/Route";
import Rights from "./Rights";
import GroupForm from "../../components/DataBaseValueOverview/FormBody/GroupForm";
import BasicContent from "../../components/DataBaseValueOverview/TableCellContent/BasicContent";
import Content from "../../components/DataBaseValueOverview/TableCellContent/Content";
import React from "react";

class Group extends CrudValue {

    public attributes: GroupAttributes;

    public static route = new Route(['groups']);
    public static paginated = false;
    public static asString = 'group';

    public static attributeNames: Array<string> = ["name"]

    public attributesToTable() {
        return [
            {Component: BasicContent, content: this.attributes.name}
        ]
    }

    public toString() {
        return this.attributes.name
    }

    public static makeStub(): Group {
        return new Group({
            name: "",
            fee: NaN,
            validity_time: NaN,
            rights: [],
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null
        })
    }

    public toRaw(): RawGroupAttributes {
        return {
            name: this.attributes.name,
            fee: this.attributes.fee,
            validity_time: this.attributes.validity_time,
            rights: this.attributes.rights.toRaw(),
            ...super.toRaw()
        };
    }

    public merge(toMerge: PartialRawGroupAttributes): Group {
        return new Group({...this.toRaw(), ...toMerge});
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            group: {
                name: this.attributes.name,
                fee: this.attributes.fee,
                validity_time: this.attributes.validity_time,
                group_rights_associations_attributes: this.attributes.rights.rights.map(r => ({
                    right_id: r.attributes.id,
                    _destroy: r.attributes.discarded_at !== null
                }))
            }
        }
    }

    constructor(params: RawGroupAttributes) {
        super(params);

        this.attributes = {
            ...params,
            // Possibility that validity_time is null (legacy stuff)
            validity_time: params.validity_time === null ? NaN : params.validity_time,
            rights: new Rights(params.rights)
        }

        /*this.PUTBody = new HTTPPutBody(this, {
            group: new HTTPFormStructure({
                name: new TextField(this.name),
                fee: new PriceField(this.fee),
                validity_time: new NumericalField(this.validity_time, {required: false}),
                group_rights_associations_attributes: new GroupRightsAssociationsForm(Object.fromEntries(this.rights.map(right => [right.attributes.id, new NameDisplay<Right>(right)])))
            })
        })*/
    }

    public static form = GroupForm

}

export interface GroupAttributes extends DataBaseValueAttributes {
    name: string,
    fee: number,
    validity_time: number,
    rights: Rights
}

export interface RawGroupAttributes extends RawDataBaseValueAttributes {
    name: string,
    fee: number,
    validity_time: number,
    rights: Array<RawRightAttributes>
}

export interface PartialRawGroupAttributes extends PartialRawDataBaseValueAttributes {
    name?: string,
    fee?: number,
    validity_time?: number,
    rights?: Array<RawRightAttributes>
}

export default Group