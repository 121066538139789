import React, {useEffect, useState} from 'react';
import Book from "../../classes/DataBaseValue/Book";
import { Tab, Tabs, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {HashRouter, Link, Route} from "react-router-dom";
import RentProducts from "./RentProduct";
import Hardware from "../../classes/DataBaseValue/Hardware";
import RentsDisplay from "./RentsDisplay";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    tabs: {
        height: '6%'
    },
    route: {
        height: '94%'
    }
}));

/**Rents Component
 * 
 * Main component for rents that cover the selling of books and hardware, and the managing of active rents, inactive rents, and overtime rents.
 */
export default function Rents(props: any) {

    const classes = useStyles();

    /* The first selected tab is whichever tab is found in the URL */
    const [selected, setSelected] = useState<string>(window.location.hash.substring(8))
    /* The different tabs */
    const selectedIndices = ['books', 'hardware', 'active', 'inactive', 'overtime'];

    return (
        <HashRouter>
            <Tabs className={classes.tabs} indicatorColor="secondary" value={selectedIndices.indexOf(selected)} centered={true} onClick={() => setSelected(window.location.hash.substring(8))}>
                <Tab label='Rent Books'     component={Link} to='/rents/books'  />
                <Tab label='Rent Hardware'  component={Link} to='/rents/hardware' />
                <Tab label='Active Rents'   component={Link} to='/rents/active' />
                <Tab label='Inactive Rents' component={Link} to='/rents/inactive' />
                <Tab label='Overtime Rents' component={Link} to='/rents/overtime' />
            </Tabs>
            <Route path='/rents/books'    render={props => <RentProducts className={classes.route} Class={Book} key={0} />} />
            <Route path='/rents/hardware' render={props => <RentProducts className={classes.route} Class={Hardware} key={1} />} />
            <Route path='/rents/active'   render={props => <RentsDisplay className={classes.route} rents='active' />} />
            <Route path='/rents/inactive' render={props => <RentsDisplay className={classes.route} rents='inactive' />} />
            <Route path='/rents/overtime' render={props => <RentsDisplay className={classes.route} rents='overtime' />} />
        </HashRouter>
    )
}