import React, {useState} from "react";
import Drawer from "../../components/Drawer/Drawer";
import { Breakpoint, useTheme } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HistoryIcon from '@mui/icons-material/History';
import useMediaQuery from '@mui/material/useMediaQuery';

function TransactionDrawer() {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));

    const [state, setState] = useState({
        open: false
    });

    return <Drawer
        open={state.open}
        onClose={() => setState({open: false})}
        onOpen={() => setState({open: true})}
        items={{
            "Methods": [
                {text: 'Transfer',              Icon: SwapHorizIcon,    linkTo: '/transactions/transfer'},
                {text: 'Add to Balance',        Icon: ChevronRightIcon, linkTo: '/transactions/send'},
                {text: 'Subtract from Balance', Icon: ChevronLeftIcon,  linkTo: '/transactions/receive'}
            ],
            "Transactions": [
                {text: "History", Icon: HistoryIcon, linkTo: '/transactions'}
            ]
        }}
        anchor='right'
        variant={isXs ? 'temporary' : 'permanent'}
        over={isXs}
    />
}

export default TransactionDrawer