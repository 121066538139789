import makeStyles from '@mui/styles/makeStyles';
import { Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DataBaseValueAutoComplete from "../DataBaseValueAutoComplete/DataBaseValueAutoComplete";
import Person from "../../classes/DataBaseValue/Person";
import React from "react";
import Party from "../../classes/DataBaseValue/Party";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    popper: {
        padding: 0,
        margin: 0
    }
}));

function CustomerSelect(props: {onCustomerSelect: (val: Person | null) => void, customer: Party, customerPerson: Person, resetKey: any}) {

    const classes = useStyles();

    //TODO change class that causes the popper menu to shift to the left
    return (
        <div className={classes.root}>
            <DataBaseValueAutoComplete
                key={props.resetKey}
                classes={{popper: classes.popper}}
                Class={Person}
                label={props.customer.attributes.id === "" || !props.customer.isPerson() ? "Customer" : `Balance: €${props.customer.attributes.balance / 100}`}
                /*value={!props.customerPerson.isStub() ? props.customerPerson : undefined}*/
                fullWidth={true}
                preloadOptions={true}
                onSelect={(person: Person | null) => props.onCustomerSelect(person ? person : null)}
            />
        </div>
    )
}

export default CustomerSelect