import Book, { RawBookAttributes } from "./Book"
import Consumable, { RawConsumableAttributes } from "./Consumable"
import Hardware, { RawHardwareAttributes } from "./Hardware"
import Product, { RawProductAttributes } from "./Product"

class ProductFactory {
    public static create(params: RawProductAttributes): Product {
        switch (params.kind) {
            case "book": return new Book(params as RawBookAttributes)
            case "hardware": return new Hardware(params as RawHardwareAttributes)
            default: return new Consumable(params as RawConsumableAttributes)
        }
    }
}

export default ProductFactory;
