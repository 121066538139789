import AbstractValue from "./AbstractValue";
import Form from "./Form";

abstract class BasicValue<V extends Form> extends AbstractValue {

    public value: V

    public toRaw(): V {
        return this.value;
    }

    abstract merge(toMerge: V): BasicValue<V>;

    constructor(value: V) {
        super();
        this.value = value;
    }
}

export class StringValue extends BasicValue<string> {
    public merge(toMerge: string): StringValue {
        return new StringValue(toMerge);
    }
}
export class NumberValue extends BasicValue<number> {
    public merge(toMerge: number): NumberValue {
        return new NumberValue(toMerge)
    }
}
export class NullValue extends BasicValue<null> {
    public merge(toMerge: null): NullValue {
        return new NullValue(toMerge);
    }
}
export class FileValue extends BasicValue<File> {
    public merge(toMerge: File): FileValue {
        return new FileValue(toMerge)
    }
}
export class BooleanValue extends BasicValue<boolean> {
    public merge(toMerge: boolean): BooleanValue {
        return new BooleanValue(toMerge);
    }
}
