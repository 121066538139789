import FormBody, {FormBodyProps, FormProps} from "./FormBody";
import React from "react";
import MaterialTextField from "@mui/material/TextField";
import {StringValue} from "../../../classes/DataBaseValue/BasicValues";

function TextField(props: FormProps<StringValue>) {
    return (
        <MaterialTextField
            error={false}
            required={false}
            label={props.name}
            variant="outlined"
            value={props.instance.value}
            onChange={(e) => props.hook(new StringValue(e.target.value))}
        />
    )
}

export default TextField