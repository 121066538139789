import makeStyles from '@mui/styles/makeStyles';
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React, {FunctionComponent, ReactElement} from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));


export default function Center(props: {children: ReactElement}) {

    const classes = useStyles();

    return (
        <div className={classes.center}>
            {props.children}
        </div>
    )
}