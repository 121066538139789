import Route from "../Route/Route";
import { CrudValue } from "./CrudValue";
import { DataBaseValueAttributes, PartialRawDataBaseValueAttributes, RawDataBaseValueAttributes } from "./DataBaseValue";
import InventoryTransfer, { RawInventoryTransferAttributes } from "./InventoryTransfer";
import Transaction, { RawTransactionAttributes } from "./Transaction";
import QueryPredicate from "../Route/QueryPredicate";
import {DateTime} from "luxon";

class Sale extends CrudValue {
    public attributes: SaleAttributes;

    public static route = new Route(['sales']);
    public static paginated = true;
    public static asString = 'sale';

    public static attributeNames: Array<string> = [];

    public attributesToTable() {
        return []
    }

    public toString() {
        return ""
    }

    constructor(params: RawSaleAttributes) {
        super(params);

        this.attributes = {
            ...params,
            transaction: new Transaction(params.transaction),
            inventory_transfer: new InventoryTransfer(params.inventory_transfer)
        }
    }

    public toRaw(): RawSaleAttributes {
        return {
            transaction: this.attributes.transaction.toRaw(),
            inventory_transfer: this.attributes.inventory_transfer.toRaw(),
            ...super.toRaw()
        }
    }

    public static makeStub(): Sale {
        return new Sale({
            id: "",
            created_at: null,
            updated_at: null,
            discarded_at: null,
            transaction: Transaction.makeStub().toRaw(),
            inventory_transfer: InventoryTransfer.makeStub().toRaw()
        })
    }

    public merge(toMerge: PartialRawSaleAttributes): Sale {
        return new Sale({...this.toRaw(), ...toMerge})
    }

    public serializeToJson(): { [p: string]: any } {
        return {
            sale: {
                money_transfer_attributes: this.attributes.transaction.serializeToJson().transaction,
                inventory_transfer_attributes: this.attributes.inventory_transfer.serializeToJson().inventory_transfer
            }
        }
    }

    public async preview(): Promise<Sale> {
        return await this.post(Sale.route.addRoute('preview'))
    }

    public static async getDailyStats(lower: DateTime, upper: DateTime): Promise<{[date: string]: Array<Sale>}> {
        const route = Sale.route
            .addRoute('per_day')
            .addPredicate(new QueryPredicate('lower', lower.toISODate()))
            .addPredicate(new QueryPredicate('upper', upper.toISODate()))

        const response: {[date: string]: Array<RawSaleAttributes>} = (await this.GETRequest<RawSaleAttributes>(route)) as {[key: string]: Array<RawSaleAttributes>}

        return Object.fromEntries(Object.entries(response).map(([date, rawSaleAttributes]) => [date, rawSaleAttributes.map(rsa => new Sale(rsa))]))
    }
}

export interface SaleAttributes extends DataBaseValueAttributes {
    transaction: Transaction,
    inventory_transfer: InventoryTransfer
}

export interface RawSaleAttributes extends RawDataBaseValueAttributes {
    transaction: RawTransactionAttributes,
    inventory_transfer: RawInventoryTransferAttributes
}

export interface PartialRawSaleAttributes extends PartialRawDataBaseValueAttributes {
    transaction?: RawTransactionAttributes,
    inventory_transfer?: RawInventoryTransferAttributes
}

export default Sale;
